<script lang="ts">
	import { ConditionalAnchor } from '$lib/components'
	import Icon from '$lib/components/Icon.svelte'
	import { t } from '$lib/translations'
	import Separator from '$lib/ui-woltair/components/Separator.svelte'

	type Brand = {
		title: string
		inStock: boolean
		brand: {
			data: {
				attributes: {
					title: string
					slug: string
					logo: {
						data: {
							attributes: {
								url: string
							}
						}
					}
					brandPages: {
						data: [
							{
								attributes: {
									title: string
								}
							}
						]
					}
				}
			}
		}
	}

	type DeviceType = {
		data: {
			attributes: {
				slug: string
			}
		}
	}

	export let title: string
	export let subtext: string
	export let deviceType: DeviceType
	export let brands: Brand[]
	export let width: number

	const deviceSlug = deviceType?.data?.attributes?.slug
</script>

<div class="col-span-12 flex w-full flex-col items-center md:col-span-{width}">
	<Separator />
	<div class="max-w-4xl text-center">
		<!-- Remove h2 and p text-styling when full HVAC page will be redesigned (should be unified) -->
		{#if title}
			<h2 class="unstyled">
				{title}
			</h2>
		{/if}
		{#if subtext}
			<p class="text-lg">{subtext}</p>
		{/if}
	</div>
	{#if brands.length}
		<div
			class="hide-scrollbar mt-12 max-w-[100vw] gap-4 px-4 max-md:flex max-md:overflow-x-auto md:grid md:max-w-full md:grid-cols-3 md:gap-16 lg:grid-cols-4 xl:grid-cols-5"
		>
			{#each brands as brand}
				{@const hasBrandPage =
					!!deviceSlug &&
					!!brand?.brand?.data?.attributes?.slug &&
					!!brand?.brand?.data?.attributes?.brandPages?.data?.length}
				<ConditionalAnchor
					condition={hasBrandPage}
					href="/{deviceSlug}/{brand?.brand.data?.attributes?.slug}"
				>
					<div class="max-md:w-48 max-md:min-w-[200px]">
						<div
							class="flex aspect-[5/3] max-w-full items-center justify-around rounded-2xl bg-neutral-100 p-4"
						>
							<img
								class="max-h-full object-contain mix-blend-darken"
								src={brand.brand?.data?.attributes?.logo?.data?.attributes?.url}
								alt={brand.brand?.data?.attributes?.title}
								loading="lazy"
							/>
						</div>
						{#if brand.inStock}
							<div class="pt-4 text-base text-green-600">
								{t('web.heat_pump.brands.availability.option1')}
							</div>
						{:else}
							<div class="pt-4 text-base">
								{t('web.heat_pump.brands.availability.option2')}
							</div>
							<div class="pb-4 text-base">
								{t('web.heat_pump.brands.instalation.option2')}
							</div>
						{/if}
						{#if hasBrandPage}
							<hr class="my-1" />
							<a
								href="/{deviceSlug}/{brand?.brand.data?.attributes?.slug}"
								class="flex items-center justify-between py-2 text-sm text-primary-500 hover:underline"
							>
								<span>{brand.brand?.data?.attributes?.title}</span>
								<Icon
									height="h-3"
									cls="align-middle"
									src="/images/icons/arrow/next-black.svg"
									alt={t('web.heat_pump.brands.arrowIconAlt')}
								/>
							</a>
						{/if}
					</div>
				</ConditionalAnchor>
			{/each}
		</div>
	{/if}
</div>

<style lang="scss">
	h2 {
		@apply inset-x-0 mx-auto mt-6-res max-w-5xl text-center text-4.5xl-res font-extrabold tracking-normal;
	}

	p {
		@apply inset-x-0 mx-auto mt-4 max-w-4xl text-center;
	}
</style>
