<script lang="ts">
	export let width: number
	export let title: string
	export let buttonText: string
	export let buttonLink: string
	export let phoneString: string
	export let phoneLink: string
	export let emailString: string
	export let emailLink: string
</script>

<div
	class="col-span-12 md:col-span-{width} relative h-full w-full rounded-2xl bg-white bg-opacity-60"
>
	<div class="grid h-full w-full grid-cols-12 gap-x-2 gap-y-8 px-10-res py-10 @container">
		<div class="col-span-12 flex w-full flex-col @3xl:col-span-7">
			<h3 class="unstyled text-3xl font-bold">{title}</h3>
			<a
				class="btn btn-filled-primary mt-4 w-fit whitespace-break-spaces text-xl-res"
				href={buttonLink}>{buttonText}</a
			>
		</div>
		<div
			class="col-span-12 flex w-full flex-col justify-center gap-3 text-left @3xl:col-span-5 @3xl:items-end @3xl:text-right"
		>
			<a
				href={emailLink}
				class="flex items-center gap-4 break-words text-4xl-res font-medium text-primary-500 @xl:text-3xl"
				><img
					src="/images/icons/email-red.svg"
					class="aspect-square h-[1em]"
					alt="email"
					loading="lazy"
				/>{emailString}</a
			>
			<a
				href={phoneLink}
				class="flex items-center gap-4 break-words text-4xl-res font-medium text-green-700 @xl:text-3xl"
				><img
					src="/images/icons/phone-green-v2.svg"
					class="aspect-square h-[1em]"
					alt="phone"
					loading="lazy"
				/>{phoneString}</a
			>
		</div>
	</div>
</div>
