<script lang="ts">
	import { dayjs } from '@woltair/translations-package-fe'
	import { t } from '$lib/translations'
	import { downloadURI } from '$lib/utils'

	export let files
	export let title: string
	let scrollContainer, scrollArea
	function scrollElement(element, forward = true) {
		const maxScrollLeft = element.scrollWidth - element.clientWidth
		if (forward) {
			if (element.scrollLeft < maxScrollLeft - 30) {
				element.scrollBy({ left: 250, behavior: 'smooth' })
			} else {
				element.scrollTo({ left: 0, behavior: 'smooth' })
			}
		} else {
			if (element.scrollLeft > 30) {
				element.scrollBy({ left: -250, behavior: 'smooth' })
			} else {
				element.scrollTo({ left: maxScrollLeft, behavior: 'smooth' })
			}
		}
	}
</script>

<section class=" relative w-full">
	<div class="flex items-center justify-between">
		<h3 class="unstyled h4 block text-left font-bold">{title}</h3>
		{#if scrollContainer?.offsetWidth < scrollArea?.offsetWidth}
			<div class="flex gap-4">
				<button
					class="block flex h-6 items-center justify-center rounded-full bg-neutral-100 px-6 py-6 text-sm"
					on:click={() => scrollElement(scrollContainer, false)}>&#10094;</button
				>
				<button
					class="block flex h-6 items-center justify-center rounded-full bg-neutral-100 px-6 py-6 text-sm"
					on:click={() => scrollElement(scrollContainer)}
					>{t('web.components.downloads.nextPhoto')} &#10095;</button
				>
			</div>
		{/if}
	</div>
	<div class="no-scrollbar w-full overflow-x-auto" bind:this={scrollContainer}>
		<div class="flex w-fit gap-4" bind:this={scrollArea}>
			{#each files as file}
				{@const imgsrc = file.file?.data?.attributes?.url ?? null}
				<div
					role="button"
					tabindex="0"
					on:keydown={(e) => {
						if (e.key == 'Enter')
							downloadURI(file.file?.data?.attributes?.url, file.file?.data?.attributes?.name)
					}}
					on:click={() => {
						downloadURI(file.file?.data?.attributes?.url, file.file?.data?.attributes?.name)
					}}
					class="block w-[250px]"
				>
					{#if imgsrc}
						<div class="relative flex h-[190px] w-full overflow-hidden rounded-xl bg-neutral-100">
							<img
								class="min-h-full min-w-full max-w-none object-cover opacity-20 blur-xl"
								src={imgsrc.replace('/public', '/width=400')}
								alt={file.title}
								loading="lazy"
							/>
							<img
								class="absolute left-1/2 top-1/2 mx-auto h-auto max-h-full w-auto w-auto max-w-full -translate-x-1/2 -translate-y-1/2 p-2"
								src={imgsrc.replace('/public', '/width=400')}
								alt={file.title}
								loading="lazy"
							/>
						</div>
					{/if}
					<div class="metainfo mb-2 mt-2 text-sm">
						<div class="overflow-hidden text-neutral-500">
							{file.title}
						</div>
						{#if file.publishDate}
							<div class="capitalize text-neutral-400">
								{dayjs(file.publishDate).format('MMM DD. YYYY')}
							</div>
						{/if}
					</div>
				</div>
			{/each}
		</div>
	</div>
</section>

<style>
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	.no-scrollbar {
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
</style>
