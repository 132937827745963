<script lang="ts">
	import { page } from '$app/stores'
	export let width: number
	export let image: { data: { attributes: { url: string } } }

	const grayBg = $page.data?.layout?.background === 'gray'
</script>

<div class="page-wrapper absolute -z-10 hidden aspect-video md:block">
	<div class="relative h-full w-full">
		<!--pozicovat tento tag -->
		<div class="absolute right-0 aspect-square custom-w-{width}">
			<div class="relative h-full w-full">
				{#if image.data?.attributes?.url}
					<img
						class="absolute right-0 h-full w-full object-cover"
						src={image.data?.attributes?.url}
						alt="Background | Woltair"
					/>
				{/if}
				<div
					class="{grayBg
						? 'bottom-gradient-gray'
						: 'bottom-gradient-white'} absolute bottom-0 h-[20%] w-full"
				/>
				<div
					class="{grayBg ? 'left-gradient-gray' : 'left-gradient-white'} absolute h-full w-1/2"
				/>
				<div
					class="{grayBg
						? 'right-gradient-gray'
						: 'right-gradient-white'} absolute right-0 h-full w-[20%]"
				/>
			</div>
		</div>
	</div>
</div>

<style lang="scss">
	.custom-w-1 {
		@apply w-1/12;
	}

	.custom-w-2 {
		@apply w-2/12;
	}

	.custom-w-3 {
		@apply w-3/12;
	}

	.custom-w-4 {
		@apply w-4/12;
	}

	.custom-w-5 {
		@apply w-5/12;
	}

	.custom-w-6 {
		@apply w-6/12;
	}

	.custom-w-7 {
		@apply w-7/12;
	}

	.custom-w-8 {
		@apply w-8/12;
	}

	.custom-w-9 {
		@apply w-9/12;
	}

	.custom-w-10 {
		@apply w-10/12;
	}

	.custom-w-11 {
		@apply w-11/12;
	}

	.custom-w-12 {
		@apply w-full;
	}

	.bottom-gradient-gray {
		background: rgb(245, 245, 245);
		background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
	}

	.left-gradient-gray {
		background: rgb(245, 245, 245);
		background: linear-gradient(270deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
	}

	.right-gradient-gray {
		background: rgb(245, 245, 245);
		background: linear-gradient(90deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
	}

	.bottom-gradient-white {
		background: rgb(255, 255, 255);
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	}

	.left-gradient-white {
		background: rgb(255, 255, 255);
		background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	}

	.right-gradient-white {
		background: rgb(255, 255, 255);
		background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
	}
</style>
