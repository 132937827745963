<script lang="ts">
	import { dayjs } from '@woltair/translations-package-fe'
	import { BLOG } from '$lib/routes'
	import { t } from '$lib/translations'
	import { cropWordLength } from '$lib/utils'
	import { resizeCDNImage } from '$lib/utils'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	type Article = {
		attributes: {
			title: string
			publishDate: string
			content: any
			slug: string
			metaDescription: string
			perex: string
			author: {
				data: {
					attributes: {
						name: string
					}
				}
			}
			mainImage: {
				data: {
					attributes: {
						url: string
					}
				}[]
			}
		}
	}

	type Categories = {
		data: {
			attributes: {
				slug: string
				articles: {
					data: Article[]
				}
			}
		}[]
	}

	export let title: string
	export let width: number
	export let hideLinkList = false
	export let articleLimit = 4
	export let articles: {
		data: Article[]
	}
	export let categories: Categories

	const dateFormat =
		{
			WOLTAIR_CZ: 'D. MMMM YYYY',
			WOLTAIR_PL: 'D.MM.YYYY',
			WOLTAIR_DE: 'D. MMMM YYYY',
			WOLTAIR_IT: 'D MMMM YYYY'
		}[PUBLIC_WOLTAIR_WEB_CODE] || 'D. MMMM YYYY'

	const articlesFromCategories = categories?.data
		.map((category) => category?.attributes?.articles?.data)
		.flat()
		.sort((a: Article, b: Article) => {
			return (
				new Date(b?.attributes?.publishDate).getTime() -
				new Date(a?.attributes?.publishDate).getTime()
			)
		})
		.reduce((acc: Article[], curr: Article) => {
			if (!acc.includes(curr)) acc.push(curr)
			return acc
		}, [])
	let articlesToShow: Article[] = []

	if (articlesFromCategories) {
		const tempArticles = [...(articles?.data ?? []), ...articlesFromCategories]

		tempArticles.forEach((article) => {
			if (!articlesToShow.some((a) => a?.attributes?.slug === article?.attributes?.slug))
				articlesToShow.push(article)
		})
	} else {
		articlesToShow = [...(articles?.data ?? [])]
	}

	articlesToShow = articlesToShow.slice(0, articleLimit)

	function getMoreLink(categories: Categories) {
		if (categories?.data?.length == 1 && categories?.data?.[0]?.attributes?.slug) {
			return `/${BLOG}/${categories.data?.[0].attributes.slug}`
		} else {
			return `/${BLOG}`
		}
	}
	const moreLink = getMoreLink(categories)
</script>

{#if articles?.data?.[0]?.attributes?.title || articlesFromCategories?.[0]?.attributes?.title}
	<div class="mb-4 flex w-full items-end justify-between">
		<h2
			class="unstyled w-full text-center text-xl font-semibold text-w-blue-950 md:w-fit md:text-left md:text-4xl"
		>
			{title}
		</h2>
		{#if !hideLinkList}
			<a
				href={moreLink}
				class="hidden font-semibold text-w-blue-950 transition-colors hover:text-w-red-500 md:block"
				>{t('web.dynamic_pages.components.showAllArticles')}</a
			>
		{/if}
	</div>
	<div
		class="grid grid-cols-1 gap-8 md:gap-4 lg:grid-cols-2 xl:grid-cols-2 col-span-{width} w-full auto-rows-max items-stretch"
	>
		{#each articlesToShow as article, i}
			{#if (i < articleLimit || !articleLimit) && article.attributes}
				<a
					class="article-box grid h-full min-h-[110px] w-full grid-cols-12 self-stretch overflow-hidden md:rounded-3xl md:bg-neutral-100"
					href="/{BLOG}/{article?.attributes?.slug}"
					title={article?.attributes?.title?.replace(/&nbsp;/g, ' ')}
				>
					<div
						class="relative col-span-4 h-full w-full overflow-hidden rounded-lg md:col-span-5 md:rounded-none"
					>
						<img
							src={resizeCDNImage(article?.attributes?.mainImage?.data?.[0]?.attributes?.url, 400)}
							alt={`Image – ${article.attributes.title?.replace(/&nbsp;/g, ' ')}`}
							class="absolute h-full w-full flex-shrink flex-grow object-cover object-center"
							loading="lazy"
						/>
					</div>
					<div
						class="col-span-8 row-span-1 flex h-fit flex-col gap-4 p-4 py-2 @container md:col-span-7 md:py-8"
					>
						<div class="flex gap-x-6 gap-y-1 text-sm @2xs:px-4">
							<span class="text-neutral-500"
								>{dayjs(article.attributes.publishDate).format(dateFormat)}</span
							>
							{#if article.attributes?.author?.data?.attributes?.name}
								<span class="hidden text-w-red-500 @xs:block"
									>{article.attributes.author.data.attributes.name}</span
								>
							{/if}
						</div>
						<h3
							class="unstyled whitespace-prewrap my-0 text-base font-semibold leading-snug tracking-tight text-w-blue-950 transition-colors @2xs:px-4 @2xs:text-lg"
						>
							{@html `${article.attributes.title.slice(0, 57)}${
								article.attributes.title.length > 57 ? '...' : ''
							}`}
						</h3>
						<p class="unstyled hidden text-sm text-neutral-500 @2xs:px-4 md:block">
							{@html cropWordLength(
								article.attributes.metaDescription ||
									article.attributes.perex ||
									article.attributes.content?.[0]?.text,
								150
							)}
						</p>
					</div>
				</a>
			{/if}
		{/each}
	</div>
	<div class="mt-10 md:hidden">
		<a href={moreLink} class="btn-new btn-new-blue"
			>{t('web.dynamic_pages.components.showAllArticles')}</a
		>
	</div>
{/if}

<style lang="scss">
	.article-box {
		&:hover h3 {
			@apply text-w-red-500;
		}
	}
</style>
