<script lang="ts">
	import { dayjs } from '@woltair/translations-package-fe'
	import { t } from '$lib/translations'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	export let width: number
	export let publishDate: string
	export let author: {
		data: {
			attributes: {
				name: string
				photo: {
					data: {
						attributes: {
							url: string
						}
					}
				}
			}
		}
	}

	const dateFormat = {
		WOLTAIR_CZ: 'D. MMMM YYYY',
		WOLTAIR_PL: 'D.MM.YYYY',
		WOLTAIR_DE: 'D. MMMM YYYY',
		WOLTAIR_IT: 'D MMMM YYYY'
	}
</script>

<div class="col-span-{width} flex w-full flex-col items-start gap-3">
	<div class="text-lg">{t('web.dynamic_pages.components.author')}:</div>
	<div class="flex items-center gap-4">
		{#if author?.data?.attributes?.photo?.data?.attributes?.url && author?.data?.attributes?.name}
			<img
				class="aspect-square h-16 object-fill"
				src={author.data.attributes.photo.data.attributes.url}
				alt={author.data.attributes.name}
				loading="lazy"
			/>
		{/if}
		<div>
			{#if author?.data?.attributes?.name}
				<div class="text-lg font-bold">{author.data.attributes.name}</div>
			{/if}
			{#if publishDate}
				<div class="text-sm">
					{dayjs(publishDate).format(dateFormat[PUBLIC_WOLTAIR_WEB_CODE])}
				</div>
			{/if}
		</div>
	</div>
</div>
