<script lang="ts">
	import ConditionalAnchor from '$lib/components/ConditionalAnchor.svelte'
	export let data
</script>

<div class="page-wrapper">
	{#if data.logoentry.length > 0}
		<div class="flex flex-wrap items-center justify-center gap-8">
			{#each data.logoentry as item}
				<div class="group max-w-48 flex-col justify-center" class:hover={item.url}>
					{#if item.logoimage?.data?.attributes?.url}
						<ConditionalAnchor
							condition={item.url}
							href={item.url}
							target={item.url?.includes('http') ? '_blank' : '_self'}
						>
							<img
								class="mx-auto max-h-10 min-h-10 bg-transparent object-contain opacity-50 contrast-50 grayscale {item.url
									? 'group-hover:opacity-100 group-hover:contrast-100 group-hover:grayscale-0'
									: ''}"
								src={item.logoimage.data.attributes.url}
								alt={item.title}
								loading="lazy"
							/>
						</ConditionalAnchor>
					{/if}
				</div>
			{/each}
		</div>
	{/if}
</div>

<style>
</style>
