<script>
	import ico_watercapacity from '$lib/assets/icons/water-oven.svg'
	import ico_air from '$lib/assets/listing/air.svg'
	import ico_drop_air from '$lib/assets/listing/drop-air.svg'
	import ico_drop from '$lib/assets/listing/drop.svg'
	import ico_power from '$lib/assets/listing/pump.svg'
	import ico_sound from '$lib/assets/listing/sound.svg'
	import ico_volume from '$lib/assets/listing/volume.svg'
	import { t } from '$lib/translations'
	import SmallTooltip from './SmallTooltip.svelte'
	export let packageData
	const heatPumpSystemImages = {
		AIR_TO_WATER: ico_drop_air,
		GROUND_TO_WATER: ico_drop,
		AIR_TO_AIR: ico_air,
		WATER_TO_WATER: ico_drop
	}
	// import ico_star from '$lib/assets/icons/star.svg';
	// import ico_wwhite from '$lib/assets/icons/woltair-w-white.svg';
	// import img_superman from '$lib/assets/wltr-superman.png';
	// import ico_infobrown from '$lib/assets/icons/info_brown.svg';
	// import hvac_placeholder from '$lib/assets/placeholder.jpg';
	// import woltair_w from '$lib/assets/icons/woltair-w.svg';
	// import ico_return from '$lib/assets/icons/return.svg';
	// import recommend from '$lib/assets/icons/recommend-de.svg';
	// import ico_calendar from '$lib/assets/icons/calendar.svg';
	// import ico_hvacpower from '$lib/assets/icons/power.svg';
	// import ico_grant from '$lib/assets/icons/grant.svg';
	// import ico_people from '$lib/assets/listing/people.svg';
</script>

<div class="specs my-2 flex w-fit flex-wrap gap-3 align-baseline text-xs text-neutral-500">
	{#if packageData?.waterTankCapacity}
		<SmallTooltip icon={ico_volume}>
			<svelte:fragment slot="tooltip">
				{t('web.marketplace.hvacResult.waterTankCapacity')}
			</svelte:fragment>
			<svelte:fragment slot="default">
				{packageData?.waterTankCapacity} l
			</svelte:fragment>
		</SmallTooltip>
		{#if true}
			<span class="divider text-neutral-300">|</span>
		{/if}
	{/if}
	{#if packageData?.batteryCapacity}
		<SmallTooltip icon={ico_volume}>
			<svelte:fragment slot="tooltip">
				{t('web.marketplace.detail.batteryCapacity')}
			</svelte:fragment>
			<svelte:fragment slot="default">
				{packageData?.batteryCapacity} kWh
			</svelte:fragment>
		</SmallTooltip>
		{#if true}
			<span class="divider text-neutral-300">|</span>
		{/if}
	{/if}
	{#if packageData?.accumulationVesselCapacity}
		<SmallTooltip icon={ico_watercapacity}>
			<svelte:fragment slot="tooltip">
				{t(`web.marketplace.hvacResult.accumulationVolume`)}
			</svelte:fragment>
			<svelte:fragment slot="default">
				{packageData?.accumulationVesselCapacity} L
			</svelte:fragment>
		</SmallTooltip>
		{#if true}
			<span class="divider text-neutral-300">|</span>
		{/if}
	{/if}
	{#if packageData?.fvePower}
		<SmallTooltip icon={ico_power}>
			<svelte:fragment slot="tooltip">
				{t(`web.marketplace.pvResult.fvePower`)}
			</svelte:fragment>
			<svelte:fragment slot="default">
				{packageData?.fvePower} kWp
			</svelte:fragment>
		</SmallTooltip>
		{#if true}
			<span class="divider text-neutral-300">|</span>
		{/if}
	{/if}
	{#if packageData?.heatPumpSystem}
		<SmallTooltip icon={heatPumpSystemImages[packageData?.heatPumpSystem]}>
			<svelte:fragment slot="tooltip">
				{t('web.marketplace.detail.heatPumpSystem')}
			</svelte:fragment>
			<svelte:fragment slot="default">
				<div class="inline-block">
					{t(`web.marketplace.hvacResult.${packageData?.heatPumpSystem}`)}
				</div>
			</svelte:fragment>
		</SmallTooltip>
		{#if true}
			<span class="divider text-neutral-300">|</span>
		{/if}
	{/if}
	{#if packageData?.noiseLevel}
		<SmallTooltip icon={ico_sound}>
			<svelte:fragment slot="tooltip">
				{t('web.marketplace.detail.noiseLevel')}
			</svelte:fragment>
			<svelte:fragment slot="default">
				{packageData?.noiseLevel} dB
			</svelte:fragment>
		</SmallTooltip>

		{#if true}
			<span class="divider text-neutral-300">|</span>
		{/if}
	{/if}
</div>

<style>
	.specs .divider:last-child {
		display: none;
	}
</style>
