import { HVAC_BRANDS, MARKETPLACE_DEFAULT_VALUES } from '$lib/consts'
import type { InputMaybe } from '$lib/types/core'
import {
	WaterSystem,
	type DefinedPvComponents,
	type FveComponent,
	type FveVariant,
	type MonthDataResponse,
	type WoltAdvisorFromQueryRequest,
	type WoltAdvisorFveRequest
} from '$lib/types/woltadvisor'
import { NoUndefinedVariablesRule } from 'graphql'

function findSupplierTitle(supplierTitle: string) {
	if (HVAC_BRANDS.hasOwnProperty(supplierTitle)) {
		return HVAC_BRANDS[supplierTitle as keyof typeof HVAC_BRANDS].name
	} else {
		return null
	}
}

export const dataPrepareHvac = function (
	data: any,
	orgid: string | number = 1
): WoltAdvisorFromQueryRequest {
	// Default Values from Marketplace
	const defaultValues = MARKETPLACE_DEFAULT_VALUES.HVAC

	// Normalization
	const floorSquare: number = !isNaN(parseInt(data.floorSquare, 10))
		? parseInt(data.floorSquare, 10)
		: defaultValues.floorSquare
	const peopleCount: number = !isNaN(parseInt(data.peopleCount, 10))
		? parseInt(data.peopleCount, 10)
		: defaultValues.peopleCount
	data.regionName = data.regionName || defaultValues.regionName
	data.houseHeatType = data.houseHeatType || defaultValues.houseHeatType
	data.isoLevel = data.houseHeatLoss ? data.isoLevel : defaultValues.isoLevel

	data.filterResultWaterSystem =
		data.waterHeatingWithHouseHeatSource === true
			? WaterSystem.WithWater
			: data.waterHeatingWithHouseHeatSource === false
			? WaterSystem.NoWater
			: data.filterResultWaterSystem

	const supplierTitle = findSupplierTitle(data.supplierTitle)
	const normalizedData: WoltAdvisorFromQueryRequest = {
		...(data.definedPackages && { definedPackages: data.definedPackages }),
		organization: {
			id: data.organization?.id || orgid
		},
		filterResult: false,
		houseHeatType: data.houseHeatType,
		regionName: data.regionName,
		floorSquare,

		peopleCount,
		airCondition: data.airCondition == 'true',
		waterHeatingWithHouseHeatSource:
			data.waterHeatingWithHouseHeatSource === 'true' || data.houseHeatType === 'ELECTRO'
				? true
				: false,
		coalAutomatic: data.coalAutomatic === true,
		...(data.waterTempFortyFive && { waterTempFortyFive: data.waterTempFortyFive }),
		...(data.isoLevel && { isoLevel: data.isoLevel }),
		...(data.refrigerantType && { refrigerantType: data.refrigerantType }),
		...(data.noiseLevel && {
			noiseLevel: isNaN(parseInt(data.noiseLevel, 10)) ? null : parseInt(data.noiseLevel, 10)
		}),
		...(data.inStock && { inStock: data.inStock }),
		...(data.cityName && { cityName: data.cityName }),
		...(data.zip && { psc: data.zip }),
		...(data.supplierTitles && { supplierTitles: data.supplierTitles }),
		...(data.energyPriceInvoices && { energyPriceInvoices: data.energyPriceInvoices }),
		...(data.colors && { colors: { id: data.colors } }),
		...(!isNaN(parseFloat(data.houseHeatLoss)) && {
			houseHeatLoss: parseFloat(data.houseHeatLoss)
		}),
		...(data.insideTemp && { insideTemp: data.insideTemp }),
		...(data.filterResultWaterSystem && { filterResultWaterSystem: data.filterResultWaterSystem }),
		...(data.solidFuel && { solidFuel: data.solidFuel }),
		...(data.limit && { limit: data.limit }),
		...(data.defindedPackages && { defindedPackages: data.defindedPackages })
	}
	if (data.formType === 'hvacDetail') {
		delete normalizedData.filterResultWaterSystem
	}
	logger.debug('dataPreparation.normalized.data', JSON.stringify(normalizedData))
	return normalizedData
}

export const dataPreparePV = function (
	data: any,
	orgid: string | number = 7
): WoltAdvisorFveRequest {
	let houseHeatType: string

	let annualElectricityConsumption: InputMaybe<number> | undefined
	// Normalization

	const peopleCount: InputMaybe<number> | undefined = !isNaN(parseInt(data.peopleCount, 10))
		? parseInt(data.peopleCount, 10)
		: MARKETPLACE_DEFAULT_VALUES.PV.peopleCount
	if (!data.regionName || data.regionName === '')
		data.regionName = MARKETPLACE_DEFAULT_VALUES.PV.region
	if (data.annualElectricityConsumption) {
		annualElectricityConsumption = parseFloat(data.annualElectricityConsumption.replace(',', '.'))
	} else if (!data.isoLevel) {
		annualElectricityConsumption = MARKETPLACE_DEFAULT_VALUES.PV.annualElectricityConsumption
	}
	if (!data.houseHeatType || data.houseHeatType == '') {
		houseHeatType = MARKETPLACE_DEFAULT_VALUES.PV.houseHeatType
	} else if (data.houseHeatType !== '') {
		houseHeatType = data.houseHeatType
	} else {
		houseHeatType = MARKETPLACE_DEFAULT_VALUES.PV.houseHeatType
	}
	if (!data.houseHeatLoss && !data.isoLevel) data.isoLevel = MARKETPLACE_DEFAULT_VALUES.PV.isoLevel

	return {
		organization: {
			id: data.organization?.id || orgid
		},
		houseHeatType: houseHeatType,
		airCondition: data.airCondition == 'true',
		peopleCount,
		waterHeatingWithHouseHeatSource:
			data.waterHeatingWithHouseHeatSource == 'true' || houseHeatType == 'ELECTRO' ? true : false,
		...(data.definedPackages && { definedPackages: data.definedPackages }),
		...(annualElectricityConsumption && { annualElectricityConsumption }),
		...(data.isoLevel && { isoLevel: data.isoLevel }),
		...(data.phaseCount && { phaseCount: data.phaseCount }),
		...(data.floorSquare && { floorArea: data.floorSquare }),
		...(data.definedComponents && { definedComponents: data.definedComponents }),
		...(data.refrigerantType &&
			data.refrigerantType !== '' && { refrigerantType: data.refrigerantType }),
		...(data.inStock && { inStock: data.inStock }),
		...(data.regionName && { region: data.regionName }),
		...(data.cityName && { city: data.cityName }),
		...(data.zip && { zip: data.zip }),
		...(data.energyPriceInvoices && { energyPriceInvoices: data.energyPriceInvoices }),
		...(data.colors && { colors: { id: data.colors.id } }),
		...(data.houseHeatLoss && { houseHeatLoss: data.houseHeatLoss }),
		...(data.insideTemp && { insideTemp: data.insideTemp }),
		...(data.solidFuel && { solidFuel: data.solidFuel })
	} as WoltAdvisorFveRequest
}

type ParsedSegment = {
	type: string
	id: string
	quantity: number
	description: string
}
type ComponentType = 'panel' | 'battery' | 'inverter'

export function parseUrlPath(path: string): ParsedSegment[] | undefined {
	const normalizedPath = path.startsWith('/') ? path.slice(1) : path
	const segments = normalizedPath.split('/')

	const parsedSegments = segments
		.map((segment) => {
			const parts = segment.split('-')
			const type = parts[0]
			const id = parts[1] // Keeping id as string
			const quantity = parseInt(parts[2], 10)
			const description = parts.slice(3).join('-')
			if (type && id && !isNaN(quantity)) {
				return { type, id, quantity, description }
			} else {
				return undefined
			}
		})
		.filter((segment): segment is ParsedSegment => segment !== undefined)

	return parsedSegments.length > 0 ? parsedSegments : undefined
}

export function transformDefinedPackages(definedPackagesPrep: ParsedSegment[] | undefined) {
	const definedComponents: DefinedPvComponents = {}

	if (!definedPackagesPrep) {
		return { definedComponents }
	}

	definedPackagesPrep.forEach((packageItem) => {
		if (packageItem.type && ['panel', 'battery', 'inverter'].includes(packageItem.type)) {
			const type = packageItem.type as ComponentType
			if (!definedComponents[type]) {
				definedComponents[type] = {
					amount: 0,
					package: {
						id: packageItem.id
					}
				}
			}
			definedComponents[type]!.amount += packageItem.quantity
		}
	})

	return { definedComponents }
}

type CountryCodes = {
	[key: string]: string
}
export const getCountryISOCode = function (countryName: string | undefined): string | null {
	if (!countryName) return null
	const countryCodes: CountryCodes = {
		Švédsko: 'SE',
		Německo: 'DE',
		Japonsko: 'JP',
		'Jižní Korea': 'KR',
		Čína: 'CN'
	}

	return countryCodes[countryName] || countryName
}
export function getComponentByType(
	componentType: string,
	data?: FveVariant
): FveComponent | undefined {
	if (data && data.components) {
		return data.components.find((component) => component.componentType === componentType)
	} else {
		logger.warn('Failed to get component', { data, componentType })
		return undefined
	}
}

export function getValuesByKey(
	data: MonthDataResponse[] | undefined,
	key: string,
	multiplicator = 1
) {
	return data ? data.map((item) => item[key] * multiplicator) : []
}
