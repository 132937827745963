<script lang="ts">
	export let boxes: {
		name?: string
		unit?: string
		value?: string
		pretext?: string
		icon?: {
			data: {
				attributes: {
					url: string
				}
			}
		}
	}[]
</script>

{#if boxes.length}
	<div class="grid w-full grid-cols-1 gap-3 xs:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
		{#each boxes as box}
			<div class="col-span-1 overflow-clip rounded-xl bg-neutral-100 p-5 @container">
				<div class="flex h-full flex-col gap-8 text-w-blue-900">
					<div class="flex items-center gap-4">
						{#if box.name}
							<div class="text-sm">{box.name}</div>
						{/if}
						{#if box.icon?.data?.attributes?.url}
							<img
								src={box.icon.data.attributes.url}
								alt={box.name || 'Icon'}
								width="40px"
								height="40px"
								class="ml-auto h-10 w-10"
								loading="lazy"
							/>
						{/if}
					</div>
					<div class="box-value mt-auto">
						{#if box.pretext}
							<span class="text-lg !font-normal !tracking-tight opacity-40">{box.pretext}</span>
						{/if}
						{#if box.value}
							<span class="mr-1 text-4.5xl font-semibold !tracking-tighter @3xs:text-6.5xl"
								>{box.value}</span
							>
						{/if}
						{#if box.unit}
							<span class="text-lg !font-normal !tracking-tight opacity-40">{box.unit}</span>
						{/if}
					</div>
				</div>
			</div>
		{/each}
	</div>
{/if}
