<script lang="ts">
	import { onMount } from 'svelte'
	import { t } from '$lib/translations'
	import { resizeCDNImage } from '$lib/utils'

	export let suptext: string
	export let title: string
	//export let width: string
	export let steps: {
		numbering: string
		title: string
		text: string
		image: {
			data: {
				attributes: {
					url: string
				}
			}
		}
		icon: {
			data: {
				attributes: {
					url: string
				}
			}
		}
	}[]

	export let stepsTheme: 'GRAY' | 'BORDERED' = 'BORDERED'

	let container: HTMLDivElement
	let containerWidth: number
	let wrapperWidth: number
	let boxWidth: number

	let leftMostScroll: boolean = true
	let rightMostScroll: boolean = false

	const scrollForwards = () => {
		container.scrollBy({
			left: boxWidth + 24,
			behavior: 'smooth'
		})
	}

	const scrollBackwards = () => {
		container.scrollBy({
			left: -boxWidth - 24,
			behavior: 'smooth'
		})
	}

	onMount(() => {
		rightMostScroll = container.scrollLeft + container.clientWidth - container.scrollWidth === 0
	})
</script>

<div class="flex flex-col items-center text-center">
	{#if suptext}
		<h3 class="unstyled font-semibold text-w-red-500">
			{suptext}
		</h3>
	{/if}

	{#if title}
		<h2
			class="unstyled mb-12-res mt-4-res max-w-3xl text-center text-xl font-semibold leading-snug text-w-blue-950 md:text-4xl"
		>
			{@html title}
		</h2>
	{/if}
</div>

<div
	class="hide-scrollbar relative flex h-fit w-screen snap-x snap-mandatory overflow-x-auto py-2 lg:w-full"
	bind:this={container}
	bind:clientWidth={containerWidth}
	on:scroll={() => {
		rightMostScroll = container.scrollLeft + container.clientWidth - container.scrollWidth === 0
		leftMostScroll = container?.scrollLeft === 0
	}}
>
	<div class="flex w-fit" bind:clientWidth={wrapperWidth}>
		{#each steps as step}
			<div
				class="carousel-box flex h-full snap-start scroll-ml-4 flex-col overflow-x-hidden rounded-2xl {stepsTheme ===
				'GRAY'
					? 'border bg-neutral-100'
					: 'border bg-white'} pt-6 md:gap-2 lg:scroll-ml-0 lg:pt-12"
				bind:clientWidth={boxWidth}
				class:pb-16={!step.image?.data?.attributes?.url}
			>
				{#if step.icon?.data?.attributes?.url || step.numbering}
					<div class="flex justify-between px-6 lg:px-10">
						{#if step.numbering}
							<div class="text-3.5xl font-semibold text-w-blue-950 opacity-30">
								{step.numbering}
							</div>
						{/if}
						{#if step.icon?.data?.attributes?.url}
							<img
								src={step.icon.data.attributes.url}
								alt={step.title}
								class="mb-6 h-16"
								loading="lazy"
								width="64"
								height="64"
							/>
						{/if}
					</div>
				{/if}
				<div class="px-6 text-lg font-semibold text-w-blue-950 md:text-2xl lg:px-10">
					{step.title}
				</div>
				<div class="mt-2 px-6 text-sm text-neutral-500 lg:px-10">{@html step.text}</div>
				{#if step.image?.data?.attributes?.url}
					<img
						src={resizeCDNImage(step.image.data.attributes.url, 430)}
						alt=""
						class="mt-auto w-full object-cover"
						loading="lazy"
						width="426"
					/>
				{/if}
			</div>
		{/each}
	</div>
</div>

{#if !(wrapperWidth < containerWidth)}
	<div class="inset-x-0 mx-auto mt-10 flex w-fit gap-4 text-sm">
		<button
			on:click={() => scrollBackwards()}
			class="flex aspect-square items-center rounded-full bg-neutral-200 px-5 {leftMostScroll
				? 'cursor-default opacity-50'
				: 'opacity-100 hover:bg-neutral-300'}"
			><img
				src="/images/icons/arrow/next-black-simple.svg"
				alt="previous"
				class="h-[.8em] rotate-180"
				loading="lazy"
			/></button
		>
		<button
			on:click={() => scrollForwards()}
			class="flex items-center gap-4 rounded-full bg-neutral-200 px-6 font-semibold transition-colors {rightMostScroll
				? 'cursor-default opacity-50'
				: 'opacity-100 hover:bg-neutral-300'}"
			>{t('web.dynamic_pages.components.nextSteps')}
			<img
				loading="lazy"
				src="/images/icons/arrow/next-black-simple.svg"
				alt="previous"
				class="h-[.8em]"
			/></button
		>
	</div>
{/if}

<style lang="scss">
	.carousel-box {
		width: calc(80vw);
		margin-left: 12px;

		&:last-of-type {
			margin-right: 12px;

			@screen md {
				margin-right: 0px;
			}
		}

		@screen md {
			width: calc(40vw);
			//width: calc(min(calc(100vw / 2 - 32px), calc(1328px / 2) - 16px));
		}

		@screen xl {
			width: calc(min(calc(100vw / 3 - 32px), calc(1328px / 3) - 16px));
			&:first-of-type {
				margin-left: 0px;
			}

			&:not(:first-of-type) {
				margin-left: 24px;
			}
		}
	}
</style>
