<script lang="ts">
	export let width: number
	export let text: string
	export let title: string
	export let image: { data: { attributes: { url: string } } }
	export let link: { linkUrl: string; linkText: string; icon: string }
	export let badge: { text: string }
	export let theme02: 'GRAY' | 'WHITE' | 'BORDERED' = 'WHITE'

	let widthClass: string = ''
	let badgeLetters = badge?.text?.length

	let badgeWidth: number

	// this code adjusts width of the badge container based on the number of letters in the badge text (to prevent overflow and weird looks)
	// text limit in Strapi is (and should be) se to 20 characters
	switch (true) {
		case badgeLetters < 14:
			badgeWidth = badgeLetters + 1
			break
		case badgeLetters < 17:
			badgeWidth = badgeLetters - Math.floor(badgeLetters / 5) + 1
			break
		default:
			badgeWidth = badgeLetters - Math.floor(badgeLetters / 5)
			break
	}

	const tailwindWidths: { [key: string]: string } = {
		12: '48',
		13: '52',
		14: '56',
		15: '60',
		16: '64',
		18: '72',
		20: '80'
	}

	Object.keys(tailwindWidths).forEach((possible) => {
		if (!widthClass && badgeWidth <= parseInt(possible)) widthClass = tailwindWidths[possible]
	})
</script>

<div
	class="col-span-12 rounded-2xl px-10-res py-10 md:col-span-{width} flex self-stretch @container"
	class:bg-white={theme02 === 'WHITE'}
	class:bg-neutral-100={theme02 === 'GRAY'}
	class:border={theme02 === 'BORDERED'}
>
	{#if badge?.text && widthClass}
		<div class="absolute right-0 top-0 aspect-square custom-h-{widthClass} overflow-hidden">
			<div
				class="relative flex aspect-square h-full w-full -translate-y-12 translate-x-12 items-center justify-center overflow-visible"
			>
				<div class="rotate-45 bg-primary-500 px-40 py-1">
					<p class="unstyled text-center text-sm font-bold text-white">
						{badge.text}
					</p>
				</div>
			</div>
		</div>
	{/if}
	<div class="flex h-full w-full flex-col items-start gap-10 @sm:flex-row">
		{#if image?.data?.attributes}
			<img
				src={image.data.attributes.url}
				alt={title}
				class="p-2-res-25 hidden w-full max-w-[10rem] basis-4/12 object-contain @sm:flex sm:basis-5/12 sm:p-4 md:p-0"
				loading="lazy"
			/>
		{/if}
		<div
			class="basis-{image?.data?.attributes
				? 'full'
				: 'full'} box-02-text-cont flex h-full flex-col"
		>
			<h4 class="unstyled mb-8 mt-0 text-xl font-bold">{title}</h4>
			<div class="text mb-6 text-base text-neutral-500">{@html text}</div>
			{#if link?.linkText && link?.linkUrl}
				<a href={link.linkUrl} class="btn-new btn-new-blue mt-auto w-fit text-sm">
					{link.linkText}
				</a>
			{/if}
		</div>
	</div>
</div>

<style lang="scss">
	// leave it here to make sure all nescessary classes are imported (hack – open to refactoring / better way)
	.custom-h-48 {
		@apply h-48;
	}

	.custom-h-52 {
		@apply h-52;
	}

	.custom-h-56 {
		@apply h-56;
	}

	.custom-h-60 {
		@apply h-60;
	}

	.custom-h-64 {
		@apply h-64;
	}

	.custom-h-72 {
		@apply h-72;
	}

	.custom-h-80 {
		@apply h-80;
	}

	.box-02-text-cont {
		:global(h6) {
			@apply mt-2 text-base text-neutral-400;
		}

		:global(ul) {
			@apply list-disc py-4;

			:global(li) {
				@apply py-1.5;
			}
		}
	}
</style>
