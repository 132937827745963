<script lang="ts">
	import { page } from '$app/stores'
	import { t } from '$lib/translations'

	export let width: number
	export let title: string
	export let suptext: string
	export let questionsLimit: number | null
	export let order: 'CUSTOM_FIRST' | 'RELATED_FIRST'
	export let questions: {
		question: string
		answer: string
	}[]
	export let faq: {
		data: {
			attributes: {
				questions: {
					question: string
					answer: string
				}[]
			}
		}
	}

	const allQuestions =
		order === 'CUSTOM_FIRST'
			? [...questions, ...(faq?.data?.attributes?.questions || '')]
			: [...(faq?.data?.attributes?.questions || ''), ...questions]

	let faqNumber = questionsLimit || allQuestions.length

	let faqElements: {
		[index: number]: HTMLDivElement
	} = {}
</script>

<div
	class="col-span-{width} flex w-full grid-cols-12 flex-col items-start gap-8 md:grid {$page.data
		?.layout?.background === 'gray'
		? 'rounded-2xl bg-white px-1 pb-12 md:px-12'
		: ''}"
>
	<div class="col-span-4 mt-12 w-full max-w-4xl text-center md:text-left">
		{#if suptext}
			<h6 class="mb-4 font-semibold text-primary-500">{suptext}</h6>
		{/if}

		{#if title}
			<h2 class="unstyled h2 mt-0">{@html title}</h2>
		{/if}
	</div>

	<div class="col-span-8 mt-12 flex w-full flex-col gap-1 transition-all">
		{#each allQuestions as faq, i}
			{#if i < faqNumber}
				<div
					class="faq-item w-full cursor-pointer border-t border-neutral-100 bg-white px-4 py-8 md:p-8"
					role="presentation"
					bind:this={faqElements[i]}
					class:border-t-0={faqElements[i - 1]?.classList?.contains('active')}
					on:click={() => {
						faqElements[i].classList.toggle('active')
						faqElements = faqElements
					}}
				>
					<h4 class="unstyled relative text-2xl-res font-bold sm:text-xl">
						<span class="inline-block w-[calc(100%-32px)]">{faq.question}</span>
					</h4>
					<div class="faq-content text-lg-res md:text-base">{@html faq.answer}</div>
				</div>
			{/if}
		{/each}
		{#if questionsLimit && questionsLimit < allQuestions.length}
			<button
				class="ml-4 mt-2 flex items-center gap-3 self-start rounded-full text-base opacity-90 hover:underline md:ml-8"
				on:click={() => {
					faqNumber === questionsLimit
						? (faqNumber = allQuestions.length)
						: (faqNumber = questionsLimit || allQuestions.length)
				}}
			>
				{faqNumber === questionsLimit
					? t('web.dynamic_pages.components.showAll')
					: t('web.dynamic_pages.components.showLess')}
				<img
					class="aspect-square h-[.8em] {faqNumber === questionsLimit ? 'rotate-90' : '-rotate-90'}"
					src="/images/icons/arrow/chevron-black.svg"
					alt={faqNumber === questionsLimit
						? t('web.dynamic_pages.components.showAll')
						: t('web.dynamic_pages.components.showLess')}
					loading="lazy"
				/>
			</button>
		{/if}
	</div>
</div>

<style lang="scss">
	.faq-content {
		:global(p) {
			@apply mb-4 mt-2 text-base leading-loose;
		}

		:global(strong) {
			@apply font-bold;
		}

		:global(ul) {
			@apply my-6 list-disc;
		}

		:global(li) {
			@apply my-2 text-lg;
		}
		:global(a) {
			@apply text-primary-500;
		}
		:global(ol) {
			@apply list-decimal;
		}
		:global(ul) {
			@apply list-disc;
		}
	}

	.faq-item {
		&:first-child {
			border-top: none;
		}
		h4 {
			&::after {
				@apply absolute flex items-center justify-center content-[url('/images/icons/arrow-down-black.svg')];
				@apply right-0 top-1/2 aspect-square h-8 translate-y-[calc(-50%)];

				@screen md {
					@apply -translate-y-1/2;
				}
			}
		}

		.faq-content {
			@apply mb-0 mt-0 h-0 overflow-hidden;
		}

		&.active {
			@apply rounded-2xl bg-neutral-100;
			h4::after {
				transform: rotate(180deg) translateY(calc(50%));

				@screen md {
					transform: rotate(180deg) translateY(calc(50%));
				}
			}

			.faq-content {
				@apply h-auto max-h-fit pt-4;
			}
		}
	}
</style>
