<script lang="ts">
	type AspectRatio = 'aspect_16_9' | 'aspect_3_2' | 'aspect_4_3' | 'aspect_1_1'
	export let width: number
	export let video: {
		data: {
			attributes: {
				ext: string
				url: string
			}
		}
	}
	export let thumbnail: {
		data: {
			attributes: {
				url: string
			}
		}
	}

	export let description: string
	export let aspectRatio: AspectRatio
	export let aspectRatioMobile: AspectRatio

	const ratioClass =
		{
			aspect_16_9: 'aspect-video',
			aspect_3_2: 'aspect-[3/2]',
			aspect_4_3: 'aspect-[4/3]',
			aspect_1_1: 'aspect-square'
		}[aspectRatio] || 'aspect-[16/9]'

	const ratioClassMobile =
		{
			aspect_16_9: 'aspect-video',
			aspect_3_2: 'aspect-[3/2]',
			aspect_4_3: 'aspect-[4/3]',
			aspect_1_1: 'aspect-square'
		}[aspectRatioMobile] || ratioClass
</script>

{#if video?.data?.attributes?.url}
	<div class="col-span-12 w-full md:col-span-{width}">
		<div>
			<iframe
				title={description || 'video'}
				src="{video.data.attributes.url}{thumbnail?.data?.attributes?.url &&
					`?poster=${thumbnail.data.attributes.url}`}"
				loading="lazy"
				class="{ratioClassMobile} md:{ratioClass} w-full rounded-2xl"
				allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
			/>
		</div>
	</div>
{/if}
