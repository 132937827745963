<script lang="ts">
	import { dayjs } from '@woltair/translations-package-fe'
	import { BROWSER } from 'esm-env'
	import { flip } from 'svelte/animate'
	import { quintOut } from 'svelte/easing'
	import { crossfade } from 'svelte/transition'
	import { t } from '$lib/translations'
	import { cropWordLength } from '$lib/utils'
	const [send, receive] = crossfade({
		fallback: crossfadeFallback
	})
	function crossfadeFallback(node) {
		const style = getComputedStyle(node)
		const transform = style.transform === 'none' ? '' : style.transform

		return {
			duration: 400,
			easing: quintOut,
			css: (t) => `transform: ${transform} scale(${t}); opacity: ${t}`
		}
	}
	export let title: string
	export let mediadata: Array<Object>

	function directClick(e, article) {
		if (BROWSER) {
			if (article?.url) {
				window?.open(article.url, '_blank').focus()
			} else if (article?.download?.data?.attributes?.url) {
				window
					?.open(
						article?.download?.data?.attributes?.url?.replace('public', 'width=2000'),
						'_blank'
					)
					.focus()
			}
		}
	}

	let displayedItems = mediadata.slice(0, 4)
	let currentPage = 1
	let pageSize = 4
	function loadMore() {
		if (currentPage * pageSize < mediadata.length) {
			const nextPageItems = mediadata.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
			displayedItems = [...displayedItems, ...nextPageItems]
			currentPage++
		}
	}
</script>

<section class="w-full">
	<div class="mb-4 flex w-full items-end justify-between">
		<h2
			class="unstyled mb-4 mt-8 w-full text-center text-xl font-semibold text-w-blue-950 md:w-fit md:text-left md:text-4xl"
		>
			{title}
		</h2>
	</div>
	<div class="grid w-full gap-4 md:grid-cols-2">
		{#each displayedItems as article (article)}
			<div
				animate:flip={{ duration: 400, easing: quintOut }}
				in:receive|local={{ key: article }}
				out:send|local={{ key: article }}
				role="button"
				tabindex="0"
				on:keydown={(e) => {
					if (e.key == 'Enter') directClick(e, article)
				}}
				on:click={(e) => directClick(e, article)}
				class="grid cursor-pointer grid-cols-12 items-center gap-4 rounded-3xl md:bg-neutral-100"
			>
				<div
					class="col-span-4 flex h-full max-h-[220px] min-h-[110px] items-stretch overflow-hidden rounded-l-3xl md:col-span-5"
				>
					{#if article?.download?.data?.attributes && article?.download?.data?.attributes.mime.startsWith('image/')}
						<img
							src={article?.download?.data?.attributes?.url}
							alt={`Image – ${title}`}
							class="w-full object-cover"
							loading="lazy"
						/>
					{:else}
						<div class="inset-4 mx-auto self-center">
							<img
								class="object-contain"
								src="/images/icons/2023_ci/dark/document.svg"
								alt="icon"
								loading="lazy"
							/>
						</div>
					{/if}
				</div>
				<div class="col-span-8 py-4 md:col-span-7">
					{#if article.publisherName || article.publishDate}
						<div class="flex items-center text-neutral-400">
							{#if article.publisherName}
								<div class="">{article.publisherName}</div>
							{/if}
							{#if article.publisherName && article.publishDate}
								<div class="px-2 text-xl font-extrabold">&#8901;</div>
							{/if}
							{#if article.publishDate}
								<div class="capitalize">
									{dayjs(article.publishDate).format('MMM DD. YYYY')}
								</div>
							{/if}
						</div>
					{/if}
					<div class="text-lg font-semibold text-secondary-500">{article.title}</div>
					<div class="">
						{#if article?.perex}{cropWordLength(article.perex)}{/if}
					</div>
				</div>
			</div>
		{/each}
	</div>
	{#if mediadata.length > displayedItems.length}
		<div class="my-8 block w-full text-center">
			<button
				class="mx-auto flex h-6 items-center justify-center rounded-full bg-neutral-100 px-6 py-6 text-sm font-bold"
				on:click={loadMore}
				>{t('web.dynamic_pages.components.showMore')}
			</button>
		</div>
	{/if}
</section>
