<script lang="ts">
	import { t } from '$lib/translations'
	type File = {
		data: {
			attributes: {
				url: string
			}
		}
	}

	export let file: File
</script>

<div class="flex w-full max-w-5xl flex-col items-center gap-4">
	<object
		data={file.data.attributes.url}
		type="application/pdf"
		width="100%"
		class="h-screen"
		title="pdf"
	/>
	<a href={file.data.attributes.url} target="_blank" class="btn btn-filled-primary w-fit"
		>{t('web.global.download')}</a
	>
</div>
