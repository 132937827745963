<script lang="ts">
	export let table: string
	export let width: number

	let tableWidth: number
	let containerWidth: number
</script>

<div
	class="table-cont col-span-12 md:col-span-{width}  relative flex w-full max-w-4xl justify-start"
>
	<div class="hide-scrollbar w-full overflow-x-scroll" bind:clientWidth={containerWidth}>
		<div
			class="simple-table w-full min-w-[600px] overflow-hidden rounded-3xl bg-white"
			bind:clientWidth={tableWidth}
		>
			{@html table}
		</div>
	</div>
	<div
		class="pointer-events-none absolute right-0 top-0 h-full w-20 bg-gradient-to-r from-white/0 to-white opacity-0 transition-opacity {tableWidth >
			containerWidth && 'opacity-100'}"
	/>
</div>

<style lang="postcss">
	.table-cont {
		:global(.simple-table) {
			:global(*):not(
					figure,
					table,
					tbody,
					td,
					tr,
					th,
					thead,
					tfoot,
					p,
					.raw-html-embed,
					span,
					div,
					br,
					strong,
					img
				) {
				@apply hidden;
			}

			:global(figure) {
			}
			:global(table) {
				@apply w-full align-top;
			}
			:global(tbody) {
			}
			:global(tr) {
				@apply border-none normal-case;

				&:last-child :global(td) {
					@apply pb-8;
				}

				&:nth-child(2n - 1) :global(td) {
					@apply bg-neutral-100;
				}
			}
			:global(thead) {
				@apply border-none;

				:global(tr) {
					:global(th) {
						@apply px-8 pt-8 align-top;
					}
				}
			}
			:global(th) {
				@apply break-words bg-white text-base font-normal text-neutral-400;

				&:first-child {
					@apply w-[27%];
				}

				&:not(:first-child) {
					@apply border-l border-gray-200;
				}
			}
			:global(td) {
				@apply rounded-none bg-white px-4 py-3 text-sm;

				&:first-child {
					@apply font-bold;
				}

				&:not(:first-child) {
					@apply border-l border-gray-200;
				}
			}
		}
	}
</style>
