<script lang="ts">
	import { sendGTM } from '$lib/analytics'

	export let width: number
	export let text: string
	export let title: string
	export let image: { data: { attributes: { url: string } } }
	export let link: {
		linkUrl: string
		linkText: string
		onClickGTM: {
			[key: string]: string | { [key: string]: string }
		} | null
	}
</script>

<div
	class="col-span-12 md:col-span-{width} flex flex-col gap-6-res self-stretch py-8-res pl-0 md:pr-12"
>
	{#if image?.data?.attributes}
		<img
			src={image.data.attributes.url}
			alt={title}
			class="aspect-square w-2/12 max-w-[84px] md:w-3/12"
			loading="lazy"
		/>
	{/if}
	<h4 class="unstyled mt-2 text-xl font-semibold text-w-blue-950 md:text-2xl">{title}</h4>
	<div class="text text-sm leading-relaxed text-neutral-500 md:text-base">{@html text}</div>
	{#if link}
		<a
			href={link.linkUrl}
			on:click={() => {
				if (link?.onClickGTM) {
					sendGTM('', {}, link.onClickGTM)
				}
			}}
			class="mt-auto"
			><p class="arrow-after text-base font-semibold text-w-blue-950 hover:text-w-red-500">
				{link.linkText}
			</p></a
		>
	{/if}
</div>
