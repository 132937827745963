<script>
	export let text
	export let url
	export let type
	export let margin = 'my-6'

	let buttonClass = {
		RED: 'btn-filled-primary',
		GREY: 'btn-filled-accent hover:text-white',
		OUTLINE: 'btn-outline-accent'
	}
</script>

<a class="btn {buttonClass[type]} {margin} text-wrap" href={url}>{text}</a>
