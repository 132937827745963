import { gql } from 'graphql-request'

const catalogPackageFragment = gql`
	fragment CatalogPackage on WaPackageDto {
		materialTotalPrice
		materialTotalPriceWithVat
		mainItemTotalPrice
		mainItemTotalPriceWithVat
		dynamicPrice
		dynamicPriceWithVat
		discountWithVat
		catalogTotalPrice
		catalogTotalPriceWithVat
		noiseLevel
		originCountry
		slugId
		images {
			url
		}
		files {
			url
			fileName
			size
		}
		thumbnail
		brandName
		refrigerantType
		radiatorHeatingPerformance
		accumulationVolume
		accumulationVesselCapacity
		accumulationVessel
		waterTankCapacity
		heatPumpSystem
	}
`

const deviceTypeFragment = gql`
	fragment DeviceType on PumpResultData {
		name
		catalogId
		description
		subsidy
		roi
		bivalentPoint
		co2Savings
		mwhSavings
		annualMoneySavings
		annualConsumption
		purchasePrice
		germanGrant

		temperatureCost {
			temp
			cost
		}
		catalogPackage {
			...CatalogPackage
		}
	}
`

export const WoltAdvisorHvacFromQuery = gql`
	${catalogPackageFragment}
	${deviceTypeFragment}

	query ($request: WoltAdvisorFromQueryRequest!) {
		woltAdvisorHvacFromQuery(request: $request) {
			yourTotalYearlyCosts
			yourElPricePerKwh
			yourAnnuallyMwh
			houseHeatLoss
			grantWithWater
			grantWithoutWater
			co2
			deviceTypes {
				brandName
				withWater {
					...DeviceType
				}
				noWater {
					...DeviceType
				}
			}
		}
	}
`
export const WoltAdvisorHvacFromQueryShort = gql`
	query ($request: WoltAdvisorFromQueryRequest!) {
		woltAdvisorHvacFromQuery(request: $request) {
			yourTotalYearlyCosts
			yourElPricePerKwh
			yourAnnuallyMwh
			yourTotalYearlyCosts
			houseHeatLoss
			grantWithWater
			grantWithoutWater
		}
	}
`
export const WoltAdvisorAvailableFveComponents = gql`
	query ($request: AvailablePVComponentsRequest!) {
		availablePVComponents(request: $request) {
			# inverters {
			# 	min
			# 	max
			# 	name
			# 	componentType
			# 	packageId
			# 	attributes {
			# 		unit
			# 		value
			# 		title
			# 	}
			# }
			panels {
				min
				max
				name
				componentType
				packageId
				attributes {
					unit
					value
					title
				}
			}
			batteries {
				min
				max
				name
				componentType
				packageId
				attributes {
					unit
					value
					title
				}
			}
		}
	}
`

export const WoltAdvisorFve = gql`
	query ($request: WoltAdvisorFveRequest!) {
		woltAdvisorFve(request: $request) {
			electricityPricePerKWh
			monthlyElectricityCost
			annualElectricityConsumptionMWh
			variants {
				variantCriterium
				batteryCapacity
				fvePower
				fveProduction
				totalPrice
				grant
				roi
				priceWork
				priceMaterial
				fveConsumptionData {
					monthData {
						month
						monthOrd
						consumption
						consumptionWithFve
						energyProduction
						moneySavings
						fveCoverage
						surplus
					}
					seasonData {
						season
						fveCoverage
					}
				}
				components {
					brand
					componentType
					packageId
					packageUid
					quantity
					name
					description
					price
					min
					max
					attributes {
						unit
						title
						value
					}
					thumbnail
					images {
						url
					}
					files {
						tags
						mimeType
						fileName
						url

						size
					}
				}
			}
		}
	}
`

export const WoltadvisorPackageHvac = gql`
	query ($request: WoltAdvisorCatalogPackageSummaryRequest!) {
		woltAdvisorCatalogPackageSummary(request: $request) {
			slugId
			catalogId
			catalogUid
			organizationId
			discount
			discountWithVat
			title
			description
			descriptionProduct
			deviceSubtypeId
			discount
			materialTotalPrice
			materialTotalPriceWithVat
			catalogTotalPrice
			catalogTotalPriceWithVat
			dynamicPrice
			dynamicPriceWithVat

			images {
				url
				mimeType
				fileName
			}

			files {
				url
				tags
				mimeType
				size
				fileName
			}
			thumbnail
			noiseLevel
			heatPumpType
			heatPumpSystem
			originCountry
			withWater
			currencyCode
			languageCode
			supplierId
			supplierName
			brandName
			maxHeatLoss
			radiatorHeatingCop
			radiatorHeatingPerformance
			floorHeatingCop
			floorHeatingPerformance
			operatingRange
			workTotalPrice
			workTotalPriceWithVat
			mainItemTotalPriceWithVat
			mainItemWidth
			mainItemHeight
			mainItemDepth
			mainItemTotalPrice
			energyEfficiencyRating
			coolingPerformance
			inputWaterTemperature
			scop
			inverter
			refrigerantControl
			refrigerantType
			refrigerantVolume
			gwpCo2
			gwpRefrigerant
			yearlyCheck
			numberOfPhases
			coolingOption
			countryOfOrigin
			sgReady
			internetConnection
			compressor
			compressorCount
			accumulationVolume
			waterTankCapacity
			accumulationVesselCapacity
			accumulationVessel
			siblingPackage {
				catalogId
				slugId
				withWater
			}
		}
	}
`
