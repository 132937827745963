<script lang="ts">
	import { string, number, object, boolean } from 'yup'
	import { REGIONS } from '$lib/consts'
	import { t } from '$lib/translations'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	export let width: number
	export let type: 'DEFAULT' | 'HVAC' | 'PV'

	export let layout: 'V1' | 'V2' = 'V1'

	export let consents: {
		title?: string
		boxes?: {
			name: string
			label: string
			defaultValue: boolean
			required: boolean
		}[]
	} = {}

	const schema = object({
		annualyElectroConsumption: string()
			.required(t('web.forms.annualyElectroConsumption.validation.required'))
			.trim()
			.matches(/^[0-9]*$/, t('web.forms.annualyElectroConsumption.validation.format')),
		actualHeating: string().required(t('web.forms.heatingType')),
		region: string().required(t('web.forms.installation.region')),
		funding: string().required(t('web.forms.leaveContact.category')),
		phone: number()
			.typeError(t('web.forms.leaveContact.phone.format'))
			.min(100000000, t('web.forms.leaveContact.phone.format'))
			.required(t('web.forms.leaveContact.phone.validation')),
		...(consents?.boxes?.length && {
			...consents.boxes.reduce((accumulator, value: any) => {
				if (value?.required === true) {
					return {
						...accumulator,
						[value.name]: boolean()
							.oneOf([true], t('web.forms.field.required'))
							.required(t('web.forms.field.required'))
					}
				} else {
					return {
						...accumulator,
						[value.name]: boolean().oneOf([true, false])
					}
				}
			}, {})
		})
	})

	let formData: {
		actualHeating?: string
		region?: string
		funding?: string
		phone?: number | string
		[key: string]: any
	} = {
		phone: '',
		...(consents?.boxes?.length && {
			...consents.boxes.reduce((accumulator, value: any) => {
				return { ...accumulator, [value.name]: value.defaultValue || false }
			}, {})
		})
	}

	$: selectChange = {}
	let formErrors: Record<string, any> = {}

	let formSent = false
	let formDone = false

	async function validatePhone(fieldName: string, value: string) {
		try {
			if (!value) {
				formErrors[fieldName] = t('web.forms.leaveContact.phone.validation')
				return
			}

			const response = await fetch('/api/validation/phone', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					phone: value
				})
			})

			if (response.ok) {
				const data: {
					number: string
					valid: boolean
					numberFormatted?: string
				} = await response.json()
				if (data.valid) {
					formErrors[fieldName] = ''
					formData[fieldName] = data.numberFormatted
				} else {
					formErrors[fieldName] = t('web.forms.leaveContact.phone.format')
				}
			}
		} catch (error: any) {
			logger.debug('formGrants01.validatePhone.failed', error.message)
			formErrors['phone'] = error.message
		}
	}

	async function validateField(fieldName: string, value: string) {
		try {
			await schema.validateAt(fieldName, { [fieldName]: value })
			formErrors[fieldName] = ''
		} catch (error: any) {
			logger.debug('formGrants01.validatePhone.failed', error.message)
			formErrors[fieldName] = error.message
		}
	}

	async function handleSubmit(event: Record<string, any>) {
		event.preventDefault()

		try {
			formErrors = {}
			await Promise.all(
				Object.entries(formData).map(async ([key, value]) => {
					if (key === 'phone') await validatePhone(key, value.toString() || '')
					else await validateField(key, value.toString() || '')
				})
			)

			if (Object.values(formErrors).some((error) => error)) throw new Error('Validation failed')

			formSent = true

			const response = await fetch('/api/lead/create', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(formData)
			})

			if (response.ok) {
				formSent = false
				formDone = true
			} else {
				formSent = false
				formDone = false
			}
		} catch (error: any) {
			if (error.inner) {
				error.inner.forEach((err: any) => {
					formErrors[err.path] = err.message
				})
			}
		}
	}
</script>

<div
	class="col-span-12 md:col-span-{width} w-full rounded-3xl bg-white p-12-res py-14-res @container"
>
	<div
		class="pointer-events-none absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center"
	>
		<div class="relative aspect-square w-[64px]">
			<div
				class="absolute inset-x-0 mx-auto aspect-square w-full animate-spin rounded-full border-[6px] border-primary-500 border-t-white opacity-0 transition-opacity"
				class:opacity-100={formSent && !formDone}
			/>
			<div
				class="pointer-events-none absolute inset-x-0 mx-auto aspect-square w-full bg-[url(/static/images/icons/tick-circle.svg)] bg-contain opacity-0 transition-opacity"
				class:opacity-100={formDone}
			/>
		</div>
		<p class="mt-2 w-10/12 text-center text-xl font-bold opacity-0" class:opacity-100={formDone}>
			{t('web.forms.leaveContact.thankYou')}
		</p>
	</div>
	<form
		on:submit|preventDefault={handleSubmit}
		class:opacity-0={formSent || formDone}
		class:pointer-events-none={formSent || formDone}
		class="transition-opacity"
	>
		<div
			class="grid grid-cols-1 gap-x-4 gap-y-2 @md:gap-y-6 {layout === 'V2'
				? '@md:grid-cols-2'
				: '@xl:grid-cols-3'}"
		>
			{#if type === 'DEFAULT' || type === 'HVAC'}
				<div class="flex w-full flex-col">
					<label for="actualHeating" class="mb-1 text-neutral-500"
						>{t('web.grants.form.heatSource.question')}</label
					>
					<select
						name="actualHeating"
						class="select"
						bind:value={formData.actualHeating}
						on:change={() => {
							selectChange['actualHeating'] = true
							validateField('actualHeating', formData.actualHeating || '')
						}}
						class:!border-primary-500={formErrors.actualHeating}
						class:!text-neutral-800={selectChange['actualHeating']}
					>
						<option disabled selected value="">{t('web.forms.choose')}</option>
						<option value={t('web.grants.form.heatSource.gas')}
							>{t('web.grants.form.heatSource.gas')}</option
						>
						<option value={t('web.grants.form.heatSource.hvac')}
							>{t('web.grants.form.heatSource.hvac')}</option
						>
						<option value={t('web.grants.form.heatSource.solidFuel')}
							>{t('web.grants.form.heatSource.solidFuel')}</option
						>
						<option value={t('web.grants.form.heatSource.electricity')}
							>{t('web.grants.form.heatSource.electricity')}</option
						>
						<option value={t('web.grants.form.heatSource.other')}
							>{t('web.grants.form.heatSource.other')}</option
						>
					</select>
					{#if formErrors.actualHeating}
						<p class="mt-1 text-sm text-primary-500">{formErrors.actualHeating}</p>
					{/if}
				</div>
			{/if}
			{#if type === 'PV'}
				<div class="flex w-full flex-col">
					<label for="annualyElectroConsumption" class="mb-1 text-neutral-500"
						>{t('web.forms.annualyElectroConsumption.text')}</label
					>
					<input
						bind:value={formData.annualyElectroConsumption}
						on:change={() => {
							validateField('annualyElectroConsumption', formData.annualyElectroConsumption || '')
						}}
						class="input"
						type="text"
						name="annualyElectroConsumption"
						placeholder={t('web.forms.annualyElectroConsumption.placeholder')}
						class:!border-primary-500={formErrors.annualyElectroConsumption}
					/>
					{#if formErrors.annualyElectroConsumption}
						<p class="mt-1 text-sm text-primary-500">{formErrors.annualyElectroConsumption}</p>
					{/if}
				</div>
			{/if}
			{#if type === 'DEFAULT'}
				<div class="flex w-full flex-col">
					<label for="funding" class="mb-1 text-neutral-500"
						>{t('web.grants.form.grantPurpose')}</label
					>
					<select
						name="funding"
						class="select"
						bind:value={formData.funding}
						on:change={() => {
							selectChange['funding'] = true
							validateField('funding', formData.funding || '')
						}}
						class:!border-primary-500={formErrors.funding}
						class:!text-neutral-800={selectChange['funding']}
					>
						<option disabled selected value="">{t('web.forms.choose')}</option>
						<option value="FOTOVOLTAIKA">{t('web.global.photoVoltaics')}</option>
						<option value="TEPELNE_CERPADLO">{t('web.global.heatPump')}</option>
					</select>
					{#if formErrors.funding}
						<p class="mt-1 text-sm text-primary-500">{formErrors.funding}</p>
					{/if}
				</div>
			{/if}
			{#if type === 'HVAC' || type === 'PV'}
				<div class="flex w-full flex-col">
					<label for="region" class="mb-1 text-neutral-500"
						>{t('web.forms.installation.region')}</label
					>
					<select
						bind:value={formData.region}
						on:change={() => {
							selectChange['region'] = true
							validateField('region', formData.region || '')
						}}
						class:!text-neutral-800={selectChange['region']}
						name="region"
						class="select"
						class:!border-primary-500={formErrors.region}
					>
						<option disabled selected hidden value="">{t('web.forms.choose')}</option>
						{#each REGIONS[PUBLIC_WOLTAIR_WEB_CODE] as region}
							<option value={region?.name}>{region?.name}</option>
						{/each}
					</select>
					{#if formErrors.region}
						<p class="mt-1 text-sm text-primary-500">{formErrors.region}</p>
					{/if}
				</div>
			{/if}
			<div class="flex w-full flex-col {layout === 'V2' ? '@md:col-span-2' : ''}">
				<div class="flex w-full flex-col">
					<label for="phone" class="mb-1 text-neutral-500">{t('web.forms.yourPhone')}</label>
					<input
						bind:value={formData.phone}
						on:change={() => {
							validatePhone('phone', formData.phone?.toString() || '')
						}}
						class="input"
						type="text"
						name="phone"
						placeholder={t('web.forms.phone.placeholder')}
						class:!border-primary-500={formErrors.phone}
					/>
					{#if formErrors.phone}
						<p class="mt-1 text-sm text-primary-500">{formErrors.phone}</p>
					{/if}
				</div>
			</div>
		</div>
		{#if consents?.boxes?.length}
			<div class="mt-4">
				{#if consents.title}
					<p class="mb-4 text-sm text-neutral-500">{consents.title}</p>
				{/if}
				{#each consents.boxes as box}
					<div class="mt-2 flex items-center">
						<input
							type="checkbox"
							name={box.name}
							id={box.name}
							bind:checked={formData[box.name]}
							class="checkbox mr-2"
							class:!border-primary-500={formErrors[box.name]}
						/>
						<label for={box.name} class="checkbox-label text-sm text-neutral-500"
							>{@html box.label}</label
						>
					</div>
					{#if formErrors[box.name]}
						<p class="mt-1 text-sm text-primary-500">{formErrors[box.name]}</p>
					{/if}
				{/each}
			</div>
		{/if}
		<button type="submit" class="btn btn-filled-primary mt-4 w-full @md:mt-8"
			>{t('web.forms.checkGrants')}</button
		>
	</form>
</div>

<style lang="scss">
	.btn {
		@apply transition-colors;
	}

	.select {
		@apply cursor-pointer rounded-lg border-2 border-neutral-200 bg-white p-4 text-sm font-normal text-neutral-400 duration-500;
	}

	.input {
		@apply w-full appearance-none rounded-lg border-2 border-neutral-200 bg-white p-4 text-sm text-neutral-800 shadow-sm outline-none ring-0 transition-colors duration-500;

		&::placeholder {
			@apply text-neutral-400;
		}
	}

	.checkbox-label :global(a) {
		@apply text-primary-500;
	}
</style>
