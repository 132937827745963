<script lang="ts">
	export let title: string
	export let list: {
		text: string
		icon: {
			data: {
				attributes: {
					url: string
				}
			}
		}
	}[]
	export let buttons: {
		text: string
		url: string
	}[]
	export let image: {
		data: {
			attributes: {
				url: string
			}
		}
	}
	export let imageMobile: {
		data: {
			attributes: {
				url: string
			}
		}
	}
	export let bgColorClass: string = 'from-orange-200/40 via-orange-200/40 to-transparent'
	export let width: number
</script>

<div
	class="col-span-12 w-full md:col-span-{width} relative overflow-hidden rounded-xl bg-neutral-100 @container"
>
	<div class="relative z-40 p-6 pb-48 @2xl:w-10/12 @2xl:px-10 @2xl:py-16 @2xl:pb-20">
		{#if title}
			<h3 class="unstyled text-3xl-res font-semibold">{@html title}</h3>
		{/if}
		<ul class="mt-6 !pl-0 @2xl:mt-10">
			{#each list as item}
				<li class="mt-5 flex items-start gap-x-3 text-sm">
					{#if item.icon?.data?.attributes?.url}
						<img src={item.icon.data.attributes.url} alt="List icon" class="mt-0.5 h-[1.2em]" />
					{:else}
						<img
							src="/images/icons/checkmark-circle-green.svg"
							alt="List icon"
							class="mt-0.5 h-[1.2em]"
						/>
					{/if}

					{#if item.text}
						<span class="opacity-60">{@html item.text}</span>
					{/if}
				</li>
			{/each}
		</ul>
		{#if buttons.length}
			<div class="mt-10 flex flex-col gap-2">
				{#each buttons as button}
					<a href={button.url} class="btn-new btn-new-blue w-fit text-wrap">
						{button.text}
					</a>
				{/each}
			</div>
		{/if}
	</div>
	<div
		class="absolute top-0 z-20 h-full w-full bg-gradient-to-b {bgColorClass
			? bgColorClass
			: 'from-neutral-100 via-neutral-100 to-transparent'} @2xl:bg-gradient-to-r"
	/>
	<div
		class="absolute top-0 z-10 h-full w-full bg-gradient-to-b from-neutral-100 via-neutral-100 to-transparent @2xl:bg-gradient-to-r"
	/>
	{#if image?.data?.attributes?.url}
		<div
			class="absolute bottom-0 right-0 z-0 h-auto w-full object-cover @2xl:top-0 @2xl:h-full @2xl:w-auto"
		>
			<div
				class="absolute top-0 z-10 h-full w-full bg-gradient-to-b from-neutral-100 via-transparent to-transparent @2xl:hidden @2xl:bg-gradient-to-r"
			/>
			<img
				src={image.data.attributes.url}
				alt=""
				class="h-full w-full {imageMobile?.data?.attributes?.url ? 'hidden sm:block' : ''}"
			/>
			{#if imageMobile?.data?.attributes?.url}
				<img class="h-full w-full" src={imageMobile?.data?.attributes?.url} alt="" />
			{/if}
		</div>
	{/if}
</div>
