<script lang="ts">
	type Box = {
		title: string
		amount: string
		aftertext: string
		subtext: string
	}

	export let boxA: Box
	export let boxB: Box
</script>

{#if boxA && boxB}
	<div class="col-span-12 flex w-full justify-center">
		<div class="flex w-full max-w-screen-lg flex-col overflow-hidden rounded-2xl md:flex-row">
			<div class="flex basis-1/2 justify-center bg-neutral-100 text-w-blue-950 @container">
				<div class="w-fit px-8 py-16 @lg:px-24 md:py-24">
					{#if boxA.title}
						<div class="mb-3 w-fit text-lg font-semibold @lg:text-2xl">{boxA.title}</div>
					{/if}
					{#if boxA.amount}
						<div class="flex w-fit flex-wrap items-center gap-3">
							<span class="whitespace-nowrap text-5.5xl-res font-semibold md:text-4.5xl-res"
								>{boxA.amount}</span
							>
							{#if boxA.amount}
								<span class="text-sm opacity-70 @lg:text-base">{boxA.aftertext}</span>
							{/if}
						</div>
					{/if}
					{#if boxA.subtext}
						<div class="mt-1 w-fit text-xs opacity-70 @lg:text-sm">{boxA.subtext}</div>
					{/if}
				</div>
			</div>
			<div class="flex basis-1/2 justify-center bg-w-blue-950 text-white @container">
				<div class="w-fit px-8 py-16 @lg:px-24 md:py-24">
					{#if boxB.title}
						<div class="mb-3 w-fit text-lg font-semibold @lg:text-2xl">{boxB.title}</div>
					{/if}
					{#if boxB.amount}
						<div class="flex w-fit flex-wrap items-center gap-3">
							<span class="whitespace-nowrap text-5.5xl-res font-semibold md:text-4.5xl-res"
								>{boxB.amount}</span
							>
							{#if boxB.amount}
								<span class="text-sm opacity-70 @lg:text-base">{boxB.aftertext}</span>
							{/if}
						</div>
					{/if}
					{#if boxB.subtext}
						<div class="mt-1 w-fit text-xs opacity-70 @lg:text-sm">{boxB.subtext}</div>
					{/if}
				</div>
			</div>
		</div>
	</div>
{/if}
