<script>
	export let people
	export let title
	export let text
</script>

<section class="page-wrapper mx-auto">
	<div class="text-wrapper flex w-full">
		<h3>{title}</h3>
		<p>
			{text}
		</p>
	</div>
</section>
<div class="persons page-wrapper mx-auto">
	{#each people as { name, position, photo: { data: { attributes: { url } } } }}
		<div class="person">
			<img src={url} alt={name} loading="lazy" />
			<p class="name font-semibold">{name}</p>
			<p class="text-gray-light text-xs-res lg:text-sm">{position}</p>
		</div>
	{/each}
</div>

<style lang="scss">
	section {
		background: #e20613;
		margin: 100px 0;
		padding: 40px 40px 120px;
		position: relative;

		@screen lg {
			padding: 50px 50px 150px;
			margin: 75px auto;
		}

		.text-wrapper {
			display: flex;
			justify-content: space-between;
			gap: 3rem;

			@media (max-width: 760px) {
				flex-direction: column;
			}

			h3 {
				color: #fff;
				font-weight: 900;
				white-space: nowrap;
				width: fit-content;
				margin-top: 0;
			}

			p {
				margin: 0;
				color: #fff;
				font-weight: 400;

				@screen lg {
					flex-basis: 100%;
					flex-shrink: 0;
				}
			}
		}
	}

	.persons {
		position: relative;
		z-index: 1;
		margin-top: -180px;
		margin-bottom: 75px;
		padding: 0 40px;
		display: flex;
		gap: 30px;
		flex-wrap: wrap;
		justify-content: center;

		@media (max-width: 760px) {
			flex-direction: column;
			width: 100%;
		}

		.person {
			flex: 1 0 calc(20% - 30px);
			max-width: calc(20% - 30px);

			@media (max-width: 760px) {
				flex: 1 0 100%;
				max-width: 100%;
			}

			img {
				object-fit: contain;
				object-position: top;
				width: 100%;
			}

			p {
				margin: 5px 0;
			}
		}
	}
</style>
