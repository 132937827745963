<script lang="ts">
	import { dayjs } from '@woltair/translations-package-fe'
	import { BLOG } from '$lib/routes'
	//import type { Article } from '$lib/types/strapi4'
	import { cropWordLength } from '$lib/utils'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	export let article: any
	export let hide = false
	const dateFormat = {
		WOLTAIR_CZ: 'D. MMMM YYYY',
		WOLTAIR_PL: 'D.MM.YYYY',
		WOLTAIR_DE: 'D. MMMM YYYY',
		WOLTAIR_IT: 'D MMMM YYYY'
	}[PUBLIC_WOLTAIR_WEB_CODE]

	$: imageAttributes = article.attributes?.mainImage?.data
		? article.attributes?.mainImage?.data[0]?.attributes
		: null

	$: categorySlug = article.attributes?.originalCategory
		? article.attributes?.originalCategory
		: article.attributes?.categories?.data[0]?.attributes?.slug

	$: articleUrl = article.attributes?.legacy
		? `/${BLOG}/${categorySlug}/${article.attributes.slug}`
		: `/${BLOG}/${article.attributes?.slug}`

	$: imgUrl = article?.attributes?.mainImage?.data?.[0]?.attributes?.url?.length
		? new URL(article.attributes.mainImage.data[0].attributes?.url)
		: new URL(
				'https://imagedelivery.net/rggusss5mXAsA02SHxOMGg/7aec1561-35b2-4a4c-5cb1-c5852535d500/public'
		  )

	const imgWidth = 400

	$: imgHeight =
		imageAttributes?.height && imageAttributes?.width
			? Math.floor((imageAttributes.height / imageAttributes.width) * imgWidth)
			: 300

	$: imgUrlResized = `${imgUrl.origin}${imgUrl.pathname
		.split('/')
		.map((part, i, arr) => {
			if (part === 'public' && i === arr.length - 1) return `width=${imgWidth}`
			return part
		})
		.join('/')}`
</script>

<a
	data-sveltekit-preload-data
	href={articleUrl}
	class="article flex flex-row-reverse items-start justify-end gap-6 rounded-xl md:flex-row md:gap-16"
	class:hide-mobile={hide}
>
	<div class="flex w-full flex-col-reverse md:min-h-36 md:flex-col">
		<h4 class="unstyled h4 mb-2 mt-1 justify-items-start text-xl-res">
			{@html article.attributes?.title}
		</h4>
		<p class="!my-0 hidden md:block">
			{#if article.attributes?.perex}
				{@html cropWordLength(article.attributes.perex, 140)}
			{:else if article.attributes?.content?.[0]?.text}
				{@html cropWordLength(article.attributes.content[0].text, 140)}
			{/if}
		</p>
		<p class="unstyled text-sm md:mt-4">
			{dayjs(article.attributes?.publishDate).format(dateFormat)}
		</p>
	</div>
	<div
		class="relative flex aspect-[4/3] w-1/4 flex-shrink-0 overflow-hidden rounded-lg sm:rounded-xl md:h-36 md:max-h-36 md:min-h-36 md:w-52 md:min-w-52 md:max-w-52"
	>
		<img
			class="w-full max-w-none object-cover"
			src={imgUrlResized}
			alt="{article.attributes?.title}, Woltair"
			width={imgWidth}
			height={imgHeight}
			loading="lazy"
		/>
	</div>
</a>
