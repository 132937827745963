<script lang="ts">
	export let rows: { name: string; value: string }[] = []
	export let title: string
	export let width: number
</script>

<div class="col-span-12 w-full md:col-span-{width} @container">
	{#if title}
		<h3>{title}</h3>
	{/if}
	<div>
		{#each rows as { name, value }}
			<div
				class="row flex items-center justify-between gap-4 py-6 text-base text-w-blue-950 @xl:text-xl"
			>
				<span class="">
					{name}
				</span>
				<span class="whitespace-nowrap font-semibold">
					{value}
				</span>
			</div>
		{/each}
	</div>
</div>

<style>
	.row:not(:last-child) {
		@apply border-b border-black/10;
	}
</style>
