<script lang="ts">
	export let title: string
	export let topimage: { data: { attributes: { url: string } } }
	export let content: string
	export let linkText: string
	export let linkUrl: string
	export let theme_type
	export let width = 4
	export let gapSize = 4
	export let imageSize = 'small'
	export let headingSizeFreeBox: string
	export let boxAlign = 'left'

	const justifyContent = {
		center: 'justify-center',
		left: 'justify-start',
		right: 'justify-end'
	}[boxAlign]

	const alignItems = {
		center: 'items-center',
		left: 'items-start',
		right: 'items-end'
	}[boxAlign]

	const textAlign = {
		center: 'text-center-custom',
		left: 'text-left-custom',
		right: 'text-right-custom'
	}[boxAlign]

	const themeclasses = {
		box: 'py-4 gap-10',
		image: 'w-4/12',
		heading: 'unstyled mt-0 mb-2 font-bold',
		link: ''
	}

	switch (headingSizeFreeBox) {
		case 'h1':
			themeclasses.heading = themeclasses.heading.concat(' ', 'h1')
			break

		case 'h2':
			themeclasses.heading = themeclasses.heading.concat(' ', 'h2')
			break

		case 'h3':
			themeclasses.heading = themeclasses.heading.concat(' ', 'h3')
			break

		case 'h4':
			themeclasses.heading = themeclasses.heading.concat(' ', 'h4')
			break

		case 'h5':
			themeclasses.heading = themeclasses.heading.concat(' ', 'h5')
			break

		case 'h6':
			themeclasses.heading = themeclasses.heading.concat(' ', 'h6')
			break

		default:
			themeclasses.heading = themeclasses.heading.concat(' ', 'h3')
			break
	}

	switch (theme_type) {
		case 'gray':
			themeclasses.box = `bg-neutral-100 p-8 rounded-xl ${justifyContent} ${alignItems}`
			break

		case 'bordered':
			themeclasses.box = `border border-neutral-200 p-8 rounded-xl ${justifyContent} ${alignItems}`
			break
		case 'borderless':
			themeclasses.box = `pr-10 ${justifyContent} ${alignItems}`
			break

		case 'centered':
			themeclasses.box =
				'bg-neutral-100 px-16 py-8 rounded-xl text-center items-center justify-center'
			themeclasses.heading = themeclasses.heading + 'my-0'
			themeclasses.link = 'mx-auto'
			break

		default:
			break
	}
	switch (imageSize) {
		case 'tiny':
			themeclasses.image = 'w-12 aspect-square'
			break
		case 'small':
			themeclasses.image = 'w-20 aspect-square'
			break
		case 'half':
			themeclasses.image = 'w-1/2'
			break
		case 'full':
			themeclasses.image = 'w-full'
			break

		default:
			themeclasses.image = 'h-18 a-auto'
			break
	}
</script>

<div
	class="col-span-12 rounded-2xl {themeclasses.box} gap-{gapSize} md:col-span-{width} free-box relative flex flex-col"
>
	{#if topimage?.data?.attributes}
		<img
			src={topimage.data.attributes.url}
			alt={title}
			class="block {themeclasses.image} my-2 max-w-full object-contain"
			loading="lazy"
		/>
	{/if}
	<div
		class="h-full-flex relative flex w-full flex-col {alignItems} gap-0"
		class:mt-auto={topimage?.data?.attributes}
	>
		{#if title}
			<h4 class="unstyled font-bold {themeclasses.heading} !mt-0 mb-4 {textAlign}">
				{title}
			</h4>
		{/if}
		{#if content}
			<div class="text max-h-fit {textAlign} text-sm text-neutral-500">{@html content}</div>
		{/if}
		{#if linkUrl && linkText}
			<a
				href={linkUrl}
				class="{themeclasses.link} !mt-auto"
				target={linkUrl.startsWith('http') ? '_blank' : '_self'}
				rel="noopener noreferrer"
				><span
					class="arrow-after mt-4 text-base font-semibold text-w-blue-950 hover:text-w-red-500"
				>
					{linkText}
				</span></a
			>
		{/if}
	</div>
</div>

<style lang="scss">
	.text-right-custom {
		@apply text-right;
		:global(p) {
			@apply text-right;
		}
	}

	.text-center-custom {
		@apply text-center;
		:global(p) {
			@apply text-center;
		}
	}

	.text-left-custom {
		@apply text-left;
		:global(p) {
			@apply text-left;
		}
	}

	.text :global(p) {
		@apply my-0;
	}
	.free-box :global(.h-full-flex) {
		@apply h-full;
	}
	.arrow-after {
		@apply flex items-center justify-start gap-4;
		&::after {
			@apply relative block aspect-square h-[.85em] bg-[url('/images/icons/arrow/arrow-red-next-alt.svg')] bg-cover content-[''];
		}
	}
</style>
