<script lang="ts">
	import ico_grant from '$lib/assets/icons/grant.svg'
	import ico_hvacpower from '$lib/assets/icons/power.svg'
	import ico_return from '$lib/assets/icons/return.svg'
	import type { ExtendedPumpResultData } from '$lib/components/wa-results/wa_types'
	import { formatAmount } from '$lib/form/formatters'
	import { t } from '$lib/translations'

	export let first = false
	export let deviceType: ExtendedPumpResultData
	export let currency = 'czk'
	const imageClass = first ? 'block' : 'hidden'
	const conditionClass = first ? 'p-4' : 'px-4 py-3'
	const boxClass =
		' w-full text-sm rounded-xl col-span-1 w-full text-center md:w-auto bg-neutral-100 from-[#F1F3FA] to-[#FDF5FA] bg-gradient-to-br'
</script>

{#if deviceType.roi || deviceType.subsidy || deviceType.annualMoneySavings}
	{#if deviceType.roi && deviceType.roi > 0}
		<div class="{boxClass}  {conditionClass}">
			<div class="flex h-full flex-wrap items-center justify-between gap-2">
				<div class="flex flex-col text-left">
					<span class="text-xs text-w-blue-950 opacity-70"
						>{t('web.marketplace.hvacResult.roiHeading')}</span
					>
					<span class="text-nowrap text-sm font-semibold">
						{deviceType.roi}
						{deviceType.roi === 1
							? t('web.marketplace.hvacResult.year')
							: deviceType.roi > 4
							? t('web.marketplace.hvacResult.years')
							: t('web.marketplace.hvacResult.yearsAlt')}
					</span>
				</div>
				<img src={ico_return} alt="" class="h-4 w-8 {imageClass}" />
			</div>
		</div>
	{/if}
	{#if deviceType.subsidy && deviceType.subsidy > 0}
		<div class="{boxClass}  {conditionClass}">
			<div class="flex h-full flex-wrap items-center justify-between gap-2">
				<div class="flex flex-col text-left text-sm">
					<span class="text-w-blue-950 opacity-70"
						>{t('web.marketplace.hvacResult.averageGrant')}</span
					>
					<span class="text-nowrap font-semibold">
						{formatAmount(deviceType.subsidy, { currency, round: 0 })}
					</span>
				</div>
				<img src={ico_grant} alt="" class="{imageClass} h-4 w-8" />
			</div>
		</div>
	{/if}
	{#if deviceType.annualMoneySavings && deviceType.annualMoneySavings > 0}
		<div class="{boxClass}  {conditionClass}">
			<div class="flex h-full flex-wrap items-center justify-between gap-2">
				<div class="flex flex-col text-left">
					<span class="text-xs text-w-blue-950 opacity-70"
						>{t('web.marketplace.hvacResult.costSavings')}</span
					>
					<span class="text-nowrap text-sm font-semibold">
						{formatAmount(deviceType.annualMoneySavings, { currency, round: 0 })}
					</span>
				</div>
				<img src={ico_hvacpower} alt="" class="{imageClass} h-4 w-8" />
			</div>
		</div>
	{/if}
{/if}
