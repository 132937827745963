<script lang="ts">
	export let width: number
	export let name: string
	export let role: string
	export let image: {
		data: {
			attributes: {
				url: string
			}
		}
	}
	export let author: {
		data: {
			attributes: {
				name: string
				description: string
				photo: {
					data: {
						attributes: {
							url: string
						}
					}
				}
			}
		}
	}
</script>

<div class="col-span-{width} flex w-full flex-col items-start gap-3">
	<div class="flex items-center gap-4">
		{#if image?.data?.attributes?.url}
			<img
				class="aspect-square h-10 rounded-full object-cover"
				src={image.data.attributes.url}
				alt={name || author?.data?.attributes?.name || 'author photo'}
				loading="lazy"
			/>
		{:else if author?.data?.attributes?.photo?.data?.attributes?.url}
			<img
				class="aspect-square h-10 rounded-full object-cover"
				src={author.data.attributes.photo.data.attributes.url}
				alt={name || author.data.attributes.name}
				loading="lazy"
			/>
		{/if}
		<div class="flex flex-col gap-y-1">
			{#if name || author?.data?.attributes?.name}
				<div class="text-sm font-semibold">{name || author.data.attributes.name}</div>
			{/if}
			{#if role || author?.data?.attributes?.description}
				<div class="text-sm opacity-30">{role || author.data.attributes.description}</div>
			{/if}
		</div>
	</div>
</div>
