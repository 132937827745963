<script lang="ts">
	export let text: string
</script>

<div class="article-text-block-section w-full">
	{@html text}
</div>

<style lang="scss">
	.article-text-block-section {
		position: relative;

		:global(ul),
		:global(ol) {
			margin: 1.5rem 0 1.5rem 0px;

			:global(li) {
				margin-bottom: 1rem;
			}
		}

		:global(ul) {
			list-style: disc;
			:global(ul) {
				list-style: circle;
				:global(ul) {
					list-style: square;
					:global(ul) {
						list-style: disc;
					}
				}
			}
		}
		:global(ol) {
			list-style: decimal;

			:global(ol) {
				list-style: lower-latin;

				:global(ol) {
					list-style: lower-roman;

					:global(ol) {
						list-style: upper-latin;
						:global(ol) {
							list-style: upper-roman;
							:global(ol) {
								list-style: decimal;
							}
						}
					}
				}
			}
		}

		:global(a) {
			color: #e20613;
		}

		:global(figure) {
			max-width: 96vw;
			@media only screen and (max-width: 760px) {
				@apply flex flex-col overflow-x-auto;
				margin: 0;
			}
			:global(img) {
				max-width: 100%;
			}
		}

		:global(figure.table) {
			margin: 15px 0;
			height: unset !important;
			width: unset !important;

			:global(table) {
				border-collapse: collapse;

				:global(tr) {
					border-bottom: 2px solid #ccc;

					&:last-child {
						border: none;
					}

					:global(td) {
						padding: 5px;
						padding-left: 5px !important;

						&:not(:last-of-type) {
							border-right: 2px solid #ccc;
						}

						&:first-child {
							font-weight: bold;
						}

						:global(p) {
							font-size: 12px !important;
							line-height: 16px !important;
							margin-bottom: 12px;
						}
					}
				}
			}
		}

		:global(.video-cont) {
			position: relative;
			height: 0;
			padding-bottom: 56.25%;
			margin-bottom: 30px;

			:global(iframe) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		:global(a:not(.ui-woltair):not(.btn)) {
			//color: #e20613;
			font-weight: bold;

			&:hover {
				color: #a50000;
			}
		}

		:global(figure.image-style-align-left) {
			padding: 0;
			margin: 0;
			padding-left: 0;
			width: 100%;
			:global(img) {
				position: relative;
				width: 100%;
				padding: 0;
				left: 0;
				display: block;
			}
		}
	}
</style>
