import { gql } from 'graphql-request'

export const fragments = {
	general: {
		ImageComponentFragment: gql`
			fragment ImageComponent on ComponentLandingPageImage {
				__typename
				size
				alt
				imageField {
					data {
						attributes {
							url
						}
					}
				}
			}
		`,
		ComponentPbBoxesFreeBoxes: gql`
			fragment ComponentPbBoxesFreeBoxes on ComponentPbBoxesFreeBoxes {
				__typename
				title
				topimage {
					data {
						id
						attributes {
							alternativeText
							url
						}
					}
				}
				id
				content
				linkText
				linkUrl
				theme_type
				width
				imageSize
				gapSize
				headingSizeFreeBox: headingSize
				boxAlign: align
			}
		`,
		ButtonComponentFragment: gql`
			fragment ButtonComponent on ComponentLandingPageButton {
				__typename
				url
				buttonText: text
				type
			}
		`,
		TextBlockComponentFragment: gql`
			fragment TextBlockComponent on ComponentLandingPageTextBlock {
				__typename
				id
				text
			}
		`,
		CtaBoxComponentFragment: gql`
			fragment CtaBoxComponent on ComponentLandingPageCtaBox {
				__typename
				id
				text
				button {
					__typename
					url
					text
					type
				}
			}
		`,
		ComponentJobOfferPersonnel: gql`
			fragment ComponentJobOfferPersonnel on ComponentJobOfferPersonnel {
				__typename
				title
				subtext
				id
				authors {
					data {
						attributes {
							name
							photo {
								data {
									attributes {
										url
									}
								}
							}
							description
						}
						id
					}
				}
			}
		`,
		ComponentPbCarouselsLogoCarousel: gql`
			fragment ComponentPbCarouselsLogoCarousel on ComponentPbCarouselsLogoCarousel {
				__typename
				logoentry {
					title
					url
					logoimage {
						data {
							attributes {
								url
							}
						}
					}
				}
			}
		`,
		ComponentPbLayoutSectionStart: gql`
			fragment ComponentPbLayoutSectionStart on ComponentPbLayoutSectionStart {
				__typename
				id
				sectionWidth
				align
				itemsAlign
				mobileOrder
				hideDesktop
				hideMobile
			}
		`,
		ComponentPbBoxesFullWidthScreen: gql`
			fragment ComponentPbBoxesFullWidthScreen on ComponentPbBoxesFullWidthScreen {
				__typename
				image {
					data {
						attributes {
							name
							url
							alternativeText
							formats
						}
					}
				}
				lefttext
				righttext
				toptext
				themefws
			}
		`,
		ComponentPbLayoutSectionEnd: gql`
			fragment ComponentPbLayoutSectionEnd on ComponentPbLayoutSectionEnd {
				__typename
				id
			}
		`,
		ComponentPbBasicsHeroText: gql`
			fragment ComponentPbBasicsHeroText on ComponentPbBasicsHeroText {
				__typename
				text
				width
				textAlign
			}
		`,
		ComponentPbBasicsHeroText02: gql`
			fragment ComponentPbBasicsHeroText02 on ComponentPbBasicsHeroText02 {
				__typename
				text
				width
				textAlignText02: textAlign
			}
		`,
		ComponentPbBasicsTextBlock: gql`
			fragment ComponentPbBasicsTextBlock on ComponentPbBasicsTextBlock {
				__typename
				text
				width
			}
		`,
		ComponentPbBasicsStrictTextBlock: gql`
			fragment ComponentPbBasicsStrictTextBlock on ComponentPbBasicsStrictTextBlock {
				__typename
				superHeading
				heading
				contentRich
				width
				alignMobile
				alignDesktop
				contentSize
				contentColor
				strictHeadingSize: headingSize
				strictSuperHeadingSize: superHeadingSize
				strictContentWeight: contentWeight
			}
		`,
		ComponentPbBoxesSocialMediaBox: gql`
			fragment ComponentPbBoxesSocialMediaBox on ComponentPbBoxesSocialMediaBox {
				__typename
				socialNetworkName
				icon {
					data {
						attributes {
							name
							url
							alternativeText
							formats
						}
					}
				}
				handle
				url
				width
			}
		`,
		ComponentPbBoxesClaimBox: gql`
			fragment ComponentPbBoxesClaimBox on ComponentPbBoxesClaimBox {
				__typename
				heading
				theme
				decoration
				width
				headingSize
				text
			}
		`,
		ComponentPbBasicsSeparator: gql`
			fragment ComponentPbBasicsSeparator on ComponentPbBasicsSeparator {
				__typename
				width
			}
		`,
		ComponentPbBoxesBox01: gql`
			fragment ComponentPbBoxesBox01 on ComponentPbBoxesBox01 {
				__typename
				width
				title
				text
				image {
					data {
						attributes {
							url
						}
					}
				}
				link {
					linkUrl
					linkText
				}
				theme01: theme
			}
		`,
		ComponentPbBoxesBox02: gql`
			fragment ComponentPbBoxesBox02 on ComponentPbBoxesBox02 {
				__typename
				width
				title
				text
				badge {
					text
				}
				image {
					data {
						attributes {
							url
						}
					}
				}
				link {
					linkUrl
					linkText
				}
				theme02: theme
			}
		`,
		ComponentPbBoxesBox03: gql`
			fragment ComponentPbBoxesBox03 on ComponentPbBoxesBox03 {
				__typename
				width
				title
				text
				badge {
					text
				}
				image {
					data {
						attributes {
							url
						}
					}
				}
				heroImage {
					data {
						attributes {
							url
						}
					}
				}
				link {
					linkUrl
					linkText
				}
				box03theme: theme
			}
		`,
		ComponentPbBoxesBox04: gql`
			fragment ComponentPbBoxesBox04 on ComponentPbBoxesBox04 {
				__typename
				width
				title
				text
				image {
					data {
						attributes {
							url
						}
					}
				}
				showSeparator
			}
		`,
		ComponentPbBoxesBox05: gql`
			fragment ComponentPbBoxesBox05 on ComponentPbBoxesBox05 {
				__typename
				width
				text
				title
				image {
					data {
						attributes {
							url
						}
					}
				}
				link {
					linkUrl
					linkText
					onClickGTM
				}
			}
		`,
		ComponentPbLayoutSpace: gql`
			fragment ComponentPbLayoutSpace on ComponentPbLayoutSpace {
				__typename
				height
				heightMobile
				setMobile
			}
		`,
		ComponentPbBasicsArticle01: gql`
			fragment ComponentPbBasicsArticle01 on ComponentPbBasicsArticle01 {
				__typename
				suptext
				title
				width
				image {
					data {
						attributes {
							url
						}
					}
				}
				article {
					data {
						attributes {
							title
							mainImage {
								data {
									attributes {
										url
									}
								}
							}
							content {
								__typename
								... on ComponentArticlesTextBlock {
									text
								}
							}
						}
					}
				}
			}
		`,
		ComponentPbBoxesBoxGroup01: gql`
			fragment ComponentPbBoxesBoxGroup01 on ComponentPbBoxesBoxGroup01 {
				__typename
				width
				boxes {
					type
					title
					text
					link {
						linkUrl
						linkText
						onClickGTM
					}
					colour
					image {
						data {
							attributes {
								url
							}
						}
					}
					disabled
				}
			}
		`,
		ComponentArticlesTextBlock: gql`
			fragment ComponentArticlesTextBlock on ComponentArticlesTextBlock {
				__typename
				text
			}
		`,
		ComponentPbCarouselsReviews01: gql`
			fragment ComponentPbCarouselsReviews01 on ComponentPbCarouselsReviews01 {
				__typename
				title
				width
				googlerating {
					rating
					totalReviews
				}
				brands {
					data {
						attributes {
							cstReviews {
								data {
									id
									attributes {
										image {
											data {
												attributes {
													url
												}
											}
										}
										location
										rating
										date
										text
										reviewAttributes {
											name
											value
										}
										brand {
											data {
												attributes {
													title
												}
											}
										}
									}
								}
							}
						}
					}
				}

				deviceTypes {
					data {
						attributes {
							cstReviews {
								data {
									id
									attributes {
										image {
											data {
												attributes {
													url
												}
											}
										}
										location
										rating
										date
										text
										reviewAttributes {
											name
											value
										}
										brand {
											data {
												attributes {
													title
												}
											}
										}
									}
								}
							}
						}
					}
				}

				cstReviews {
					data {
						id
						attributes {
							image {
								data {
									attributes {
										url
									}
								}
							}
							location
							rating
							date
							text
							reviewAttributes {
								name
								value
							}
							brand {
								data {
									attributes {
										title
									}
								}
							}
						}
					}
				}
			}
		`,
		ComponentPbCarouselsSteps01: gql`
			fragment ComponentPbCarouselsSteps01 on ComponentPbCarouselsSteps01 {
				__typename
				suptext
				title
				width
				steps {
					numbering
					title
					text
					image {
						data {
							attributes {
								url
							}
						}
					}
					icon {
						data {
							attributes {
								url
							}
						}
					}
				}
				stepsTheme: theme
			}
		`,
		ComponentPbMiscInvestors: gql`
			fragment ComponentPbMiscInvestors on ComponentPbMiscInvestors {
				__typename
				title
				text
				width
				investors {
					name
					subtext
					logo {
						data {
							attributes {
								url
								width
								height
							}
						}
					}
				}
			}
		`,
		ComponentPbMiscModel3D: gql`
			fragment ComponentPbMiscModel3D on ComponentPbMiscModel3D {
				__typename
				model_glb {
					data {
						attributes {
							name
							url
						}
					}
				}
			}
		`,
		ComponentPbBoxesAboutUsBox01: gql`
			fragment ComponentPbBoxesAboutUsBox01 on ComponentPbBoxesAboutUsBox01 {
				__typename
				width
				suptext
				title
				text
				phone {
					phoneNumber
					phoneLink
					availability
				}
				email {
					emailAddress
					emailLink
					availability
				}
				personName
				personRole
				image {
					data {
						attributes {
							url
						}
					}
				}
				imageMobile {
					data {
						attributes {
							url
						}
					}
				}
			}
		`,
		ComponentPbBasicsBlogView: gql`
			fragment ComponentPbBasicsBlogView on ComponentPbBasicsBlogView {
				__typename
				articleLimit
				articles(pagination: { limit: 10 }, sort: "publishDate:desc") {
					data {
						attributes {
							title
							publishDate
							slug
							mainImage {
								data {
									attributes {
										url
									}
								}
							}
						}
					}
				}
				categories {
					data {
						attributes {
							articles(pagination: { limit: 10 }, sort: "publishDate:desc") {
								data {
									attributes {
										title
										publishDate
										slug
										mainImage {
											data {
												attributes {
													url
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`,
		ComponentPbBasicsDownloads: gql`
			fragment ComponentPbBasicsDownloads on ComponentPbBasicsDownloads {
				__typename
				title
				files {
					title
					publishDate
					file {
						__typename
						data {
							id
							attributes {
								formats
								url
								name
							}
						}
					}
				}
			}
		`,
		ComponentPbBasicsMediaLinksView: gql`
			fragment ComponentPbBasicsMediaLinksView on ComponentPbBasicsMediaLinksView {
				__typename
				title
				mediadata(pagination: { limit: -1 }, sort: "publishDate:desc") {
					title
					url
					publisherName
					perex
					publishDate
					download {
						data {
							attributes {
								mime
								url
								name
								formats
							}
						}
					}
				}
			}
		`,
		ComponentPbBasicsBlogView01: gql`
			fragment ComponentPbBasicsBlogView01 on ComponentPbBasicsBlogView01 {
				__typename
				title
				hideLinkList
				articleLimit
				articles(pagination: { limit: 10 }, sort: "publishDate:desc") {
					data {
						attributes {
							title
							publishDate
							slug
							content {
								... on ComponentArticlesTextBlock {
									text
								}
							}
							metaDescription
							author {
								data {
									attributes {
										name
									}
								}
							}
							mainImage {
								data {
									attributes {
										url
									}
								}
							}
						}
					}
				}
				categories {
					data {
						attributes {
							slug
							articles(pagination: { limit: 10 }, sort: "publishDate:desc") {
								data {
									attributes {
										title
										publishDate
										slug
										content {
											... on ComponentArticlesTextBlock {
												text
											}
										}
										metaDescription
										author {
											data {
												attributes {
													name
												}
											}
										}
										mainImage {
											data {
												attributes {
													url
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`,
		ComponentPbBasicsButton: gql`
			fragment ComponentPbBasicsButton on ComponentPbBasicsButton {
				__typename
				width
				boxText
				boxLink
				colour
				onClickGTM
				mobileFullWidth
			}
		`,
		ComponentPbBasicsImage: gql`
			fragment ComponentPbBasicsImage on ComponentPbBasicsImage {
				__typename
				width
				alternativeText
				image {
					data {
						attributes {
							url
							alternativeText
						}
					}
				}
				imageMobile {
					data {
						attributes {
							url
							alternativeText
						}
					}
				}
				borderRadius
			}
		`,
		ComponentPbAdvisorPackageBoxes: gql`
			fragment ComponentPbAdvisorPackageBoxes on ComponentPbAdvisorPackageBoxes {
				__typename
				title
				width
				device
				packageLimit
				packages {
					slugId
					packageId
				}
				waPreferences {
					houseHeatType
					region {
						data {
							attributes {
								name
							}
						}
					}
					floorSquare
					peopleCount
					airCondition
					waterTempFortyFive
					isoLevel
				}
			}
		`,
		ComponentPbBoxesLogoBoxGroup: gql`
			fragment ComponentPbBoxesLogoBoxGroup on ComponentPbBoxesLogoBoxGroup {
				__typename
				width
				logos {
					name
					link
					image {
						data {
							attributes {
								url
							}
						}
					}
				}
			}
		`,
		ComponentPbMetricsScrollToContent: gql`
			fragment ComponentPbMetricsScrollToContent on ComponentPbMetricsScrollToContent {
				__typename
				onScrollGTM
			}
		`,
		ComponentPbFormsGrants01: gql`
			fragment ComponentPbFormsGrants01 on ComponentPbFormsGrants01 {
				__typename
				width
				type
				layout
				consents {
					title
					boxes {
						name
						label
						defaultValue
						required
					}
				}
			}
		`,
		ComponentPbBasicsFaq01: gql`
			fragment ComponentPbBasicsFaq01 on ComponentPbBasicsFaq01 {
				__typename
				width
				suptext
				title
				order
				questionsLimit
				questions {
					question
					answer
				}
				faq {
					data {
						attributes {
							questions {
								question
								answer
							}
						}
					}
				}
			}
		`,
		ComponentPbDetailsBlockBackground: gql`
			fragment ComponentPbDetailsBlockBackground on ComponentPbDetailsBlockBackground {
				__typename
				width
				bgType
				position
			}
		`,
		ComponentPbDetailsHeaderImage: gql`
			fragment ComponentPbDetailsHeaderImage on ComponentPbDetailsHeaderImage {
				__typename
				width
				image {
					data {
						attributes {
							url
						}
					}
				}
			}
		`,
		ComponentPbTablesSimpleTable01: gql`
			fragment ComponentPbTablesSimpleTable01 on ComponentPbTablesSimpleTable01 {
				__typename
				width
				table
			}
		`,
		ComponentPbBoxesBoxPrimary: gql`
			fragment ComponentPbBoxesBoxPrimary on ComponentPbBoxesBoxPrimary {
				__typename
				id
				title
				width
				subtext
				button {
					id
					text
				}
			}
		`,
		ComponentPbContactsContactBox01: gql`
			fragment ComponentPbContactsContactBox01 on ComponentPbContactsContactBox01 {
				__typename
				width
				title
				buttonText
				buttonLink
				phoneString: phone
				phoneLink
				emailString: email
				emailLink
			}
		`,
		ComponentPbBasicsAuthor: gql`
			fragment ComponentPbBasicsAuthor on ComponentPbBasicsAuthor {
				__typename
				width
				publishDate
				author {
					data {
						attributes {
							name
							photo {
								data {
									attributes {
										url
									}
								}
							}
						}
					}
				}
			}
		`,
		ComponentPbBannersImageOnly: gql`
			fragment ComponentPbBannersImageOnly on ComponentPbBannersImageOnly {
				__typename
				id
				url
				width
				alternativeText
				borderRadiusBanner: borderRadius
				bannerImage: image {
					data {
						attributes {
							url
						}
					}
				}
				bannerImageMobile: imageMobile {
					data {
						attributes {
							url
						}
					}
				}
			}
		`,
		ComponentPbBoxesDataBoxes: gql`
			fragment ComponentPbBoxesDataBoxes on ComponentPbBoxesDataBoxes {
				__typename
				boxes {
					name
					value
					unit
					pretext
					icon {
						data {
							attributes {
								url
							}
						}
					}
				}
			}
		`,
		ComponentPbMiscNavTabs: gql`
			fragment ComponentPbMiscNavTabs on ComponentPbMiscNavTabs {
				__typename
				tabs {
					text
					link
					icon {
						data {
							attributes {
								url
							}
						}
					}
				}
			}
		`,
		ComponentPbBoxesSimpleLinkBoxes: gql`
			fragment ComponentPbBoxesSimpleLinkBoxes on ComponentPbBoxesSimpleLinkBoxes {
				__typename
				width
				simpleBoxes: boxes {
					title
					subtext
					link
					image {
						data {
							attributes {
								url
							}
						}
					}
				}
			}
		`,
		ComponentPbLayoutEmptyColumn: gql`
			fragment ComponentPbLayoutEmptyColumn on ComponentPbLayoutEmptyColumn {
				__typename
				width
			}
		`,
		ComponentPbBasicsList: gql`
			fragment ComponentPbBasicsList on ComponentPbBasicsList {
				__typename
				listType: type
				title
				items {
					content
				}
				width
			}
		`,
		ComponentPbTablesSimpleTable02: gql`
			fragment ComponentPbTablesSimpleTable02 on ComponentPbTablesSimpleTable02 {
				__typename
				title
				rows {
					name
					value
				}
				width
			}
		`,
		ComponentPbBoxesCompareBoxes: gql`
			fragment ComponentPbBoxesCompareBoxes on ComponentPbBoxesCompareBoxes {
				__typename
				boxA {
					title
					amount
					aftertext
					subtext
				}
				boxB {
					title
					amount
					aftertext
					subtext
				}
			}
		`,
		ComponentPbBasicsLinkGroup: gql`
			fragment ComponentPbBasicsLinkGroup on ComponentPbBasicsLinkGroup {
				__typename
				links(pagination: { limit: -1 }) {
					text
					url
				}
				width
			}
		`,
		ComponentPbBoxesRegionsBox: gql`
			fragment ComponentPbBoxesRegionsBox on ComponentPbBoxesRegionsBox {
				__typename
				title
				image {
					data {
						attributes {
							url
						}
					}
				}
				regions(pagination: { limit: -1 }, sort: "name:asc") {
					data {
						attributes {
							name
							slug
							hvacAvailable
							pvAvailable
						}
					}
				}
				deviceType: device_type {
					data {
						attributes {
							slug
							name
						}
					}
				}
				width
			}
		`,
		ComponentPbBoxesBlogCtaBox: gql`
			fragment ComponentPbBoxesBlogCtaBox on ComponentPbBoxesBlogCtaBox {
				__typename
				text
				buttons {
					text
					link
					type
					onClickGTM
				}
				width
			}
		`,
		ComponentPbBoxesFullBgBox: gql`
			fragment ComponentPbBoxesFullBgBox on ComponentPbBoxesFullBgBox {
				__typename
				title
				subtext
				textStyle
				style
				bgColorClass
				width
				image {
					data {
						attributes {
							url
						}
					}
				}
				imageMobile {
					data {
						attributes {
							url
						}
					}
				}
				paddingBottom
				paddingBottomMobile
			}
		`,

		ComponentPbBoxesSimpleRatingBox: gql`
			fragment ComponentPbBoxesSimpleRatingBox on ComponentPbBoxesSimpleRatingBox {
				__typename
				title
				text
				personName
				personRole
				image {
					data {
						attributes {
							url
						}
					}
				}
				ratingText
				stars
				width
			}
		`,

		ComponentPbBoxesFeaturesImgBox: gql`
			fragment ComponentPbBoxesFeaturesImgBox on ComponentPbBoxesFeaturesImgBox {
				__typename
				title
				content

				imageNR: image {
					data {
						attributes {
							url
						}
					}
				}
				bgColorClass
				width
			}
		`,

		ComponentPbBoxesAdvantageImgBox: gql`
			fragment ComponentPbBoxesAdvantageImgBox on ComponentPbBoxesAdvantageImgBox {
				__typename
				title
				list {
					text
					icon {
						data {
							attributes {
								url
							}
						}
					}
				}
				buttons {
					text
					url
				}
				image {
					data {
						attributes {
							url
						}
					}
				}
				imageMobile {
					data {
						attributes {
							url
						}
					}
				}
				bgColorClass
				width
			}
		`,

		ComponentPbBoxesBigTextRatingBox: gql`
			fragment ComponentPbBoxesBigTextRatingBox on ComponentPbBoxesBigTextRatingBox {
				__typename
				value
			}
		`,
		ComponentPbBasicsAuthor01: gql`
			fragment ComponentPbBasicsAuthor01 on ComponentPbBasicsAuthor01 {
				__typename
				width
				name
				role
				image {
					data {
						attributes {
							url
						}
					}
				}
				author {
					data {
						attributes {
							name
							description
							photo {
								data {
									attributes {
										url
									}
								}
							}
						}
					}
				}
			}
		`,
		ComponentPbBasicsVideo01: gql`
			fragment ComponentPbBasicsVideo01 on ComponentPbBasicsVideo01 {
				__typename
				width
				description
				aspectRatio
				aspectRatioMobile
				video {
					data {
						attributes {
							url
							ext
						}
					}
				}
				thumbnail {
					data {
						attributes {
							url
						}
					}
				}
			}
		`
	}
}
