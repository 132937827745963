<script lang="ts">
	import { t } from '$lib/translations'
	import { page } from '$app/stores'
	import Button from '$lib/components/Button.svelte'
	import { fade } from 'svelte/transition'
	import { onMount } from 'svelte'
	import { isMobile } from '$lib/utils/isMobile'
	import { generateQRCode } from '$lib/utils/generateQRCode'
	import { sendGTM } from '$lib/analytics'

	export let model_glb
	let loadingAR = true
	let modelLoaded = false
	let modelARview = false
	let progress = 20

	async function loadModel() {
		if (!modelLoaded && typeof window !== 'undefined') {
			await import('@google/model-viewer')

			modelLoaded = true
		}
	}
	let mobile = true
	let qrCodeUrl: string | null = null
	const link = model_glb?.data?.[0]?.attributes?.url
	onMount(async () => {
		isMobile.subscribe((value) => {
			mobile = value
		})
		qrCodeUrl = await generateQRCode(createARLink(link))
	})
	$: if (!mobile) {
		loadModel()
	}
	function handleProgress(event: CustomEvent<{ totalProgress: number }>) {
		const { totalProgress } = event.detail
		progress = Math.min(100, Math.round(totalProgress * 100))
	}
	function handleLoad(event: Event) {
		loadingAR = false
	}
	function interactedWith3D(event: Event) {
		sendGTM('model3Dinteraction')
	}
	function createARLink(fileUrl: string): string {
		// Android intent link
		const androidLink = `intent://arvr.google.com/scene-viewer/1.2?mode=ar_preferred&file=${encodeURIComponent(
			fileUrl
		)}#Intent;scheme=https;package=com.google.ar.core;end`

		// iOS/Web link
		const iosLink = `https://arvr.google.com/scene-viewer/1.2?mode=ar_preferred&file=${encodeURIComponent(
			fileUrl
		)}&disable_occlusion=true`

		return navigator.userAgent.includes('Android') ? androidLink : iosLink
	}
</script>

{#if link}
	<div class="grid w-full grid-cols-12 gap-2">
		<div class="relative col-span-12 h-full min-h-96 w-full rounded-xl bg-slate-50 lg:col-span-8">
			{#if modelLoaded || !mobile}
				{#if loadingAR}
					<div
						transition:fade={{ duration: 400 }}
						class="progress-bar-container center absolute top-1/2 z-20 w-full px-4 text-center"
					>
						<div class="w-full rounded-full bg-slate-800">
							<div
								class="progress-bar block h-4 rounded-full bg-primary-500"
								style="width: {progress}%;"
							/>
							<div class="absolute top-0 w-full text-xs text-white">
								{t('woltadvisor.model3D.load.progress')}
							</div>
						</div>
					</div>
				{/if}
				<model-viewer
					src={link}
					alt="3D model"
					ar={true}
					auto-rotate
					camera-controls
					class="h-full w-full"
					on:focus={interactedWith3D}
					on:progress={handleProgress}
					on:load={handleLoad}
				/>
			{:else}
				<div class="h-full w-full" transition:fade={{ duration: 400 }}>
					<div class="h-full w-full blur-xl">
						{#if $page?.data?.pageData?.package.thumbnail}
							<img
								class="absolute left-1/2 top-1/2 h-auto max-h-full w-auto -translate-x-1/2 -translate-y-1/2 object-contain"
								src={$page?.data?.pageData?.package.thumbnail}
								alt={$page?.data?.pageData?.package?.title}
							/>
						{/if}
					</div>
					<Button
						textSize="text-base"
						cls="text-center whitespace-nowrap block font-bold min-w-fit absolute  -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"
						padding="py-4 px-6 mb-4 md:mb-0"
						theme="primary"
						onClickButton={loadModel}
						testid="button-3D-model"
					>
						{t('woltadvisor.model3D.load.button')}</Button
					>
				</div>
			{/if}
		</div>
		<div
			class="col-span-4 hidden overflow-hidden rounded-xl border bg-secondary-400 p-4 text-white lg:block"
		>
			{#if qrCodeUrl}
				<p class="text-center font-bold">{@html t('woltadvisor.model3D.qr.title')}</p>
				<ol class="unstyled ml-0 list-decimal pl-0 text-xs">
					<li class="mb-1 text-white">
						<p class="unstyled">{t('woltadvisor.model3D.qr.step01')}</p>
					</li>
					<li class="mb-1 text-white">
						<p class="unstyled">
							{t('woltadvisor.model3D.qr.step02')}
						</p>
					</li>
					<li class="mb-1 text-white">
						<p class="unstyled">
							{t('woltadvisor.model3D.qr.step03')}
						</p>
					</li>
				</ol>
				<img src={qrCodeUrl} alt="QR Code" class="mx-auto block" />
			{:else}
				<p>{t('woltadvisor.model3D.qr.generating')}</p>
			{/if}
		</div>
	</div>
{/if}
