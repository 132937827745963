<script lang="ts">
	import { ConditionalAnchor } from '$lib/components'

	export let width: number
	export let logos: {
		name: string
		link: string
		subtext: string
		image: {
			data: {
				attributes: {
					url: string
				}
			}
		}
	}[]
</script>

<div class="col-span-{width} w-full">
	<div class="flex flex-wrap items-center justify-center gap-x-12 gap-y-8">
		{#each logos as logo}
			{#if logo.image?.data?.attributes?.url && logo.name}
				<div class="relative flex flex-col items-center">
					<ConditionalAnchor href={logo.link} condition={!!logo.link?.length} target="_blank">
						<img
							src={logo.image.data.attributes.url}
							alt={logo.name}
							class="inset-x-0 mx-auto h-12 w-fit object-contain"
							loading="lazy"
						/>
					</ConditionalAnchor>
				</div>
			{/if}
		{/each}
	</div>
</div>
