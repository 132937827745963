<script lang="ts">
	export let icon: string | false = false
	export let hintPosition: string | false = false
	export let cls: string | false = false
</script>

<span class="hint-wrapper relative {cls ? cls : `flex items-center gap-2 align-baseline`}">
	{#if icon}
		<img src={icon} class="w-wuto h-auto" alt="Icon" />
	{/if}
	<div class="block">
		<slot />
	</div>
	{#if $$slots.tooltip}
		<span
			class="hint pointer-events-none absolute bottom-full {hintPosition
				? hintPosition
				: 'left-0'} min-w-32 -translate-y-1 rounded-md bg-secondary-500 px-2 py-1 text-white opacity-0 transition-opacity duration-100"
			><slot name="tooltip" /></span
		>
	{/if}
</span>

<style>
	.hint-wrapper:hover .hint {
		opacity: 1;
	}
</style>
