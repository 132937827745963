<script lang="ts">
	export let title: string
	export let subtext: string
	export let width: number
</script>

<div class="col-span-12 rounded-lg bg-neutral-200 p-8 shadow-lg md:col-span-{width}">
	<h2>{title}</h2>
	<p>{subtext}</p>
</div>
