<script lang="ts">
	import { resizeCDNImage } from '$lib/utils'
	type StrapiImage = {
		data: {
			attributes: {
				url: string
			}
		}
	}

	export let url: string
	export let alternativeText: string
	export let width: number
	export let bannerImage: StrapiImage
	export let bannerImageMobile: StrapiImage
	export let borderRadiusBanner: 'NONE' | 'XL' | 'XL_2' | 'XL_3'

	const borderRadiusClass =
		{
			NONE: '',
			XL: 'rounded-xl',
			XL_2: 'rounded-2xl',
			XL_3: 'rounded-3xl'
		}[borderRadiusBanner] || ''
</script>

<div class="col-span-12 md:col-span-{width} w-full">
	{#if url?.length}
		<a href={url}>
			{#if bannerImageMobile?.data?.attributes?.url}
				<img
					src={bannerImageMobile.data.attributes.url}
					alt={alternativeText}
					class="md:hidden {borderRadiusClass} w-full object-contain"
				/>
				<img
					src={resizeCDNImage(bannerImage.data.attributes.url, 2660)}
					alt={alternativeText}
					class="hidden md:block {borderRadiusClass} w-full object-contain transition-opacity hover:opacity-90"
				/>
			{:else}
				<img
					src={resizeCDNImage(bannerImage.data.attributes.url, 2660)}
					alt={alternativeText}
					class="{borderRadiusClass} w-full object-contain transition-opacity hover:opacity-90"
				/>
			{/if}
		</a>
	{:else if bannerImageMobile?.data?.attributes?.url}
		<img
			src={bannerImageMobile.data.attributes.url}
			alt={alternativeText}
			class="md:hidden {borderRadiusClass} w-full object-contain"
		/>
		<img
			src={resizeCDNImage(bannerImage.data.attributes.url, 2660)}
			alt={alternativeText}
			class="hidden md:block {borderRadiusClass} w-full object-contain"
		/>
	{:else}
		<img
			src={resizeCDNImage(bannerImage.data.attributes.url, 2660)}
			alt={alternativeText}
			class="{borderRadiusClass} w-full object-contain"
		/>
	{/if}
</div>
