<script lang="ts">
	export let items: { content: string }[] = []
	export let type = 'CHECKMARK' as const
	export let title: string
	export let width: number
</script>

<div class="col-span-12 md:col-span-{width} w-full">
	{#if title}
		<h3 class="pb-6">{title}</h3>
	{/if}
	<svelte:element
		this={type === 'CHECKMARK' ? 'ul' : 'ol'}
		class:checkmark-list={type === 'CHECKMARK'}
		class="flex flex-col gap-y-6"
	>
		{#each items as item}
			<li class="!my-0">{@html item.content}</li>
		{/each}
	</svelte:element>
</div>

<style lang="scss">
	.checkmark-list {
		@apply list-none pl-0;

		li {
			@apply flex items-center gap-5 text-black/60;

			&:before {
				@apply block h-4 w-4 shrink-0 bg-[url('/images/icons/checkmark-circle-red.svg')] bg-contain bg-no-repeat content-[''];
			}
		}
	}

	.checkmark-list :global(p) {
		@apply my-0;
	}
</style>
