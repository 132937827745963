<script lang="ts">
	export let title: string
	export let text: string
	// export let width: number
	export let investors: {
		name: string
		subtext: string
		logo: {
			data: {
				attributes: {
					url: string
					width: number
					height: number
				}
			}
		}
	}[]
</script>

<div class="flex w-full flex-col items-center">
	{#if title}
		<h2 class="unstyled max-w-3xl text-center text-2xl font-semibold">{title}</h2>
	{/if}
	{#if text}
		<p class="mt-5 max-w-2xl text-center text-neutral-500">{text}</p>
	{/if}

	{#if investors?.length}
		<div class="mt-16 flex w-full flex-wrap justify-center gap-10-res">
			{#each investors as investor}
				<div class="h-full">
					{#if investor?.logo?.data?.attributes?.url}
						<div class="flex h-24 justify-center">
							<img
								src={investor.logo.data.attributes.url}
								alt=""
								class="object-contain"
								loading="lazy"
							/>
						</div>
					{/if}
					{#if investor.subtext}
						<p class="mt-8-res text-center text-sm text-black opacity-30">{investor.subtext}</p>
					{/if}
				</div>
			{/each}
		</div>
	{/if}
</div>
