<script lang="ts">
	export let socialNetworkName
	export let icon
	export let handle
	export let url
	export let width
	// export let component
</script>

<div
	class="relative col-span-12 flex gap-4 md:min-h-48 md:flex-col md:justify-between md:col-span-{width} rounded-3xl bg-neutral-100 p-6"
>
	{#if icon?.data?.attributes?.url}
		<div class="flex h-12 w-12">
			<img src={icon?.data?.attributes?.url} alt="" class="object-contain" loading="lazy" />
		</div>
	{/if}
	<div class="relative">
		<div class="text-xs text-neutral-500">
			{socialNetworkName}
		</div>
		<div class="flex w-full items-center justify-between text-lg font-bold">
			<div class="block">
				<a href={url}>
					{handle}
				</a>
			</div>
			<img src="/images/icons/2023_ci/dark/arrow-right-dark.svg" alt="" loading="lazy" />
		</div>
	</div>
</div>
