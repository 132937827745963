<script lang="ts">
	export let data
</script>

<div class="page-wrapper">
	<div class="mx-auto mb-16 text-wrap text-center">
		<h2 class="unstyled h2">{data.title}</h2>
		{#if data.subtext}
			<p class="subtext">
				{data.subtext}
			</p>
		{/if}
	</div>
	{#if data?.authors?.data}
		<div class="images-wrap mx-auto flex flex-wrap gap-8 md:justify-center">
			{#each data.authors.data as inmate}
				<div class="mb-8 w-full max-w-[180px]">
					{#if inmate?.attributes?.photo?.data?.attributes?.url}
						<img
							src={inmate.attributes.photo.data.attributes.url}
							class="mb-6 overflow-hidden rounded-xl"
							alt={inmate.attributes.name}
							loading="lazy"
						/>
					{:else}
						<img
							src="/images/misc/placeholder.jpg"
							class="mb-6 overflow-hidden rounded-xl"
							alt={inmate.attributes.name}
							loading="lazy"
						/>
					{/if}
					<p class="unstyled name font-semibold">{inmate.attributes.name}</p>
					<p class="unstyled text-gray-light lg:text-sm">{inmate.attributes.description}</p>
				</div>
			{/each}
		</div>
	{/if}
</div>

<style>
	.text-wrap {
		max-width: 100%;
		width: 800px;
	}
	.images-wrap {
		max-width: 100%;
		width: 1020px;
	}
</style>
