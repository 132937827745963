<script lang="ts">
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { CMS_PREVIEW } from '$params/cms_preview'
	export let tabs: {
		text: string
		link: string
		icon?: { data: { attributes: { url: string } } }
	}[] = []

	const isActivePreview = (path: string | undefined) =>
		path
			? $page.url.pathname.includes('preview') && $page.url.searchParams.get('path') === path
			: false

	const noTabSelected = !tabs.some(
		(tab) => tab?.link === $page.url.pathname || isActivePreview(tab?.link)
	)

	const handleLink = (link: string) => {
		if ($page.url.pathname.includes(CMS_PREVIEW)) {
			const url = new URL($page.url)
			url.searchParams.set('path', link)
			return `${url.pathname}${url.search}`
		}
		return link
	}
</script>

{#if tabs.length > 0}
	<div class="hide-scrollbar mt-6 flex w-screen overflow-x-scroll border-b sm:w-full">
		<div class="mx-4 flex w-fit min-w-full justify-start gap-4 md:mx-0 md:justify-between md:gap-0">
			{#each tabs as tab, i}
				{#if tab.link && tab.text}
					<button
						role="tab"
						on:click={() => goto(handleLink(tab.link), { noScroll: true })}
						class="flex w-full min-w-fit flex-nowrap items-center justify-center gap-3 whitespace-nowrap border-b-[3px] border-transparent px-1 py-3 font-semibold text-w-blue-950 transition-all hover:border-w-red-500 md:gap-6 md:px-10 md:py-6"
						class:md:pr-12={tab.icon}
						class:border-w-red-500={tab.link === $page.url.pathname ||
							(noTabSelected && i === 0) ||
							isActivePreview(tab?.link)}
					>
						{#if tab.icon}
							<img
								src={tab.icon.data.attributes.url}
								alt={tab.text}
								class="h-6 w-6 object-contain object-center md:h-10 md:w-10"
								loading="lazy"
							/>
						{/if}
						<span>{tab.text}</span>
					</button>
				{/if}
			{/each}
		</div>
	</div>
{/if}
