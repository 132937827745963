<script lang="ts">
	import { onMount } from 'svelte'
	import { sendGTM } from '$lib/analytics'

	export let onScrollGTM: {
		[key: string]: string | { [key: string]: string }
	} | null

	let scrollArea: HTMLDivElement
	let viewed = false

	onMount(() => {
		const observer = new IntersectionObserver(([entry]) => {
			if (entry.intersectionRatio > 0 && !viewed) {
				viewed = true
				if (onScrollGTM) sendGTM('', {}, onScrollGTM)
			}
		})

		observer.observe(scrollArea)
	})
</script>

<div class="h-0 w-full" bind:this={scrollArea} />
