<script lang="ts">
	import { string, object, boolean } from 'yup'
	import { t } from '$lib/translations'
	import { validatePhone } from '$lib/utils'

	export let width: number
	export let leadSource = 'PV_EXT'
	export let consents: {
		title?: string
		boxes?: {
			name: string
			label: string
			defaultValue: boolean
			required: boolean
		}[]
	} = {}

	const schema = object({
		email: string().required(t('web.forms.email.required')).email(t('web.forms.email.validate')),
		phone: string()
			.required(t('web.forms.leaveContact.phone.validation'))
			.test(
				'phone-validation',
				t('web.forms.leaveContact.phone.format') || '',
				async function (value) {
					if (!value) return false
					const validationResult = await validatePhone(value)
					return validationResult.isValid
				}
			),
		contractNumber: string(),
		...(consents?.boxes?.length && {
			...consents.boxes.reduce((accumulator, value: any) => {
				if (value?.required === true) {
					return {
						...accumulator,
						[value.name]: boolean()
							.oneOf([true], t('web.forms.field.required'))
							.required(t('web.forms.field.required'))
					}
				} else {
					return {
						...accumulator,
						[value.name]: boolean().oneOf([true, false])
					}
				}
			}, {})
		})
	})

	let formData: {
		email: string
		phone: string
		contractNumber: string
		[key: string]: any
	} = {
		email: '',
		phone: '',
		contractNumber: '',
		...(consents?.boxes?.length && {
			...consents.boxes.reduce((accumulator, value: any) => {
				return { ...accumulator, [value.name]: value.defaultValue || false }
			}, {})
		})
	}

	let formErrors: Record<string, any> = {}

	let formStep: 'DEFAULT' | 'SENDING' | 'SENT' = 'DEFAULT'

	async function validateField(fieldName: string, value: string) {
		try {
			await schema.validateAt(fieldName, { [fieldName]: value })
			formErrors[fieldName] = ''
		} catch (error: any) {
			logger.debug('formCrossSell01.validateField.failed', error.message)
			formErrors[fieldName] = error.message
		}
	}

	async function handleSubmit() {
		try {
			formErrors = {}
			await Promise.all(
				Object.entries(formData).map(async ([key, value]) => {
					await validateField(key, value.toString() || '')
				})
			)

			if (Object.values(formErrors).some((error) => error)) throw new Error('Validation failed')

			formStep = 'SENDING'

			const response = await fetch('/api/lead/create', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({
					email: formData.email,
					phone: formData.phone,
					leadSource: leadSource,
					...(leadSource === 'PV_EXT' && {
						installationProductCategory: 'PV_EXTENSION'
					}),
					customFieldValues: [
						{
							code: 'USER_ENTERED_CONTRACT_ID',
							value: formData.contractNumber || null
						}
					]
				})
			})

			if (response.ok) {
				formStep = 'SENT'
			} else {
				formStep = 'DEFAULT'
			}
		} catch (error: any) {
			if (error.inner) {
				error.inner.forEach((err: any) => {
					formErrors[err.path] = err.message
				})
			}
		}
	}
</script>

<div
	class="col-span-12 md:col-span-{width} relative w-full rounded-3xl border-2 border-neutral-200 bg-white p-12-res py-14-res @container"
>
	<div
		class="pointer-events-none absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center"
	>
		<div class="relative aspect-square w-[64px]">
			<div
				class="absolute inset-x-0 mx-auto aspect-square w-full animate-spin rounded-full border-[6px] border-primary-500 border-t-white transition-opacity {formStep ===
				'SENDING'
					? 'opacity-100'
					: 'opacity-0'}"
			/>
			<div
				class="pointer-events-none absolute inset-x-0 mx-auto aspect-square w-full bg-[url(/static/images/icons/tick-circle.svg)] bg-contain {formStep ===
				'SENT'
					? 'opacity-100'
					: 'opacity-0'} transition-opacity"
			/>
		</div>
		<p
			class="mt-2 w-10/12 text-center text-xl font-bold transition-opacity {formStep === 'SENT'
				? 'opacity-100'
				: 'opacity-0'}"
		>
			{t('web.forms.leaveContact.thankYou')}
		</p>
	</div>
	<form
		on:submit|preventDefault={handleSubmit}
		class:opacity-0={formStep === 'SENDING' || formStep === 'SENT'}
		class:pointer-events-none={formStep === 'SENDING' || formStep === 'SENT'}
		class="transition-opacity"
	>
		<div class="grid grid-cols-1 gap-x-4 gap-y-2 @md:gap-y-4 @xl:grid-cols-3">
			<div class="flex w-full flex-col">
				<label for="email" class="mb-1 text-sm text-neutral-500">{t('web.forms.yourEmail')}</label>
				<input
					bind:value={formData.email}
					on:change={() => {
						validateField('email', formData.email || '')
					}}
					class="input"
					type="text"
					name="email"
					placeholder={t('web.forms.email.placeholder.example')}
					class:!border-primary-500={formErrors.email}
				/>
				{#if formErrors.email}
					<p class="unstyled mt-1 text-sm text-primary-500">{formErrors.email}</p>
				{/if}
			</div>
			<div class="flex w-full flex-col">
				<label for="phone" class="mb-1 text-sm text-neutral-500">{t('web.forms.phone')}</label>
				<input
					bind:value={formData.phone}
					on:change={() => {
						validateField('phone', formData.phone?.toString() || '')
					}}
					class="input"
					type="text"
					name="phone"
					placeholder={t('web.forms.phone.placeholder')}
					class:!border-primary-500={formErrors.phone}
				/>
				{#if formErrors.phone}
					<p class="unstyled mt-1 text-sm text-primary-500">{formErrors.phone}</p>
				{/if}
			</div>
			<div class="flex w-full flex-col">
				<label for="contractNumber" class="mb-1 text-sm text-neutral-500"
					>{t('web.forms.contractNumber')}</label
				>
				<input
					bind:value={formData.contractNumber}
					on:change={() => {
						validateField('contractNumber', formData.contractNumber || '')
					}}
					class="input"
					type="text"
					name="contractNumber"
					placeholder={t('web.forms.contractNumber.placeholder')}
					class:!border-primary-500={formErrors.contractNumber}
				/>
				{#if formErrors.contractNumber}
					<p class="unstyled mt-1 text-sm text-primary-500">{formErrors.contractNumber}</p>
				{/if}
			</div>
		</div>
		{#if consents?.boxes?.length}
			<div class="mt-4">
				{#if consents.title}
					<p class="mb-4 text-sm text-neutral-500">{@html consents.title}</p>
				{/if}
				{#each consents.boxes as box}
					<div class="mt-2 flex items-center">
						<input
							type="checkbox"
							name={box.name}
							id={box.name}
							bind:checked={formData[box.name]}
							class="checkbox mr-3"
							class:!border-primary-500={formErrors[box.name]}
						/>
						<label for={box.name} class="checkbox-label text-sm text-neutral-500"
							>{@html box.label}</label
						>
					</div>
					{#if formErrors[box.name]}
						<p class="unstyled mt-1 text-sm text-primary-500">{formErrors[box.name]}</p>
					{/if}
				{/each}
			</div>
		{/if}
		<button type="submit" class="btn-new btn-new-red mt-4 w-full @md:mt-8"
			>{t('web.forms.contactMe')}</button
		>
	</form>
</div>

<style lang="scss">
	.btn {
		@apply transition-colors;
	}

	.select {
		@apply cursor-pointer rounded-lg border-2 border-neutral-200 bg-white p-4 text-sm font-normal text-neutral-400 duration-500;
	}

	.input {
		@apply w-full appearance-none rounded-lg border-2 border-neutral-200 bg-white p-4 text-sm text-neutral-800 shadow-sm outline-none ring-0 transition-colors duration-500;

		&::placeholder {
			@apply text-neutral-400;
		}
	}

	.checkbox-label :global(a) {
		@apply text-primary-500;
	}
</style>
