<script lang="ts">
	export let width: number
	export let text: string
	export let title: string
	export let image: { data: { attributes: { url: string } } }
	export let showSeparator: boolean
</script>

<div
	class="col-span-12 rounded-2xl bg-white lg:col-span-{width} flex w-full flex-col self-stretch overflow-hidden md:flex-row"
>
	<div class="relative flex w-full flex-col px-10-res py-10 @container">
		{#if image?.data?.attributes?.url}
			<img
				src={image.data.attributes.url}
				alt={title}
				class="mb-4 aspect-square h-12 object-contain object-left"
				loading="lazy"
			/>
		{/if}
		{#if showSeparator}
			<div class="h-[6px] w-[80px] rounded-full bg-primary-500" />
		{/if}
		{#if title}
			<h4 class="unstyled mt-8 max-w-md text-xl font-bold @xl:max-w-none">{title}</h4>
		{/if}
		{#if text}
			<p class="unstyled mt-5 max-w-md text-base leading-relaxed @xl:max-w-none">{text}</p>
		{/if}
	</div>
</div>
