import QRCode from 'qrcode'

export const generateQRCode = async (text: string): Promise<string> => {
	try {
		return await QRCode.toDataURL(text, {
			color: {
				dark: '#FFFFFF', // Default dark color
				light: '#0e223c' // Default light color
			}
		})
	} catch (err) {
		console.error('Error generating QR code', err)
		throw err
	}
}
