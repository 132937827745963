<script lang="ts">
	export let text: string
	export let width: number
	export let textAlign: 'left' | 'center' | 'right' = 'left'
</script>

<div class="hero-text col-span-12 md:col-span-{width} w-full">
	<div class="hero-text-cont w-full max-w-full text-{textAlign}">{@html text}</div>
</div>

<style lang="scss">
	.hero-text {
		:global(.hero-text-cont) {
			:global(p) {
				@apply text-lg text-xl-res;

				@screen md {
					@apply text-lg;
				}
			}

			:global(a) {
				@apply text-primary-500;
			}
			:global(h1) {
				@apply mt-0 pb-2 text-7xl-res font-bold leading-[1.1em];

				@screen md {
					@apply text-5.5xl;
				}
			}

			:global(h2) {
				@apply mt-0 text-6xl-res font-bold leading-[1.25em];

				@screen md {
					@apply text-4xl;
				}

				@screen lg {
					@apply text-5xl;
				}
			}

			:global(h4) {
				@apply mt-0;
			}
			:global(h5) {
				@apply mb-2 mt-0 text-xl;
			}
			:global(h6) {
				@apply mb-2 mt-0 text-sm text-neutral-500;
			}
		}
	}
</style>
