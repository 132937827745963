<script lang="ts">
	import { HEAT_PUMP, PHOTOVOLTAICS } from '$lib/routes'

	export let title: string
	export let image: { data: { attributes: { url: string } } }
	export let regions: {
		data: {
			attributes: {
				name: string
				slug: string
				hvacAvailable: boolean
				pvAvailable: boolean
			}
		}[]
	}
	export let width: number
	export let deviceType: {
		data: {
			attributes: {
				slug: string
				name: string
			}
		}
	}
</script>

<section class="col-span-12 md:col-span-{width} relative w-full @container">
	<div
		class="relative grid w-full gap-8 {image.data?.attributes?.url
			? 'grid-cols-1 @4xl:grid-cols-2'
			: 'grid-cols-1'} place-items-center"
	>
		<div class="flex h-fit flex-wrap gap-x-2 gap-y-3">
			{#if title}
				<h3 class="!mt-0">{title}</h3>
			{/if}
			{#if regions.data.length > 0}
				{#each regions.data as region}
					{#if (deviceType.data?.attributes?.slug === HEAT_PUMP && region.attributes?.hvacAvailable) || (deviceType.data?.attributes?.slug === PHOTOVOLTAICS && region.attributes?.pvAvailable && region.attributes?.slug && region.attributes?.name)}
						<a
							href="/{deviceType.data.attributes.slug}/{region.attributes.slug}"
							class="h-fit w-fit rounded-full bg-neutral-100 px-4 py-2 text-sm transition-colors hover:bg-neutral-200"
							>{region.attributes.name}</a
						>
					{/if}
				{/each}
			{/if}
		</div>
		{#if image.data?.attributes?.url}
			<div class="relative max-h-full w-full max-w-72 md:min-h-96 md:w-full md:max-w-none">
				<img
					src={image.data.attributes.url}
					alt="Map"
					loading="lazy"
					class="inset-x-0 mx-auto h-full object-contain md:absolute"
				/>
			</div>
		{/if}
	</div>
</section>
