<script lang="ts">
	type StrapiImage = {
		data: {
			attributes: {
				url: string
			}
		}
	}

	export let width: number
	export let alternativeText: string
	export let image: StrapiImage
	export let imageMobile: StrapiImage
	export let borderRadius: 'NONE' | 'XL' | 'XL_2' | 'XL_3'

	const borderRadiusClass =
		{
			NONE: '',
			XL: 'rounded-xl',
			XL_2: 'rounded-2xl',
			XL_3: 'rounded-3xl'
		}[borderRadius] || ''
</script>

{#if image?.data?.attributes?.url}
	<div class="col-span-12 md:col-span-{width} flex items-center justify-center self-stretch">
		{#if imageMobile?.data?.attributes?.url}
			<img
				src={imageMobile.data.attributes.url}
				alt={alternativeText}
				class="object-fit object-center md:hidden {borderRadiusClass} w-full"
				loading="lazy"
			/>
			<img
				src={image.data.attributes.url}
				alt={alternativeText}
				class="object-fit hidden object-center md:block {borderRadiusClass} w-full"
				loading="lazy"
			/>
		{:else}
			<img
				src={image.data.attributes.url}
				alt={alternativeText}
				class="object-fit object-center {borderRadiusClass} w-full"
				loading="lazy"
			/>
		{/if}
	</div>
{/if}
