<script lang="ts">
	import { onMount } from 'svelte'
	import { afterNavigate } from '$app/navigation'
	import { page } from '$app/stores'
	import { MARKETPLACE, HEAT_PUMP } from '$lib/routes'
	import type { HvacFromQueryResponse, Maybe } from '$lib/types/woltadvisor'
	import type { ExtendedPumpResultData } from '$lib/components/wa-results/wa_types'
	import { setWASettings } from '$lib/utils'
	import HVACCard from '$lib/wa-modules/HVACCard.svelte'
	import { ORGANIZATION } from '$lib/consts'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	export let title = ''
	export let packageLimit = 4
	export let packages: {
		slugId: string
		packageId: string
	}[] = []
	export let waPreferences: {
		houseHeatType?: string
		region?: {
			data?: {
				attributes?: {
					name: string
				}
			}
		}
		floorSquare?: string
		peopleCount?: string
		airCondition?: boolean
		waterTempFortyFive?: string
		isoLevel?: string
	} = {}

	let { region, ...otherParams } = waPreferences || {}

	let strapiParams = {
		...removeEmpty(otherParams),
		...(region?.data?.attributes?.name && { regionName: region.data.attributes.name }),
		...(packages?.length && {
			definedPackages: packages.map((pkg) => {
				return { id: pkg.packageId }
			})
		})
	}

	function removeEmpty(obj: Record<string, any>) {
		return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
	}

	$: excludePackageId = $page.data?.slug?.split('-')[0]

	let packageBoxes: Maybe<ExtendedPumpResultData | undefined>[] | undefined = []
	export let blurPrice = false

	const getPackages = async () => {
		const response = await fetch('/api/request', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				formType: 'hvacOtherProducts',
				...($page.data?.data?.packageSettings && { ...$page.data.data.packageSettings }),
				limit: packageLimit || 4,
				...strapiParams,
				...(excludePackageId && { excludePackageId: excludePackageId })
			})
		})

		const data = (await response.json()) as { woltAdvisorHvacFromQuery: HvacFromQueryResponse }

		if (data?.woltAdvisorHvacFromQuery) {
			const { deviceTypes } = data.woltAdvisorHvacFromQuery
			const { packageSettings } = $page.data.data ?? {}
			const withWaterHeating = packageSettings?.waterHeatingWithHouseHeatSource ?? false

			packageBoxes = deviceTypes
				.map((deviceType) => {
					if (!withWaterHeating || withWaterHeating == 'false') return deviceType?.noWater
					if (withWaterHeating || withWaterHeating == 'true') return deviceType?.withWater
				})
				.filter((deviceType) => deviceType?.catalogPackage?.dynamicPriceWithVat)
				.slice(0, packageLimit)
		}
		if (packageBoxes && packageBoxes?.length < 1) {
			packageBoxes = undefined
		}
	}

	onMount(async () => {
		getPackages()
		const waABsettings = await setWASettings()
		blurPrice = waABsettings.blurPrice
	})
	afterNavigate(async () => {
		getPackages()
	})
</script>

{#if title}
	<h2
		class="unstyled mb-10 mt-0 text-lg font-semibold leading-normal text-w-blue-950 md:text-2xl lg:text-2.5xl"
	>
		{title}
	</h2>
{/if}

<div
	class="hide-scrollbar flex w-screen grid-cols-1 gap-3 overflow-x-scroll md:grid md:w-full md:max-w-full md:grid-cols-2 md:gap-6 lg:grid-cols-3 xl:grid-cols-4"
>
	{#if packageBoxes && packageBoxes.length > 0}
		{#each packageBoxes as deviceType}
			<div class="min min-w-64">
				<HVACCard
					{deviceType}
					currency={ORGANIZATION[PUBLIC_WOLTAIR_WEB_CODE].currencyCode}
					detailRootUrl="/{MARKETPLACE}/{HEAT_PUMP}/"
					searchString={$page.url.search}
					{blurPrice}
				/>
			</div>
		{/each}
	{:else if packageBoxes}
		{#each { length: packageLimit } as _}
			<div
				class="flex w-full min-w-[90%] max-w-96 self-stretch rounded-xl border-2 border-neutral-100 bg-white @container xs:min-w-96 md:col-span-1 md:min-h-[700px] md:min-w-0 md:max-w-none xl:col-span-1"
			>
				<div class="flex w-full animate-pulse flex-col px-4 py-6">
					<div class="mt-4 flex w-full justify-center">
						<div class="my-0 aspect-square w-7/12 animate-pulse rounded-3xl bg-neutral-300" />
					</div>
					<div class="mb-10 mt-10">
						<div class="h-5 w-11/12 rounded-lg bg-neutral-300" />
						<div class="mt-2 h-5 w-9/12 rounded-lg bg-neutral-300" />
						<div class="my-4 flex flex-wrap gap-x-3 gap-y-2">
							{#each { length: 4 } as _}
								<div class="mt-1 h-4 w-12 rounded-lg bg-neutral-300" />
							{/each}
						</div>
						<div class="my-4 flex flex-wrap gap-x-3 gap-y-2">
							{#each { length: 2 } as _}
								<div class="mt-1 h-4 w-8 rounded-lg bg-neutral-300" />
							{/each}
						</div>
					</div>
					<div class="mt-auto flex flex-col items-center gap-2">
						<div class=" h-16 w-full rounded-xl bg-neutral-200" />
						<div class=" h-16 w-full rounded-xl bg-neutral-200" />
						<div class=" h-16 w-full rounded-xl bg-neutral-200" />
						<div class="my-3 h-7 w-7/12 rounded-lg bg-neutral-300" />
						<div class=" h-12 w-full rounded-lg bg-neutral-300" />
					</div>
				</div>
			</div>
		{/each}
	{/if}
</div>
