<script lang="ts">
	export let href: string | undefined = undefined
	export let theme: 'primary' | 'secondary' | 'gray' = 'primary'
	export let cls = ''
	export let padding = 'px-5 py-3'
	export let textSize = 'text-sm'
	export let onClickButton: (e: MouseEvent) => void = (e: MouseEvent) => {
		logger.debug('components.button', 'Button not handled', e)
	}
	export let testid: string | null = null

	const themeClasses: { [key in typeof theme]: string } = {
		primary: 'text-white bg-primary-500',
		secondary: 'text-white bg-secondary-500 hover:bg-secondary-300',
		gray: 'text-neutral-800 bg-gray-200 hover:bg-white'
	}

	function handleClick(event: MouseEvent) {
		onClickButton(event)
	}

	// Determine the element to use based on whether href is provided
	const element = href ? 'a' : 'button'
</script>

<svelte:element
	this={element}
	role="button"
	tabindex="0"
	{href}
	type={href ? undefined : 'button'}
	on:click={handleClick}
	data-testid={testid}
	class="{cls} {padding} {textSize} inline-block rounded-lg transition-colors {themeClasses[theme]}"
>
	<slot />
</svelte:element>
