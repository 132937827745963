<script>
	import LandingPage from './index.ts'

	export let text
	export let buttons
</script>

<div class="calculator-box mt-4 flex max-w-3xl flex-col gap-12 rounded-lg p-8 md:flex-row">
	<h3 class="!my-0">{text}</h3>
	<div class="flex flex-col justify-end gap-2">
		{#each buttons as button}
			<LandingPage.Button {...button} margin="my-0" />
		{/each}
	</div>
</div>

<style>
	.calculator-box {
		box-shadow: 0 6px 16px 0 rgba(185, 185, 185, 0.5);
	}
</style>
