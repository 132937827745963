<script lang="ts">
	export let text: string
	export let width: number
</script>

<div class="col-span-12 md:col-span-{width} text">
	<div class="text-cont basics-text-block-text-cont max-w-4xl">{@html text}</div>
</div>

<style lang="scss">
	.basics-text-block-text-cont {
		:global(h1) {
		}

		:global(h2) {
		}

		:global(h3) {
		}

		:global(h4) {
		}

		:global(h5) {
		}

		:global(h6) {
		}

		:global(p) {
			@apply mb-4 mt-2 text-lg leading-loose;
		}

		:global(strong) {
			@apply font-bold;
		}

		:global(ul) {
			@apply my-6 list-disc;
		}

		:global(li) {
			@apply my-2 text-lg;
		}

		:global(a) {
			@apply text-primary-500;
		}

		:global(figure.table) {
			@apply block w-full max-w-full overflow-x-scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;

			:global(.ck-table-resized) {
				@apply w-full;
			}

			&::-webkit-scrollbar {
				display: none;
			}
		}
	}
</style>
