<script>
	export let text
</script>

<div class="lp-text max-w-3xl">{@html text}</div>

<style lang="scss">
	.lp-text {
		:global(h2) {
			margin: 48px 0px 16px 0px;
			font-weight: 500;
			font-size: 38px;
		}
		:global(h3) {
			font-size: 22px;
			font-weight: 700;
		}
		:global(p) {
			font-weight: 300;
			line-height: 36px;
			margin-bottom: 1.5rem;
			font-size: 18px !important;
			color: #000;
		}

		:global(ul),
		:global(ol) {
			margin-left: 40px;
			margin: 20px 0 20px 22px;

			:global(li) {
				font-size: 18px;
				line-height: 36px;
				font-weight: 300;
				margin-bottom: 1rem;
			}
		}

		:global(ul) {
			list-style: square;
		}

		:global(ol) {
			list-style: decimal;
		}

		:global(a) {
			color: #e20613;
		}

		:global(figure) {
			max-width: 100%;
			@media only screen and (max-width: 760px) {
				margin: 0;
			}
			:global(img) {
				max-width: 100%;
			}
		}

		:global(figure.table) {
			margin: 15px 0;
			height: unset !important;
			width: unset !important;

			:global(table) {
				border-collapse: collapse;

				:global(tr) {
					border-bottom: 2px solid #ccc;

					&:last-child {
						border: none;
					}

					:global(td) {
						padding: 5px;
						padding-left: 5px !important;

						&:not(:last-of-type) {
							border-right: 2px solid #ccc;
						}

						&:first-child {
							font-weight: bold;
						}

						:global(p) {
							font-size: 12px !important;
							line-height: 16px !important;
							margin-bottom: 12px;
						}
					}
				}
			}
		}

		:global(.video-cont) {
			position: relative;
			height: 0;
			padding-bottom: 56.25%;
			margin-bottom: 30px;

			:global(iframe) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		:global(a:not(.ui-woltair):not(.btn)) {
			//color: #e20613;
			font-weight: bold;

			&:hover {
				color: #a50000;
			}

			:global(*) {
				color: inherit;

				:global(*) {
					color: inherit;
				}
			}
		}

		:global(figure.image-style-align-left) {
			padding: 0;
			margin: 0;
			padding-left: 0;
			width: 100%;
			:global(img) {
				position: relative;
				width: 100%;
				padding: 0;
				left: 0;
				display: block;
			}
		}
	}
</style>
