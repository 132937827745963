<script lang="ts">
	export let title: string
	export let text: string
	export let personName: string
	export let personRole: string
	export let image: {
		data: {
			attributes: {
				url: string
			}
		}
	}
	export let ratingText: string
	export let stars: number
	export let width: number
</script>

<div
	class="col-span-12 md:col-span-{width} grid w-full grid-cols-12 rounded-2xl bg-neutral-100 @container"
>
	<div class="col-span-12 flex flex-col gap-6 px-6 pt-8 @2xl:col-span-7 @2xl:py-16 @2xl:pl-10">
		{#if ratingText || stars}
			<div class="flex flex-col gap-2 text-sm @2xl:flex-row @2xl:items-center @2xl:gap-4">
				{#if stars}
					<span class="flex"
						>{#each Array(Math.floor(stars)) as _}
							<img
								src="/images/icons/rating-star-red.svg"
								class="aspect-square h-6 w-6"
								alt="rating"
								width="24"
								height="24"
								loading="lazy"
							/>
						{/each}
						{#if Math.floor(stars) !== Math.ceil(stars)}
							<img
								src="/images/icons/rating-star-red-half.svg"
								class="aspect-square h-6 w-6"
								alt="rating"
								width="24"
								height="24"
								loading="lazy"
							/>
						{/if}</span
					>{/if}
				{#if ratingText}
					<span class="pl-1 text-w-blue-950 opacity-70 @2xl:pl-0">{ratingText}</span>
				{/if}
			</div>
		{/if}
		{#if title}
			<h3 class="unstyled text-4xl-res font-semibold text-w-blue-950">{title}</h3>
		{/if}
		{#if text}
			<p class="!mt-0 text-sm opacity-70">{text}</p>
		{/if}
		<div class="mt-auto flex flex-col">
			{#if personName}
				<span class="font-semibold text-w-blue-950">{@html personName}</span>
			{/if}
			{#if personRole}
				<span class="opacity-70">{@html personRole}</span>
			{/if}
		</div>
	</div>
	{#if image?.data?.attributes?.url}
		<img
			src={image.data.attributes.url}
			alt={personName || 'Woltair representative'}
			class="col-span-12 ml-auto mt-auto w-2/3 max-w-72 object-contain px-4 @2xl:col-span-5 @2xl:w-full @2xl:pt-12 md:max-w-none"
		/>
	{:else}
		<div class="pt-4" />
	{/if}
</div>
