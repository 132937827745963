<script lang="ts">
	export let width: number = 12
	export let suptext: string
	export let title: string
	export let text: string
	export let phone: {
		phoneNumber: string
		phoneLink: string
		availability: string
	}
	export let email: {
		emailAddress: string
		emailLink: string
		availability: string
	}
	export let personName: string
	export let personRole: string
	export let image: {
		data: {
			attributes: {
				url: string
			}
		}
	}
	export let imageMobile: {
		data: {
			attributes: {
				url: string
			}
		}
	}
</script>

<div
	class="grid grid-cols-12 col-span-{width} inset-x-0 mx-auto h-fit w-full max-w-5xl overflow-hidden rounded-3xl bg-neutral-100 @container"
>
	<div
		class="relative z-10 col-span-12 flex flex-col gap-2 p-16-res pt-16-res md:col-span-6 md:gap-4 md:p-20 md:pr-8"
	>
		<p class="font-semibold text-w-red-500">{suptext}</p>
		<h2 class="unstyled text-xl font-semibold text-w-blue-950 md:text-5xl">{title}</h2>
		<p class="mb-6 mt-4 text-sm leading-relaxed text-neutral-500 md:mb-12">{text}</p>
		{#if phone?.phoneLink}
			<div class="relative z-10 mb-2 flex items-center gap-5">
				<img
					src="/images/icons/phone-w-blue-outline.svg"
					alt="phone icon"
					class="aspect-square w-8"
					loading="lazy"
				/>
				<div>
					<a
						href={phone.phoneLink}
						class="whitespace-nowrap text-3xl-res font-semibold text-w-blue-950 transition-colors hover:text-w-red-500 md:text-3.5xl"
						>{phone.phoneNumber}</a
					>
					{#if phone.availability}
						<p class="unstyled text-xs text-w-blue-950 opacity-40 md:text-base">
							{phone.availability}
						</p>
					{/if}
				</div>
			</div>
		{/if}
		{#if email?.emailLink}
			<div class="flex items-center gap-5 md:mb-12">
				<img
					src="/images/icons/email-w-blue-outline.svg"
					alt="letter icon for email"
					class="aspect-square w-8"
					loading="lazy"
				/>
				<div>
					<a
						href={email.emailLink}
						class="text-3xl-res font-semibold text-w-blue-950 transition-colors hover:text-w-red-500 md:text-3.5xl"
						>{email.emailAddress}</a
					>
					{#if email.availability}
						<p class="unstyled text-xs text-w-blue-950 opacity-40 md:text-base">
							{email.availability}
						</p>
					{/if}
				</div>
			</div>
		{/if}
		<div class="mt-auto hidden md:block">
			<p class="unstyled font-semibold text-w-blue-950">{personName}</p>
			<p class="unstyled text-w-blue-950 opacity-40">{personRole}</p>
		</div>
	</div>
	<div class="col-span-12 flex items-end md:col-span-6 md:pt-16">
		<img
			src={image?.data?.attributes?.url || '/images/logos/about-us-box-placeholder-logo.svg'}
			alt={image?.data?.attributes?.url ? personName : 'Woltair logo'}
			class="hidden w-full object-contain object-left-bottom md:block"
			loading="lazy"
		/>

		{#if imageMobile?.data?.attributes?.url}
			<img
				src={imageMobile?.data?.attributes?.url || '/images/logos/woltair-favicon.svg'}
				alt={personName}
				class="max-h-56 w-full object-contain object-center md:hidden"
				loading="lazy"
			/>
		{/if}
	</div>
</div>
