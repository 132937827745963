<script lang="ts">
	import Form from '$lib/components/WoltAdvisor/Form.svelte'
	import { t } from '$lib/translations'
	export let background: object
	export let backgroundImage = '/images/backgrounds/woltair-homepage-background-de.webp'
	export let navHeight = 80
	export let title: string
	export let brandsCount: number = 0
	export let marketplaceForm: object

	export let brands = [
		{
			name: 'Viessmann',
			logo: '/images/logos/brands/white/viessmann-logo-white.svg',
			logoGrey: '/images/logos/brands/grey/viessmann-logo-grey.svg'
		},
		{
			name: 'Vaillant',
			logo: '/images/logos/brands/white/vaillant-logo-white.svg',
			logoGrey: '/images/logos/brands/grey/vaillant-logo-grey.svg'
		},
		{
			name: 'LG',
			logo: '/images/logos/brands/white/lg-logo-white.svg',
			logoGrey: '/images/logos/brands/grey/lg-logo-grey.svg'
		}
	]
</script>

<div class="page-fullwidth -mt-32 h-fit w-[100vw]">
	<div class="hidden md:block">
		<div class="absolute -z-10 h-full w-full bg-black" />
		<div
			class="fix-safari-stutter absolute -z-10 h-full w-full bg-cover bg-center bg-no-repeat opacity-50 blur-3xl"
			style:background-image="url('{background?.data?.attributes?.url ?? backgroundImage}')"
		/>
	</div>

	<div
		class="bg-image background z-20 mx-auto h-fit max-w-[2000px]"
		style:padding-top="{navHeight}px"
		style:background-image="url('{background?.data?.attributes?.url ?? backgroundImage}')"
	>
		<div
			class="md:page-wrapper mx-2 flex min-h-[980px] flex-col justify-start pb-[140vw] pt-24 sm:pb-24 md:mx-auto md:h-auto md:max-h-none md:min-h-0 md:justify-start"
		>
			{#if marketplaceForm}
				<div class="relative z-10 h-fit max-h-full w-fit max-w-full rounded-2xl bg-w-blue-950">
					<Form
						{title}
						showHVAC={marketplaceForm.showHVAC}
						showHiddenTabs={marketplaceForm.showHiddenTabs}
						showPV={marketplaceForm.showPV}
					/>
				</div>
				<div class="hidden md:block">
					<p class="mt-24 text-white">{t('web.simple_leadform.heat_pumps.ourBrands')}</p>
					<div class="mt-4 flex flex-wrap items-center gap-8">
						{#each brands as brand}
							<img src={brand.logo} alt={brand.name} class="h-auto w-32" loading="lazy" />
						{/each}

						{#if brandsCount - brands.length > 0}
							<p class="w-full text-white md:w-fit">
								+{brandsCount - brands.length}&nbsp;{t('web.simple_leadform.ourBrands.more')}
							</p>
						{/if}
					</div>
				</div>
			{:else}
				<div class="relative mb-8 mt-36 w-auto max-w-[800px]">
					<div class="relative z-10">
						<h1 class="unstyled h1 w-auto text-white">{title}</h1>
					</div>
					<div class="blurshadow" />
				</div>
			{/if}
		</div>
	</div>

	<div class="flex flex-col sm:mt-16 md:hidden">
		<p class="text-center text-neutral-600">
			{t('web.simple_leadform.heat_pumps.ourBrands')}
		</p>
		<div class="flex flex-col items-center gap-8">
			<div class="mt-8 flex flex-wrap justify-center gap-8">
				{#each brands as brand}
					<img src={brand.logoGrey} alt={brand.name} class="h-auto w-5/12" loading="lazy" />
				{/each}
			</div>

			{#if brandsCount - brands.length > 0}
				<p class="w-full text-center text-neutral-600 md:w-fit">
					+{brandsCount - brands.length}
					{t('web.simple_leadform.ourBrands.more')}
				</p>
			{/if}
		</div>
	</div>
</div>

<style lang="scss">
	.background {
		background-position: cover;
		@apply bg-[url('/images/backgrounds/woltair-homepage-background-de-mobile-ver-b.webp')] bg-contain bg-bottom bg-no-repeat;

		@screen sm {
			@apply bg-[url('/images/backgrounds/woltair-homepage-background-de.webp')] bg-cover bg-top bg-no-repeat;
		}
	}
	.blurshadow {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #000;
		filter: blur(100px);
	}

	.fix-safari-stutter {
		transform: translateZ(0);
	}
</style>
