<script lang="ts">
	import { sendGTM } from '$lib/analytics'

	export let width: number
	export let boxText: string
	export let boxLink: string
	export let colour: 'RED' | 'BLUE' | 'WHITE' | 'GREY_ROUNDED'
	export let onClickGTM: {
		[key: string]: string | { [key: string]: string }
	} | null
	export let mobileFullWidth: boolean
</script>

{#if boxLink && boxText}
	<div class="col-span-{width} sm:w-fit" class:w-full={mobileFullWidth}>
		<a
			href={boxLink}
			target={boxLink.includes('http') ? '_blank' : '_self'}
			on:click={() => {
				if (onClickGTM) {
					sendGTM('', {}, onClickGTM)
				}
			}}
			class:greyButton={colour === 'GREY_ROUNDED'}
			class:btn-new={colour !== 'GREY_ROUNDED'}
			class:btn-new-red={colour === 'RED'}
			class:btn-new-blue={colour === 'BLUE'}
			class:w-full={mobileFullWidth}
			class="sm:w-fit"
			>{boxText}

			{#if colour === 'GREY_ROUNDED'}
				<img
					src="/images/icons/arrow/next-black-simple.svg"
					alt="previous"
					class="h-[.8em]"
					loading="lazy"
				/>
			{/if}
		</a>
	</div>
{/if}

<style lang="scss">
	.greyButton {
		@apply flex items-center gap-3 rounded-full bg-neutral-200/70 px-6 py-3 text-sm font-medium opacity-100 transition-colors;

		&:hover {
			@apply bg-neutral-300;
		}
	}
</style>
