<script lang="ts">
	export let width: number
	export let title: string
	export let suptext: string
	export let image: {
		data: {
			attributes: {
				url: string
			}
		}
	}
	export let article: {
		data: {
			attributes: {
				title: string
				mainImage: {
					data: {
						attributes: {
							url: string
						}
					}[]
				}
				content: {
					text: string
				}[]
			}
		}
	}

	const header = title || article?.data?.attributes?.title
	const img =
		image?.data?.attributes?.url || article?.data?.attributes?.mainImage?.data?.[0]?.attributes?.url

	// const content = article?.data?.attributes?.content?.[0]?.text
	const content = article?.data?.attributes?.content?.map((c) => c?.text).join('')
</script>

<div class="col-span-{width} flex w-full max-w-full flex-col items-center">
	<div class="max-w-full text-center md:max-w-4xl">
		{#if suptext}
			<h6 class="mb-6 text-lg font-semibold text-primary-500">{suptext}</h6>
		{/if}

		{#if header}
			<h2 class="unstyled text-4.5xl-res font-semibold">{@html header}</h2>
		{/if}
	</div>

	<img
		src={img}
		alt={header || 'Woltair'}
		class="mb-12 mt-12 aspect-[8/3] w-full rounded-3xl object-cover object-center"
		loading="lazy"
	/>
	<div class="article-01-text-cont relative max-w-full overflow-x-hidden md:max-w-3xl">
		{@html content}
	</div>
</div>

<style lang="scss">
	.article-01-text-cont {
		position: relative;

		:global(ul),
		:global(ol) {
			margin: 1.5rem 0 1.5rem 0px;

			:global(li) {
				margin-bottom: 1rem;
			}
		}

		:global(ul) {
			list-style: disc;
			:global(ul) {
				list-style: circle;
				:global(ul) {
					list-style: square;
					:global(ul) {
						list-style: disc;
					}
				}
			}
		}
		:global(ol) {
			list-style: decimal;

			:global(ol) {
				list-style: lower-latin;

				:global(ol) {
					list-style: lower-roman;

					:global(ol) {
						list-style: upper-latin;
						:global(ol) {
							list-style: upper-roman;
							:global(ol) {
								list-style: decimal;
							}
						}
					}
				}
			}
		}

		:global(a) {
			color: #e20613;
		}

		:global(figure) {
			max-width: 100%;
			@media only screen and (max-width: 760px) {
				margin: 0;
			}
			:global(img) {
				max-width: 100%;
			}
		}

		:global(figure.table) {
			margin: 15px 0;
			height: unset !important;
			width: unset !important;

			:global(table) {
				border-collapse: collapse;

				:global(tr) {
					border-bottom: 2px solid #ccc;

					&:last-child {
						border: none;
					}

					:global(td) {
						padding: 5px;
						padding-left: 5px !important;

						&:not(:last-of-type) {
							border-right: 2px solid #ccc;
						}

						&:first-child {
							font-weight: bold;
						}

						:global(p) {
							font-size: 12px !important;
							line-height: 16px !important;
							margin-bottom: 12px;
						}
					}
				}
			}
		}

		:global(.video-cont) {
			position: relative;
			height: 0;
			padding-bottom: 56.25%;
			margin-bottom: 30px;

			:global(iframe) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		:global(a:not(.ui-woltair):not(.btn)) {
			//color: #e20613;
			font-weight: bold;

			&:hover {
				color: #a50000;
			}
		}

		:global(figure.image-style-align-left) {
			padding: 0;
			margin: 0;
			padding-left: 0;
			width: 100%;
			:global(img) {
				position: relative;
				width: 100%;
				padding: 0;
				left: 0;
				display: block;
			}
		}
	}
</style>
