import { readable } from 'svelte/store'

export const isMobile = readable(false, (set) => {
	const checkMobile = () => {
		const isMobileDevice = window.matchMedia('(max-width: 768px)').matches
		set(isMobileDevice)
	}

	checkMobile()

	window.addEventListener('resize', checkMobile)

	return () => {
		window.removeEventListener('resize', checkMobile)
	}
})
