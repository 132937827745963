<script lang="ts">
	import { createEventDispatcher } from 'svelte'
	import recommend_cs from '$lib/assets/icons/recommend-cs.svg'
	import recommend_de from '$lib/assets/icons/recommend-de.svg'
	import recommend_it from '$lib/assets/icons/recommend-it.svg'
	import ico_wwhite from '$lib/assets/icons/woltair-w-white.svg'
	import hvac_placeholder from '$lib/assets/placeholder.jpg'
	import Button from '$lib/components/Button.svelte'
	import type { HVACPackageClickedEventDetail } from '$lib/components/wa-results/wa_types'
	import { formatAmount } from '$lib/form/formatters'
	import { t } from '$lib/translations'
	import type { PumpResultData } from '$lib/types/woltadvisor'
	import { getCountryISOCode } from '$lib/utils/dataPreparation'
	import HVACHighlights from '$lib/wa-modules/HVACHighlights.svelte'
	import SmallTooltip from '$lib/wa-modules/SmallTooltip.svelte'
	import Specs from '$lib/wa-modules/Specs.svelte'
	import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'
	import type { ExtendedPumpResultData } from '$lib/components/wa-results/wa_types'

	const dispatch = createEventDispatcher()
	async function dispatchPackageClicked(href: string, deviceType: PumpResultData) {
		dispatch('packageClicked', {
			href,
			deviceType
		} as HVACPackageClickedEventDetail)
	}

	let recommend: string | undefined = {
		WOLTAIR_DE: recommend_de,
		WOLTAIR_CZ: recommend_cs,
		WOLTAIR_IT: recommend_it
	}[PUBLIC_WOLTAIR_WEB_CODE]

	export let layout: 'HORIZONTAL' | 'VERTICAL' = 'VERTICAL'
	export let recommened = false
	export let currency: string
	export let deviceType: ExtendedPumpResultData
	export let detailRootUrl: string
	export let searchString: string
	export let translation: (key: string, values?: Record<string, string>) => string | undefined = t
	export let blurPrice: boolean
	function countHighlights(deviceType: ExtendedPumpResultData) {
		let i = 0
		if (deviceType.roi || deviceType.subsidy || deviceType.annualMoneySavings) {
			if (deviceType.subsidy && deviceType.subsidy > 0) {
				i++
			}
			if (deviceType.roi && deviceType.roi > 0) {
				i++
			}
			if (deviceType.annualMoneySavings && deviceType.annualMoneySavings) {
				i++
			}
		}
		return i
	}
	const wrapClass: string =
		layout === 'HORIZONTAL'
			? 'md:col-span-2 xl:col-span-3 md:grid md:grid-cols-2 xl:grid-cols-3 gap-4 flex flex-col'
			: 'flex flex-col grid-cols-1 h-full gap-4 items-between'

	const grid0first: string =
		layout === 'HORIZONTAL'
			? 'md:col-span-2 xl:col-span-1 md:order-1 md:row-span-2 relative'
			: 'h-44 items-start relative'
	const numHighlights = countHighlights(deviceType)
	const grid0second: string =
		layout === 'HORIZONTAL'
			? 'md:col-span-2 md:order-2 md:self-end'
			: 'items-end md:place-self-end relative'

	const grid0third: string =
		layout === 'HORIZONTAL'
			? `md:col-span-3 md:order-4 grid grid-cols-1 gap-2 md:grid-cols-${numHighlights} justify-between w-full`
			: 'grid gap-2 items-stretch'

	const grid0fourth: string =
		layout === 'HORIZONTAL'
			? 'md:col-span-2 md:order-3 md:flex md:justify-between items-center'
			: 'self-end place-self-end'

	const packageHref: string =
		detailRootUrl?.toString() + (deviceType?.catalogPackage?.slugId ?? '') + searchString
	const countryISO: string | null = getCountryISOCode(deviceType?.catalogPackage?.originCountry)

	function generateRandomString(length: number): string {
		const characters = 'Z0EIGSTB'
		let randomString = ''

		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * characters.length)
			randomString += characters.charAt(randomIndex)
		}

		return randomString
	}
</script>

{#if deviceType}
	<a
		on:click={(event) => {
			if (event.button !== 1) {
				dispatchPackageClicked(packageHref, deviceType)
			}
		}}
		href={packageHref}
		data-brandName={deviceType?.brandName?.toLowerCase()}
		class="h-full rounded-xl bg-white p-4 md:px-4 md:pb-5 md:pt-8 {wrapClass} self-e relative col-span-1 border-2 border-neutral-100"
		class:blur-sm={deviceType?.mocked}
		class:pointer-events-none={deviceType?.mocked}
		class:opacity-60={deviceType?.mocked}
		data-testid="hvac-result-item-{layout}"
	>
		<div class="{grid0first} relative flex origin-left items-center justify-start">
			{#if recommened}
				<img
					src={recommend}
					class="pointer-events-none absolute left-[-100px] top-[-40px]"
					alt=""
				/>
			{/if}
			<img
				class="h-full max-h-64 w-auto min-w-full max-w-full object-contain object-center"
				src={deviceType?.catalogPackage?.thumbnail || hvac_placeholder}
				alt="Heatpump"
			/>
		</div>
		<div class={grid0second}>
			<div class="font-bold">
				{deviceType?.name}
			</div>
			<div
				class="tags my-2 flex flex-wrap items-center gap-1 text-sm {layout == 'VERTICAL'
					? 'absolute bottom-full'
					: ''}"
			>
				{#if recommened}
					<div class="mr-1 inline-block rounded-full bg-primary-500 px-3 py-1 text-xs text-white">
						<img src={ico_wwhite} alt="Star" class="mr-2 inline-block h-auto w-4 align-baseline" />
						{translation('web.marketplace.filter.recommended.tag')}
					</div>
				{/if}
				{#if deviceType?.tag == 'quiet'}
					<div class="mr-1 inline-block rounded-full bg-primary-500 px-3 py-1 text-xs text-white">
						{translation('web.marketplace.hvacResult.mostQuiet')}
					</div>
				{/if}
				{#if deviceType?.tag == 'power'}
					<div class="mr-1 inline-block rounded-full bg-primary-500 px-3 py-1 text-xs text-white">
						{translation('web.marketplace.hvacResult.mostPower')}
					</div>
				{/if}

				<div class="mr-1 inline-block rounded-full bg-secondary-500 px-3 py-1 text-xs text-white">
					{translation('web.woltadvisor.tag.includingInstallation')}
				</div>
			</div>
			{#if deviceType?.catalogPackage?.originCountry}
				<SmallTooltip>
					<svelte:fragment slot="tooltip">
						{translation('web.marketplace.country')}
					</svelte:fragment>
					<svelte:fragment slot="default">
						{#if translation(`web.marketplace.country.${countryISO}`) != `web.marketplace.country.${countryISO}`}{translation(
								`web.marketplace.country.${countryISO}`
							)}{/if}
					</svelte:fragment>
				</SmallTooltip>
			{/if}
			<Specs packageData={{ ...deviceType?.catalogPackage, water: deviceType?.water }} />
		</div>
		{#if layout === 'VERTICAL'}
			<div class="h-full" />
		{/if}
		<div class={grid0third}>
			<HVACHighlights {deviceType} {currency} first={layout === 'HORIZONTAL'} />
		</div>
		<div class="{grid0fourth} w-full items-center justify-self-end py-2">
			{#if deviceType?.catalogPackage?.dynamicPriceWithVat && !blurPrice}
				<span
					class="{layout === 'HORIZONTAL'
						? 'pb-6 md:pb-0 md:text-4xl-res'
						: 'pb-6'} block text-center align-text-bottom text-2xl-res font-semibold"
				>
					{deviceType?.catalogPackage?.dynamicPriceWithVat
						? formatAmount(deviceType?.catalogPackage?.dynamicPriceWithVat - deviceType?.subsidy, {
								currency,
								round: 0
						  })
						: ''}
				</span>
			{:else if deviceType?.catalogPackage?.dynamicPriceWithVat}
				<span
					class="{layout === 'HORIZONTAL'
						? 'pb-6 md:pb-0 md:text-4xl-res'
						: 'pb-6'} block text-center align-text-bottom text-2xl-res font-semibold"
				>
					<span class="blur">
						{generateRandomString(5)}
					</span>
					{formatAmount(1, {
						currency,
						round: 0
					}).slice(1)}
				</span>
			{/if}

			<Button
				testid="lead-button"
				theme="secondary"
				cls="block text-center self-center justify-end {layout === 'HORIZONTAL'
					? 'w-full md:w-auto'
					: 'w-full'}"
				href={packageHref}
				onClickButton={(event) => {
					if (event.button === 1) {
						dispatchPackageClicked(packageHref, deviceType)
					}
				}}
				>{blurPrice
					? translation('web.marketplace.result.showPrice')
					: translation('web.marketplace.pvResult.detail')}</Button
			>
		</div>
	</a>
{/if}
