<script lang="ts">
	import ArticleBoxLink from '$lib/modules/blog/ArticleBoxLink.svelte'

	type Article = {
		attributes: {
			title: string
			publishDate: string
			mainImage: {
				data: {
					attributes: {
						url: string
					}
				}
			}
		}
	}

	export let width: number
	export let articleLimit: number
	export let articles: {
		data: Article[]
	}
	export let categories: {
		data: {
			attributes: {
				articles: {
					data: Article[]
				}
			}
		}[]
	}

	const articlesFromCategories = categories?.data
		.map((category) => category?.attributes?.articles?.data)
		.flat()
		.sort((a: Article, b: Article) => {
			return (
				new Date(b?.attributes?.publishDate).getTime() -
				new Date(a?.attributes?.publishDate).getTime()
			)
		})

	const combinedArticles = [...(articles?.data ?? []), ...articlesFromCategories]

	let articlesToShow: Article[] = []

	combinedArticles.forEach((article) => {
		if (
			!articlesToShow.find(
				(articleToShow) => articleToShow?.attributes?.title === article?.attributes?.title
			)
		) {
			articlesToShow.push(article)
		}
	})

	articlesToShow = articlesToShow.slice(0, articleLimit)
</script>

{#if articles?.data?.[0]?.attributes?.title ?? articlesFromCategories?.[0]?.attributes?.title}
	<div class="grid grid-cols-1 gap-4 col-span-{width} md:!max-w-[842px]">
		{#each articlesToShow as article, i}
			{#if i < articleLimit || !articleLimit}
				<ArticleBoxLink {article} />
			{/if}
		{/each}
	</div>
{/if}
