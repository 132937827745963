<script lang="ts">
	export let data
	let textColor, bgColor
	switch (data.theme) {
		case 'primary':
			textColor = 'text-white'
			bgColor = 'bg-primary-500'
			break

		default:
			textColor = 'text-white'
			bgColor = 'bg-secondary-500'
			break
	}
</script>

<div class="blog-section relative block w-full">
	<div class="relative ml-[50%] block w-screen -translate-x-1/2 {bgColor} py-16">
		<div class="page-wrapper {textColor}">
			<div class="mx-auto max-w-[450px] pb-8 text-center">
				<div class="mx-auto text-center">
					<img
						src={data.image.data.attributes.url}
						alt="Woltair"
						class="inline-block h-24"
						loading="lazy"
					/>
				</div>
				{@html data.toptext}
			</div>
			<div class="mx-auto max-w-[680px] gap-16 text-left font-thin md:grid md:grid-cols-2">
				<div class="col-span-1">
					{@html data.lefttext}
				</div>
				<div class="col-span-1">
					{@html data.righttext}
				</div>
			</div>
		</div>
	</div>
</div>

<style lang="scss">
	.blog-section {
		:global(p) {
			@apply mb-4;
		}
		.text-white :global(h1),
		.text-white :global(h2),
		.text-white :global(h3),
		.text-white :global(h4),
		.text-white :global(h5),
		.text-white :global(h6) {
			color: white;
		}
	}
</style>
