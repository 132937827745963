<script lang="ts">
	export let superHeading: string
	export let strictSuperHeadingSize: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
	export let heading: string
	export let strictHeadingSize: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
	export let alignMobile: 'left' | 'right' | 'center'
	export let alignDesktop: 'left' | 'right' | 'center'
	export let width: number
	export let contentRich: string
	export let contentSize: 'XL' | 'LG' | 'MD' | 'SM' | 'XS'
	export let contentColor: 'BLACK' | 'GRAY' | 'DARK_BLUE'
	export let strictContentWeight: 'LIGHT' | 'REGULAR' | 'MEDIUM' | 'SEMIBOLD' | 'BOLD' | 'EXTRABOLD'

	const contentSizeClass =
		{
			XL: 'content-xl',
			LG: 'content-lg',
			MD: 'content-md',
			SM: 'content-sm',
			XS: 'content-xs'
		}[contentSize] || 'content-md'

	const contentWeightClass =
		{
			LIGHT: 'font-light',
			REGULAR: 'font-normal',
			MEDIUM: 'font-medium',
			SEMIBOLD: 'font-semibold',
			BOLD: 'font-bold',
			EXTRABOLD: 'font-extrabold'
		}[strictContentWeight] || 'font-normal'
</script>

<div
	class="col-span-12 text-{alignMobile || 'center'} md:text-{alignDesktop ||
		'left'} md:col-span-{width} w-full"
>
	{#if superHeading}
		<div class="{strictSuperHeadingSize || 'h5'} font-bold text-primary-500">
			{@html superHeading}
		</div>
	{/if}
	{#if heading}
		<svelte:element
			this={strictHeadingSize || 'h3'}
			class="unstyled my-1 {(strictHeadingSize === 'h1' ? 'h2' : strictHeadingSize) ||
				'h3'} my-4 mb-6 text-secondary-500">{@html heading}</svelte:element
		>
	{/if}
	{#if contentRich}
		<div
			class="content {contentSizeClass} {contentWeightClass} inset-x-0 mx-auto flex flex-wrap justify-center gap-x-3 md:justify-start"
			class:gray-content={contentColor === 'GRAY'}
			class:dark-blue-content={contentColor === 'DARK_BLUE'}
		>
			{@html contentRich}
		</div>
	{/if}
</div>

<style lang="scss">
	.gray-content {
		:global(p) {
			@apply text-black/40;
		}
	}

	.dark-blue-content {
		:global(p) {
			@apply text-w-blue-950;
		}
	}

	.content-lg {
		:global(p) {
			@apply my-2 text-lg leading-[1.5em];
		}
	}

	.content-md {
		:global(p) {
			@apply my-2 text-base leading-[1.75em];
		}
	}

	.content-sm {
		:global(p) {
			@apply my-2 text-sm leading-[1.75em];
		}
	}

	.content-xs {
		:global(p) {
			@apply my-2 text-xs leading-[1.75em];
		}
	}

	.btn-strapi {
		@apply text-w-blue-950;
		@apply rounded-lg px-[2em] py-[1em] text-sm;
		@apply whitespace-nowrap text-center;
		@apply inline-flex items-center justify-center gap-2;
		@apply transition-all duration-200 ease-in-out;
		@apply cursor-pointer font-semibold;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

		&:focus {
			@apply ring-2;
		}
	}

	@screen md {
		.btn-strapi {
			@apply text-base;
		}
	}

	.btn-strapi-red {
		@apply bg-w-red-500 text-white shadow-none;
		@apply ring-w-blue-950;
		&:hover {
			@apply bg-w-red-550;
		}
	}

	.btn-strapi-blue {
		@apply bg-w-blue-950 text-white shadow-none;

		&:hover {
			@apply bg-w-red-600;
		}
	}

	.content {
		:global(p) {
			@apply w-full;
		}
		:global(p:first-of-type) {
			@apply mt-0;
		}
		:global(h1),
		:global(h2),
		:global(h3),
		:global(h4),
		:global(h5),
		:global(h6) {
			@apply w-full;
		}
		:global(.strapi-button) {
			@apply btn-strapi btn-strapi-red mt-2 inline-block;
		}

		:global(a) {
			@apply text-w-red-500;
		}

		:global(ul) {
			list-style: disc;
			padding-left: 1.5rem;
			:global(ul) {
				list-style: circle;
				:global(ul) {
					list-style: square;
					:global(ul) {
						list-style: disc;
					}
				}
			}
		}

		:global(.strapi-button-blue) {
			@apply btn-strapi btn-strapi-blue mt-2 inline-block;
		}

		:global(.strapi-arrow-link) {
			@apply mt-4 flex flex-nowrap items-center gap-2 text-nowrap font-bold;

			&:hover {
				@apply underline;
			}

			&::after {
				content: '';
				display: block;
				height: 0.75em;
				width: 0.75em;
				background-image: url('/images/icons/arrow/arrow-red-next-alt.svg');
				background-size: contain;
			}
		}

		:global(.raw-html-embed) {
			@apply w-full;
		}
		:global(.location-snippet) {
			@apply flex w-full flex-wrap items-center justify-between gap-4 pb-10 pt-6;

			:global(.address) {
				@apply flex items-center gap-4 font-bold;

				&::before {
					@apply block h-20 w-20 rounded-full bg-neutral-100 content-[''];
					@apply bg-center bg-no-repeat;
					background-image: url('/images/icons/location-marker-red.svg');
					background-size: 24px auto;
				}
			}

			:global(.map-link) {
				@apply text-w-red-500 underline;
			}
		}
	}
</style>
