<script lang="ts">
	export let text: string
	export let width: number
	export let textAlignText02: 'left' | 'center' | 'right' = 'left'
</script>

<div class="hero-text-2 col-span-12 md:col-span-{width}">
	<div class="hero-text-2-cont w-full max-w-full text-{textAlignText02}">
		{@html text}
	</div>
</div>

<style lang="scss">
	.hero-text-2 {
		:global(.hero-text-2-cont) {
			:global(p) {
				@apply text-base text-neutral-500;
			}

			:global(a) {
				@apply text-primary-500;
			}
			:global(h1) {
				@apply mt-0 text-3xl font-semibold leading-tight text-w-blue-950;

				@screen md {
					@apply text-5xl;
				}

				@screen lg {
					@apply text-5.5xl;
				}
			}

			:global(h2) {
				@apply mt-0 text-xl font-semibold leading-normal text-w-blue-950;

				@screen md {
					@apply text-4xl;
				}

				@screen lg {
					@apply text-4.5xl;
				}
			}

			:global(h3) {
				@apply mt-0 text-lg font-semibold leading-normal text-w-blue-950;

				@screen md {
					@apply text-2xl;
				}

				@screen lg {
					@apply text-2.5xl;
				}
			}

			:global(h4) {
				@apply mt-0 text-base font-semibold leading-normal text-w-blue-950;

				@screen md {
					@apply text-xl;
				}

				@screen lg {
					@apply text-1.5xl;
				}
			}

			:global(h5) {
				@apply mt-0 text-base font-semibold leading-normal text-w-blue-950 opacity-50;

				@screen md {
					@apply text-xl;
				}

				@screen lg {
					@apply text-1.5xl;
				}
			}

			:global(h6) {
				@apply mt-0 text-base font-semibold leading-normal text-w-red-500;

				@screen md {
					@apply text-xl;
				}

				@screen lg {
					@apply text-1.5xl;
				}
			}
		}
	}
</style>
