<script lang="ts">
	export let title: string
	export let bgColorClass: string
	export let data
	export let content: string = data.content
	export let imageNR: any
	export let width: number
	const bgcolor = bgColorClass || 'bg-neutral-100'
</script>

<div class="w-full col-span-{width} thecontiner relative rounded-xl p-4 {bgcolor}">
	{#if title}
		<h2 class="max-w-[540px]">{title}</h2>
	{/if}
	<div class="boxcontainer relative flex">
		{#if content}
			<div class="content-container">
				{@html content}
			</div>
		{/if}
		{#if imageNR.data?.[0]?.attributes?.url}
			<div class="image-container">
				<img src={imageNR.data[0].attributes.url} alt={title ? title : 'image'} />
			</div>
		{/if}
	</div>
</div>

<style>
	/* Container class to apply the containment context */
	.thecontiner {
		container-name: featurebox;
		container-type: inline-size;
	}

	@container featurebox (max-width: 600px) {
		.boxcontainer {
			flex-direction: column;
		}
	}

	.image-container,
	.content-container {
		flex: 1;
	}
</style>
