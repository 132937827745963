<script lang="ts">
	export let title: string
	export let subtext: string
	export let width: number
	export let image: {
		data: {
			attributes: {
				url: string
			}
		}
	}
	export let imageMobile: {
		data: {
			attributes: {
				url: string
			}
		}
	}
	export let paddingBottom: number
	export let paddingBottomMobile: number
	export let textStyle: 'LIGHT' | 'DARK' = 'DARK'
	export let style: 'BG_IMAGE' | 'BOTTOM_IMAGE' = 'BG_IMAGE'
	export let bgColorClass: string = 'bg-neutral-100'
</script>

<div
	class="col-span-12 md:col-span-{width} relative flex w-full flex-col overflow-hidden rounded-xl {bgColorClass}"
>
	<div class="relative z-10 p-6 md:p-8">
		{#if title}
			<h3
				class="unstyled relative z-10 text-xl-res font-semibold {textStyle === 'LIGHT'
					? 'text-white'
					: 'text-w-blue-950'}"
			>
				{@html title}
			</h3>
		{/if}
		{#if subtext}
			<p
				class="unstyled relative z-10 mt-2 text-sm {textStyle === 'LIGHT'
					? 'text-white opacity-80'
					: 'text-w-blue-950 opacity-60'}"
			>
				{@html subtext}
			</p>
		{/if}
		{#if textStyle === 'LIGHT'}
			<div
				class="absolute left-0 top-0 z-0 h-full w-full bg-black text-right opacity-40 blur-3xl md:opacity-20"
			/>{/if}
	</div>
	{#if paddingBottom}
		<div class="hidden w-full bg-transparent sm:block" style="height: {paddingBottom}rem" />
	{/if}

	{#if paddingBottomMobile}
		<div class="w-full bg-transparent sm:hidden" style="height: {paddingBottomMobile}vw" />
	{/if}
	{#if style === 'BG_IMAGE'}
		<img
			class="absolute top-0 z-0 h-full w-full object-cover {imageMobile?.data?.attributes?.url
				? 'hidden sm:block'
				: ''}"
			src={image?.data?.attributes?.url}
			alt=""
		/>
		{#if imageMobile?.data?.attributes?.url}
			<img
				class="absolute top-0 z-0 h-full w-full object-cover sm:hidden"
				src={imageMobile?.data?.attributes?.url}
				alt=""
			/>
		{/if}
	{/if}
	{#if style === 'BOTTOM_IMAGE'}
		<img
			class="inset-x-0 mx-auto mt-auto w-full max-w-[500px] object-contain p-6 md:p-8 {imageMobile
				?.data?.attributes?.url
				? 'hidden sm:block'
				: ''}"
			src={image?.data?.attributes?.url}
			alt=""
		/>
		{#if imageMobile?.data?.attributes?.url}
			<img
				class="inset-x-0 mx-auto mt-auto w-full max-w-[500px] object-contain p-6 sm:hidden md:p-8"
				src={imageMobile?.data?.attributes?.url}
				alt=""
			/>
		{/if}
	{/if}
</div>
