<script lang="ts">
	//export let width: number
	export let bgType: 'YELLOW_ORANGE' | string
	export let position: number
</script>

<div
	class="-z-10 flex h-0 items-center justify-center overflow-visible"
	style:transform="translateY({position}rem)"
>
	<div class="absolute flex aspect-square w-[1360px] max-w-[100vw] justify-center">
		{#if (bgType = 'YELLOW_ORANGE')}
			<div class="yellow-orange relative flex w-full items-center justify-center">
				<div
					class="yellow transl absolute -z-10 aspect-square w-6/12 -translate-x-[25%] translate-y-[2%] rounded-full opacity-[0.075]"
				/>
				<div
					class="orange absolute -z-20 aspect-square w-6/12 -translate-y-[2%] translate-x-[25%] rounded-full opacity-[0.075]"
				/>
			</div>
		{/if}
	</div>
</div>

<style type="scss">
	.yellow-orange {
		& .yellow {
			background-color: rgb(227, 136, 0);
			filter: blur(1000px);
		}

		& .orange {
			background-color: rgba(226, 6, 19, 1);
			filter: blur(1000px);
		}
	}
</style>
