<script lang="ts">
	export let heading: string
	export let headingSize: number
	export let text: string
	export let theme: string
	export let decoration: boolean
	export let width: number
	let themeBox: string
	switch (theme) {
		case 'primary':
			themeBox = 'text-white bg-primary-500'
			break

		default:
			themeBox = 'text-white bg-secondary-500'
			break
	}
</script>

<div class="relative col-span-12 md:col-span-{width} ">
	<div
		class=" {themeBox} relative z-20 flex h-full flex-col justify-center rounded-3xl p-12 text-center"
	>
		{#if heading}
			<div class="{headingSize} my-0">
				{heading}
			</div>
			{#if text}
				<p>
					{text}
				</p>
			{/if}
		{/if}
	</div>
	{#if decoration}
		<img
			src="/images/icons/2023_ci/deco/single_wltr_arr.svg"
			alt="decoration"
			class="absolute bottom-0 right-[5%] z-30 w-1/4 translate-y-1/2"
			loading="lazy"
		/>
		<img
			src="/images/icons/2023_ci/deco/single_wltr_arr.svg"
			alt="decoration"
			class="absolute bottom-0 right-[20%] z-10 w-1/4 translate-y-1/2"
			loading="lazy"
		/>
	{/if}
</div>
