<script lang="ts">
	import { ConditionalAnchor } from '$lib/components'

	export let simpleBoxes: {
		title?: string
		subtext?: string
		link?: string
		image?: {
			data: {
				attributes: {
					url: string
				}
			}
		}
	}[] = []
	export let width: number
</script>

{#if simpleBoxes.length > 0}
	<div
		class="col-span-12 md:col-span-{width} grid w-full grid-cols-1 gap-4 @container xs:grid-cols-2"
	>
		{#each simpleBoxes as box}
			<ConditionalAnchor href={box.link} condition={!!box.link?.length}>
				<div
					class="group col-span-1 flex h-full w-full flex-col items-center gap-2 rounded-2xl border border-transparent bg-neutral-100 py-6 transition-colors @lg:py-10"
					class:hover:border-neutral-300={!!box.link?.length}
				>
					{#if box?.image?.data}
						<div class="mb-4 px-6 @lg:px-10">
							<img
								src={box.image.data.attributes.url}
								alt={box.title}
								class="aspect-square w-full object-contain"
								loading="lazy"
							/>
						</div>
					{/if}
					{#if box.title}
						<h3
							class="unstyled px-4 text-center text-base font-semibold transition-colors @lg:px-8 @lg:text-xl-res"
							class:group-hover:text-w-red-500={!!box.link?.length}
						>
							{box.title}
						</h3>
					{/if}
					{#if box.subtext}
						<div class="px-4 text-center text-xs text-neutral-700/80 @lg:px-8 @lg:text-sm">
							{box.subtext}
						</div>
					{/if}
				</div>
			</ConditionalAnchor>
		{/each}
	</div>
{/if}
