<script lang="ts">
	export let height: number | null
	export let heightMobile: number | null
	export let setMobile: boolean

	const heightValue = Number.parseInt((height ?? 0).toString())
	const heightMobileValue = Number.parseInt((heightMobile ?? 0).toString())
</script>

{#if setMobile}
	<div class="col-span-12 row-start-1 hidden w-full md:block" style="height: {heightValue}rem" />
	<div
		class="col-span-12 row-start-1 block w-full md:hidden"
		style="height: {heightMobileValue}vw"
	/>
{:else}
	<div class="col-span-12 row-start-1 hidden w-full md:block" style="height: {heightValue}rem" />
	<div
		class="col-span-12 row-start-1 block w-full md:hidden"
		style="height: {heightValue / 2}rem"
	/>
{/if}
