<script lang="ts">
	export let links: { text: string; url: string }[]
	export let width: number
</script>

{#if links?.length > 0}
	<section
		class="col-span-12 w-full md:col-span-{width} flex
        flex-wrap items-center justify-center gap-x-2 gap-y-4"
	>
		{#each links as link}
			{#if link.url && link.text}
				<a
					href={link.url}
					class="rounded-full bg-neutral-200/60 px-4 py-2 text-sm text-neutral-700 transition-colors hover:bg-neutral-300/80"
					>{link.text}</a
				>
			{/if}
		{/each}
	</section>
{/if}
