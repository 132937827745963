<script lang="ts">
	// import { dayjs } from '@woltair/translations-package-fe'
	import { onMount } from 'svelte'
	import { t } from '$lib/translations'
	import { resizeCDNImage } from '$lib/utils'
	// import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

	type Review = {
		id: string
		attributes: {
			image: {
				data: {
					attributes: {
						url: string
					}
				}
			}
			location: string
			rating: number
			date: string
			text: string
			reviewAttributes: {
				name: string
				value: string
			}[]
			brand: {
				data: {
					attributes: {
						title: string
					}
				}
			}
		}
	}

	//export let width: string
	export let title: string
	export let googlerating: {
		rating: number
		totalReviews: number
	}

	export let brands: {
		data: {
			attributes: {
				cstReviews: {
					data: Review[]
				}
			}
		}[]
	}

	export let deviceTypes: {
		data: {
			attributes: {
				cstReviews: {
					data: Review[]
				}
			}
		}[]
	}

	export let cstReviews: {
		data: Review[]
	}

	const brandsReviews = brands?.data
		.map((brand) => brand?.attributes?.cstReviews?.data?.map((review) => review))
		.flat()

	const deviceReviews = deviceTypes?.data
		.map((deviceType) => deviceType?.attributes?.cstReviews?.data?.map((review) => review))
		.flat()

	const directReviews = cstReviews?.data?.map((review) => review)

	const seenIds = new Set<string>()
	const allReviews = [...brandsReviews, ...deviceReviews, ...directReviews]
		.filter((review) => {
			if (seenIds.has(review.id)) return false
			seenIds.add(review.id)
			return true
		})
		.sort((a, b) => {
			return new Date(b.attributes?.date).getTime() - new Date(a.attributes?.date).getTime()
		})
		.map((review) => {
			return review.attributes
		})

	// const dateFormat =
	// 	{
	// 		WOLTAIR_CZ: 'D. MMMM YYYY',
	// 		WOLTAIR_PL: 'D.MM.YYYY',
	// 		WOLTAIR_DE: 'D. MMMM YYYY',
	// 		WOLTAIR_IT: 'D MMMM YYYY'
	// 	}[PUBLIC_WOLTAIR_WEB_CODE] || 'D. MMMM YYYY'

	let container: HTMLDivElement
	let containerWidth: number
	let wrapperWidth: number
	let boxWidth: number

	let leftMostScroll = true
	let rightMostScroll = true

	const scrollForwards = () => {
		container.scrollBy({
			left: boxWidth + 48,
			behavior: 'smooth'
		})
	}

	const scrollBackwards = () => {
		container.scrollBy({
			left: -boxWidth - 48,
			behavior: 'smooth'
		})
	}

	onMount(() => {
		if (container)
			rightMostScroll = container.scrollLeft + container.clientWidth - container.scrollWidth === 0
	})
</script>

<svelte:window
	on:resize={() => {
		if (container) {
			rightMostScroll = container.scrollLeft + container.clientWidth - container.scrollWidth === 0
			leftMostScroll = container?.scrollLeft === 0
		}
	}}
/>

{#if allReviews?.length > 0}
	<div class="flex flex-col items-center text-center">
		{#if googlerating}
			<div class="inset-x-0 mx-auto flex items-center gap-4">
				<div>
					<p class="text-3xl text-w-blue-950">
						{Math.round(googlerating.rating * 10) / 10}
					</p>
				</div>
				<div class="flex flex-col gap-1">
					<div class="flex gap-1">
						{#each Array(Math.floor(googlerating.rating)) as _}
							<img
								src="/images/icons/rating-star.svg"
								class="aspect-square h-[1em]"
								alt="rating"
								loading="lazy"
							/>
						{/each}
						{#if Math.floor(googlerating.rating) !== Math.ceil(googlerating.rating)}
							<img
								src="/images/icons/rating-star-half.svg"
								class="aspect-square h-[1em]"
								alt="rating"
								loading="lazy"
							/>
						{/if}
					</div>
					<p class="text-sm">
						{googlerating.totalReviews}
						{t('web.dynamic_pages.components.googleRating')}
					</p>
				</div>
			</div>
		{/if}

		{#if title}
			<h2
				class="unstyled mb-12-res mt-4 max-w-3xl text-center text-xl font-semibold leading-snug text-w-blue-950 md:text-4xl"
			>
				{title}
			</h2>
		{/if}
	</div>

	<div
		class="hide-scrollbar relative flex h-fit w-full snap-x snap-mandatory overflow-x-auto"
		bind:this={container}
		bind:clientWidth={containerWidth}
		on:scroll={() => {
			rightMostScroll = container.scrollLeft + container.clientWidth - container.scrollWidth === 0
			leftMostScroll = container?.scrollLeft === 0
		}}
	>
		<div class="flex w-fit" bind:clientWidth={wrapperWidth}>
			{#each allReviews as review}
				<div class="carousel-box h-full snap-start" bind:clientWidth={boxWidth}>
					{#if review.image?.data?.attributes?.url}
						<img
							src={resizeCDNImage(review.image.data.attributes.url, 410)}
							alt={review?.location || 'image'}
							class="aspect-[4/3] w-full rounded-3xl object-cover"
							loading="lazy"
							width="410"
							height="308"
						/>
					{/if}
					<div class="mt-8 flex flex-col gap-8">
						{#if review.location || review.rating}
							<div class="flex w-full justify-between">
								{#if review.location}
									<div class="font-semibold text-w-blue-950">{review.location}</div>
								{/if}
								{#if review.rating}
									<div class="text-small flex items-center gap-2 text-neutral-500">
										<img
											src="/images/icons/rating-star.svg"
											class="aspect-square h-[1.1em]"
											alt="{Math.round(review.rating * 10) / 10}/10"
											loading="lazy"
										/>{Math.round(review.rating * 10) / 10}/10
									</div>
								{/if}
							</div>
						{/if}
						<!-- <div class="mt-2 flex gap-6 text-sm">
					{#if review.date}
						<div class="text-w-blue-950">{dayjs(review.date).format(dateFormat)}</div>
					{/if}
					{#if review?.brand?.data?.attributes?.title}
						<div class="text-w-red-500">{review.brand.data.attributes.title}</div>
					{/if}
				</div> -->
						{#if review?.reviewAttributes?.length}
							<div class="flex flex-col gap-3">
								{#each review.reviewAttributes as attribute}
									<div class="flex justify-between text-sm text-w-blue-950">
										<div>{attribute.name}</div>
										<div class="text-right font-bold">{attribute.value}</div>
									</div>
								{/each}
							</div>
						{/if}

						{#if review.text}
							<p class="unstyled text-sm leading-relaxed text-neutral-500">{review.text}</p>
						{/if}
					</div>
				</div>
			{/each}
		</div>
	</div>

	{#if !(wrapperWidth < containerWidth)}
		<div
			class="pointer-events-none inset-x-0 mx-auto mt-4 flex w-fit gap-4 text-sm opacity-0 transition-opacity md:mt-12 {!(
				leftMostScroll && rightMostScroll
			)
				? 'pointer-events-auto opacity-100'
				: ''}"
		>
			<button
				on:click={() => scrollBackwards()}
				class="flex aspect-square items-center rounded-full bg-neutral-200 px-5 {leftMostScroll
					? 'cursor-default opacity-50'
					: 'opacity-100 hover:bg-neutral-300'}"
				><img
					src="/images/icons/arrow/next-black-simple.svg"
					alt="previous"
					class="h-[.8em] rotate-180"
					loading="lazy"
				/></button
			>
			<button
				on:click={() => scrollForwards()}
				class="flex items-center gap-4 rounded-full bg-neutral-200 px-6 font-semibold transition-colors {rightMostScroll
					? 'cursor-default opacity-50'
					: 'opacity-100 hover:bg-neutral-300'}"
				>{t('web.dynamic_pages.components.moreReferences')}
				<img
					src="/images/icons/arrow/next-black-simple.svg"
					alt="previous"
					class="h-[.8em]"
					loading="lazy"
				/></button
			>
		</div>
	{/if}
{/if}

<style lang="scss">
	.carousel-box {
		width: calc(100vw - 32px);
		&:not(:first-of-type) {
			margin-left: 48px;
		}

		@screen md {
			width: calc(min(calc(100vw / 2 - 42px), calc(1328px / 2) - 32px));
		}

		@screen xl {
			width: calc(min(calc(100vw / 3 - 32px), calc(1328px / 3) - 32px));
		}
	}
</style>
