import { parse, visit } from 'graphql'
import LandingPage from '$lib/components/landing-pages'
import * as Components from '$lib/modules/cms'
import { fragments as importedFragments } from '$lib/strapi/fragments'

const fragments = Object.assign({}, ...Object.values(importedFragments))

type ComponentList = {
	[key: string]: {
		this: any
		props: {
			[key: string]: string
		}
	}
}

// const for setting typename strings of wrapper components
export const CONTENT_SECTION_START = 'ComponentPbLayoutSectionStart'
export const CONTENT_SECTION_END = 'ComponentPbLayoutSectionEnd'
export const REUSABLE_COMPONENT = 'ComponentPbBasicsReusableComponent'
export const REUSABLE_COMPONENT_HVAC = 'ComponentPbBasicsReusableComponentHvac'

export const extractFieldsFromFragment = (fragmentString) => {
	const document = parse(fragmentString)
	const fields = {}

	visit(document, {
		Field: {
			enter(node, key, parent, path, ancestors) {
				// Exclude __typename and nested fields
				if (typeof ancestors[ancestors.length - 2] !== 'undefined') {
					const parentType = ancestors[ancestors.length - 2].kind
					if (node.name.value !== '__typename' && parentType === 'FragmentDefinition') {
						if (node.alias?.value) fields[node.alias.value] = node.alias.value
						else fields[node.name.value] = node.name.value
					}
				}
			}
		}
	})

	return fields
}
//Automatically generate COMPONENT_LIST definitions for PageBuilder
function generateComponentsObject(
	fragments: Record<string, any>,
	Components: any
): ComponentObject {
	return Object.keys(fragments).reduce((acc, fragmentName) => {
		// Extract the component name from the fragment name
		const componentName = 'Component' + fragmentName.split('Component')[1]
		if (Components[componentName]) {
			acc[fragmentName] = {
				this: Components[componentName],
				props: extractFieldsFromFragment(fragments[fragmentName])
			}
		}
		return acc
	}, {} as ComponentObject)
}
const componentsObject = generateComponentsObject(fragments, Components)

export function buildGQL(fragments: any, components: any, fileName: string) {
	let fragmentImport = ''
	let fragmentList = ''
	let fragmentSpread = ''
	for (const fragmentName in fragments) {
		if (
			fragments &&
			Object.prototype.hasOwnProperty.call(fragments, fragmentName) &&
			fragmentName.startsWith('ComponentPb')
		) {
			if (
				fragmentName in components ||
				['ComponentPbLayoutSectionStart', 'ComponentPbLayoutSectionEnd'].some(
					(arrVal) => fragmentName === arrVal
				)
			) {
				const fragmentGQL = fragments[fragmentName]
				fragmentImport = fragmentImport + fragmentGQL
				fragmentSpread = fragmentSpread + `\n...` + fragmentName
				fragmentList = fragmentList + `\n` + fragmentName
			} else {
				logger.warn(
					'strapi.componentList.failed',
					`⚠️ %cWARNING [$lib/strapi/queries/${fileName}]⚠️ - %cFragment ${fragmentName} not found in compoment templates. Skipping...🤌`,
					'color: yellow',
					'color: green',
					`🗯️ Suggested solution: Component named ${fragmentName} needs to be exported in $lib/modules/cms or remove the gql definition`
				)
			}
		}
	}
	return { fragmentImport, fragmentSpread, fragmentList }
}

//this is a list of all the components that are used in the page builder, the key is the typename of the component in Strapi API, this is reference to Svelte component, in props the key is the name of the Svelte component prop and the value is the name of the field in Strapi
export const COMPONENT_LIST: ComponentList = {
	// LandingPage – probably will be replaced by unified PB components
	ComponentLandingPageButton: {
		this: LandingPage.Button,
		props: {
			text: 'buttonText',
			url: 'url',
			type: 'type'
		}
	},
	ComponentLandingPageTextBlock: {
		this: LandingPage.Text,
		props: {
			text: 'text'
		}
	},
	ComponentLandingPageImage: {
		this: LandingPage.Image,
		props: {
			url: 'imageField.data.attributes.url',
			alt: 'alt',
			size: 'size'
		}
	},
	ComponentLandingPageCtaBox: {
		this: LandingPage.CTABox,
		props: {
			buttons: 'button',
			text: 'text'
		}
	},
	ComponentPbProductsBrands: {
		this: Components.ComponentPbProductsBrands,
		props: {
			title: 'title',
			deviceType: 'deviceType',
			brands: 'brands',
			width: 'width'
		}
	},
	ComponentPbBasicsPdfViewer: {
		this: Components.ComponentPbBasicsPdfViewer,
		props: {
			file: 'file'
		}
	},

	...componentsObject
}
