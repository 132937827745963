<script lang="ts">
	import { sendGTM } from '$lib/analytics'
	import { resizeCDNImage } from '$lib/utils'

	export let width: number = 12
	export let boxes: {
		type: 'SMALL' | 'LARGE'
		title: string
		text: string
		link: {
			linkUrl: string
			linkText: string
			onClickGTM: {
				[key: string]: string | { [key: string]: string }
			} | null
		}
		colour: 'WHITE' | 'GREY' | 'RED' | 'BLUE'
		image: {
			data: {
				attributes: {
					url: string
				}
			}
		}
		disabled: boolean
	}[]

	const bgColour =
		{
			WHITE: 'bg-white',
			GREY: 'bg-neutral-100',
			RED: 'bg-w-red-500',
			BLUE: 'bg-w-blue-950'
		} || 'bg-neutral-100'

	const textColour =
		{
			WHITE: 'text-w-blue-950',
			GREY: 'text-w-blue-950',
			RED: 'text-white',
			BLUE: 'text-white'
		} || 'text-w-blue-950'

	const subtextColour =
		{
			WHITE: 'text-neutral-500',
			GREY: 'text-neutral-500',
			RED: 'text-white',
			BLUE: 'text-white'
		} || 'text-neutral-500'

	const buttonStyle =
		{
			WHITE: 'btn-new-blue',
			GREY: 'btn-new-blue',
			RED: 'btn-new-blue',
			BLUE: 'btn-new-red'
		} || 'btn-new-blue'
</script>

<section class="grid w-full grid-cols-12 grid-rows-[12] gap-6 col-span-{width}">
	{#each boxes as box}
		<div
			class="col-span-12 py-12 {box.type === 'LARGE'
				? 'row-span-6 pb-0'
				: 'row-span-2 py-10'} {bgColour[box.colour]} rounded-2xl @container md:col-span-6"
		>
			{#if box.type === 'SMALL'}
				<div
					class="flex h-fit flex-col items-center gap-4 self-stretch px-12 @md:flex-row @md:gap-14"
				>
					{#if box.image?.data?.attributes?.url}
						<img
							class:opacity-20={box.disabled}
							src={box.image.data.attributes.url}
							alt={box?.title || 'image'}
							class="aspect-square h-fit w-full max-w-[84px] @md:w-1/4"
							loading="lazy"
							width="84"
							height="84"
						/>
					{/if}
					<div
						class="flex h-full w-full flex-col items-center gap-2 self-stretch text-center @md:w-3/4 @md:items-start @md:text-left"
					>
						{#if box.title}
							<h2 class="unstyled {textColour[box.colour]} text-xl font-bold md:text-2xl">
								{box.title}
							</h2>
						{/if}
						{#if box.text}
							<p class="!my-0 mb-auto text-sm {subtextColour[box.colour]}">{box.text}</p>
						{/if}
						{#if box.link?.linkUrl && box.link?.linkText}
							<a
								class:pointer-events-none={box.disabled}
								href={box.link.linkUrl}
								on:click={() => {
									if (box.link?.onClickGTM) {
										sendGTM('', {}, box.link.onClickGTM)
									}
								}}
								class="{box.disabled
									? 'btn-new btn-new-disabled'
									: `${
											buttonStyle[box.colour]
									  } btn-new `} mt-5 text-sm transition duration-300 ease-in-out"
								>{box.link.linkText}</a
							>
						{/if}
					</div>
				</div>
			{:else if box.type === 'LARGE'}
				<div class="flex h-full flex-col items-center gap-2 text-center">
					<h2 class="unstyled {textColour[box.colour]} mx-12 mt-4 text-xl font-bold md:text-2xl">
						{box.title}
					</h2>
					{#if box.text}
						<p class="mx-12 text-sm {subtextColour[box.colour]}">{box.text}</p>
					{/if}
					{#if box.link?.linkUrl && box.link?.linkText}
						<a
							class:pointer-events-none={box.disabled}
							href={box.link.linkUrl}
							class="{box.disabled
								? 'btn-new btn-new-disabled'
								: `${
										buttonStyle[box.colour]
								  } btn-new`} mx-12 mb-8 mt-5 w-fit rounded-lg px-6 py-4 text-sm"
							>{box.link.linkText}</a
						>
					{/if}
					{#if box.image?.data?.attributes?.url}
						<img
							src={resizeCDNImage(box.image.data.attributes.url, 650)}
							alt={box.title}
							class="mt-auto"
							class:opacity-20={box.disabled}
							loading="lazy"
							width="650"
							height="325"
						/>
					{/if}
				</div>
			{/if}
		</div>
	{/each}
</section>
