<script lang="ts">
	export let text
	export let buttons: {
		text: string
		link: string
		type: string
		onClickGTM: JSON | null
	}[]
	export let width: number
</script>

<div
	class="column-xl col-span-12 md:col-span-{width} mx-auto my-10 flex w-full flex-col items-center justify-between gap-8 rounded-2xl bg-neutral-100 p-8 md:flex-row"
>
	{#if text}
		<h3 class="!m-0">
			{text}
		</h3>
	{/if}
	{#if buttons.length > 0}
		<div class="flex flex-col items-stretch gap-2">
			{#each buttons as button}
				<a
					href={button.link}
					class="btn-new {button.type === 'BLUE'
						? 'btn-new-blue'
						: 'btn-new-red'} w-full self-stretch text-sm"
				>
					{button.text}
				</a>
			{/each}
		</div>
	{/if}
</div>
