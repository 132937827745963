<script lang="ts">
	export let width: number
	export let text: string
	export let title: string
	export let image: { data: { attributes: { url: string } } }
	export let link: { linkUrl: string; linkText: string; icon: string }
	export let theme01: 'GRAY' | 'WHITE' | 'BORDERED' = 'WHITE'
</script>

<div
	class="col-span-12 rounded-2xl px-10-res py-8 md:col-span-{width} flex items-center gap-10 self-stretch @container"
	class:bg-white={theme01 === 'WHITE'}
	class:bg-neutral-100={theme01 === 'GRAY'}
	class:border={theme01 === 'BORDERED'}
>
	{#if image?.data?.attributes}
		<img
			src={image.data.attributes.url}
			alt={title}
			class="p-2-res-25 hidden aspect-square max-h-28 w-4/12 max-w-full object-contain @xs:block sm:p-4 md:p-2"
			loading="lazy"
		/>
	{/if}
	<div
		class="{image?.data?.attributes
			? 'w-full @xs:w-8/12'
			: 'w-full'} flex h-full flex-col justify-center"
	>
		<h4 class="unstyled my-0 mb-2 text-xl font-semibold">{title}</h4>
		<div class="text text-base text-neutral-500" class:mb-6={link}>{@html text}</div>
		{#if link}
			<a href={link.linkUrl} class="mt-auto"
				><span class="arrow-after text-base hover:text-primary-500">
					{link.linkText}
				</span></a
			>
		{/if}
	</div>
</div>

<style lang="scss">
	.arrow-after {
		@apply flex items-center justify-between;
		&::after {
			@apply relative block aspect-square h-[.85em] bg-[url('/images/icons/arrow/arrow-red-next-alt.svg')] bg-cover content-[''];
		}
	}
</style>
